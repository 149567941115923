import { getOperator, headers, setOtherFilters } from "@/utils";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import { QcHeader } from "@/types";
import { getLikeStartValue } from "@/utils/configuration/formatters-config";

const odmConfiguration = "odm-configuration";

export async function fetchSupersetConfiguration(val: string) {
  const qc_header: QcHeader = {
    qc: {
      queryId: "get-superset-data",
      offset: 0,
      limit: -1,
    },
  };

  const operators: any[] = [];
  const table = "configuration_key_value";
  const dbField = "param_key";
  operators.push(
    getOperator(
      `lower(${table}`,
      `${dbField})`,
      "LIKE",
      getLikeStartValue(val).toLowerCase(),
      dbField
    )
  );


  setOtherFilters(operators, qc_header);

  const { data } = await api().get(settings.api_query_url, {
    headers: headers({ qc: JSON.stringify(qc_header.qc) }),
  });
  return data;
}

export async function saveSuperset(superset: any, param_key? : string, value_key?:string) {
  const baseUrl = `${settings.api_url}/${odmConfiguration}/api/1/${odmConfiguration}/keyvalue/`;
  const result = await api().post(`${baseUrl}`, {
    objectType: "odm.configuration.keyvalue",
    systemUid: odmConfiguration,
    paramKey: param_key ? param_key : superset.param_key,
    paramValue: value_key ? value_key : superset.param_value,
  });
  return result.data?.resourceUid;
}

export async function updateSuperset(superset: any) {
  const resourceUid = superset.id;
  const baseUrl = `${settings.api_url}/${odmConfiguration}/api/1/${odmConfiguration}/keyvalue/${resourceUid}/`;
  return (
    await api().put(`${baseUrl}`, {
      objectType: "odm.configuration.keyvalue",
      systemUid: odmConfiguration,
      resourceUid,
      paramKey: superset.param_key,
      paramValue: superset.param_value,
    })
  ).data;
}
