import { api } from "@/auth/api";
import store from "@/store"

export const searchPicklist = async (filter: any) => {
    console.log(store.state.picklistModule)
    const globalListPicklist = store.state.picklistModule as any
    

    let dataList: any = [];
    let editable=false
for (const key in globalListPicklist) {
    if (globalListPicklist.hasOwnProperty(key)) {
        const entityLabel = key;
        const odm =  (globalListPicklist[key as any] as any)?.systemUid;
        const entityName = (globalListPicklist[key as any] as any)?.entityName;
        (globalListPicklist[key as any] as any)?.items?.map((item:any) => createObject(entityLabel, entityName, odm, item.value? item.value: item.id, dataList, item.config && item.config.internalCode ?item.config.internalCode: "",  editable ));

    }
}

if (filter){
    dataList = filterList(dataList, filter);
}

return dataList
}


function createObject(entityLabel:string, entityName:string , odm :string, item :string, data:any , internalCode:string ,editable:boolean) {
    data.push({ entityLabel, entityName, odm, item,internalCode, editable}) ;
}

function filterList(list: any, filter: any) {
    const filteredList = list.filter((item: any) => {
        for (const key in filter) {
            if (filter.hasOwnProperty(key) && filter[key] !== undefined && filter[key] !== '') {
                if (item[key] !== filter[key]) {
                    return false;
                }
            }
        }
        return true;
    });
    return filteredList;
}




export const savePicklist = async (item: any) => {
    
   if(item.internalCode){
    const itemToSave= {
        defaultValue: false,
        label: item.item,
        internalCode: item.internalCode ,
        resourceUid: item.item,
        daaq: "/",
    }
        if (item.fromAdd){
            const url = `/${item.odm}/api/1/${item.odm}/${item.entityName}/`;
             (await api().post(url, itemToSave)).data
        }
        else{
            const url = `/${item.odm}/api/1/${item.odm}/${item.entityName}/${item.item}/`;
             (await api().put(url, itemToSave)).data
        }
    }
    else{
        const itemToSave= {
            resourceUid: item.item,
            daaq: "/",
        }
        if (item.fromAdd){
            const url = `/${item.odm}/api/1/${item.odm}/${item.entityName}/`;
             (await api().post(url, itemToSave)).data
        }
        


    }

    delete item.fromAdd
    return item



}