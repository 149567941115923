import {
    AdvancedQuote,
    AssetState,
    AssociatedParty,
    CalculationRequest, CalculationResponse,
    CalculationStep,
    CalculationType,
    ContractOrigination,
    evaluateProposalResponse,
    ExpertModeState,
    FinancialProductPack,
    FinancialProfile,
    MoneyAmount,
    NormalModeState,
    Price,
    PricingCalculationRequest,
    PricingCalculationResponse,
    Proposal,
    ProposalAsset,
    ProposalAssetItem, ProposalAssetItemType,
    RootState,
    ScaleConfig,
    ScaleSliderConfig,
    SearchType,
    SearchVariableType,
    ServiceConfig,
    SimpleQuote,
    SimulationAttribute,
    SimulationRequest,
    SimulationResponse,
    SimulationState, UniformOdmName
} from "@/types";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import {
    deepClone,
    deleteAttributes,
    deleteAttributesRecursively,
    globalConfig,
    groupBy,
    headers,
    removeAttributesRecursively,
    upsert,
    TAXDEFAULT
} from "@/utils";
import axios from "axios";
import { deleteFormatMaskAndConvertToNumber, formatWithMask } from "@/utils/configuration/formatters-config";
import store from "@/store";

import i18n from "@/i18n";
import demandModule from "../modules/demandModule";
import { searchProductTax } from "@/store/services/offerService";
import Notify from 'quasar/src/plugins/Notify.js';;
import { filterParty } from "@/store/services/searchThird";
import { getProductSelectionParametersRequest } from "./financing/financingMapper";

const odm_simulation = 'odm-simulation'
const odm_pricing = 'odm-pricing'
const odm_product = 'odm-product'
const odm_offer = 'odm-offer'



const buildCalculationStep = (rate = - 1,
    duration?: number,
    outstandingBalanceBefore = -1,
    outstandingBalanceAfter = -1,
    paymentValue = -1,
): CalculationStep => {
    const currency = 'EUR'
    const unit = 'MONTHS'
    return {
        currency,
        paymentDatesOption: "FixedDate",
        periodRateOption: "NominalPeriodRateMethod",
        paymentTermsOption: "PayAdvanceIntArrears",
        annualRate: rate,
        numberOfPaymentTerms: duration || 1,
        outstandingBalanceBefore: {
            amount: outstandingBalanceBefore,
            currency
        },
        outstandingBalanceAfter: {
            amount: outstandingBalanceAfter,
            currency
        },
        payment: {
            amount: paymentValue,
            currency
        },
        periodBetween2Installments: {
            duration: 1,
            unit: unit
        },
    }
}


export function calculatePricing(type: SearchType,
    request: PricingCalculationRequest): Promise<PricingCalculationResponse> {
    return new Promise<PricingCalculationResponse>(async (resolve, reject) => {
        const _headers = headers()
        const url = `${settings.api_url}/${odm_pricing}/api/1/${odm_pricing}/compute/advanced/${type}/`;
        axios.post(url, request, {
            headers: _headers
        }).then(res => resolve(res.data)).catch(err => reject(err))
    })
}

const convertCalculationType = (typeParam: CalculationType) => {
    let type = null
    switch (typeParam) {
        case CalculationType.PAYMENT:
            type = SearchType.PAYMENT
            break;
    }
    return type
}

const getFinancialProfile = async (baseUrl: string, financingProductUid?: string) => {
    return new Promise<FinancialProfile>(async (resolve, reject) => {
        await api().get(`${baseUrl}/financialprofile/${financingProductUid}/`)
            .then(res => resolve(res.data)).catch(e => reject(e))
    })
}


const getPackItems = async (baseUrl: string,
    partyInfo: any,
    assetInfo: any,
    quoteInfo: any,
    accessoryProductInfo: any,
    financingProductUid?: string) => {
    return new Promise<any>(async (resolve, reject) => {
        await api().post(`${baseUrl}/financingProductPack/${financingProductUid}/packItems/`, {
            applicationName: 'ORIGINATION',
            context: '',
            maxResults: 10,
            partyInfo,
            assetInfo,
            accessoryProductInfo,
            quoteInfo: quoteInfo,
        }).then(res => resolve(res.data)).catch(e => reject(e))
    })
}

const getFinancialProductPack = async (baseUrl: string, financingProductUid?: string) => {
    return new Promise<FinancialProductPack>(async (resolve, reject) => {
        await api().get(`${baseUrl}/financingproductpack/${financingProductUid}/`)
            .then(res => resolve(res.data)).catch(e => reject(e))
    })
}

const globalDefaultValues = {
    duration: 36,
    residualValue: 0,
    millage: 0,
    annualRate: 2,
    firstPayment: 0,
    downPayment: 0,
}

export async function calculatePercentageAmount(guaranteeList: any, state: RootState): Promise<PricingCalculationResponse> {
    return new Promise<any>(async (resolve, reject) => {
        const requestBody = await getProductSelectionParametersRequest(state)
        const assetInfo = requestBody.assetInfo
        const quoteInfo = requestBody.quoteInfo
        const guaranteeArray = JSON.stringify(guaranteeList)
        const baseUrl = `${settings.api_url}/${odm_simulation}/api/1/${odm_simulation}`
        await api().post(`${baseUrl}/simulation/guarantee/`, {
            guaranteeArray,
            assetInfo,
            quoteInfo,
        }).then(res => resolve(res.data)).catch(e => reject(e))
    })
}


export async function getScalesOtherData(statte: any, scales: ScaleConfig[], price: number, state: any): Promise<any> {
    const { rateFinancingTax, normalModeState, scaleSelected } = state
    const duration = extractNormalModeValue(normalModeState, 'duration')?.value || undefined
    const residualValue = extractNormalModeValue(normalModeState, 'residualValue')?.value || globalDefaultValues.residualValue
    const millage = extractNormalModeValue(normalModeState, 'millage')?.value || globalDefaultValues.millage
    const annualRate = (extractNormalModeValue(normalModeState, 'rate')?.percentValue || globalDefaultValues.annualRate) + Number(rateFinancingTax)
    const firstPayment = extractNormalModeValue(normalModeState, 'firstPayment')?.value || globalDefaultValues.firstPayment
    const downPayment = extractNormalModeValue(normalModeState, 'downPayment')?.value || globalDefaultValues.downPayment

    return new Promise(async (resolve, reject) => {
        try {
            await calculateScaleList({
                scales,
                firstPayment,
                downPayment,
                annualRate,
                price,
                duration,
                residualValue,
                millage,
                selectedScale: scaleSelected,
                statte
            })
            const result = scales.map((scale: any) => {
                const presentation = scale.config.sliders.map((sliderConfig: any) => {
                    return {
                        id: sliderConfig.id,
                        attribute: sliderConfig.attribute,
                        label: sliderConfig.label,
                        value: `${sliderConfig.value} ${sliderConfig.slideType === 'PERCENT' ? '%' : sliderConfig.suffix}`,
                    }
                })
                const classification = scale.classification
                return {
                    ...scale,
                    name: i18n.global.t(`demand.product.financingProduct.productLabel.${(scale.resourceUid).split('.').join('_')}`),
                    selected: false,
                    loading: false,
                    visible: true,
                    tittle: formatWithMask(scale.payment, 2),
                    subTittle: `sur ${scale.numberOfPaymentTerms} mois`,
                    presentation,
                    config: scale.config,
                    sliderConfig: [],
                    classification,
                }
            })
            resolve(result)
        } catch (e) {
            reject(e)
        }
    })

}

function BuildServiceItem(services: any) {
    const serviss = []
    for (const serv of services) {

        const service = {
            productUid: serv.resourceUid,
            currency: serv.amount.currency,
            quantity: 1,
            paymentDatesOption: serv.paymentDatesOption,
            amount: serv.amount || null,
            calculationMethodOption: serv.calculationMethodOption,
            annualRate: serv.annualRate || 0,
            basis: serv.basis,
            basisValue: null
        }
        serviss.push(service)
    }


    return serviss


}


const calculateScaleList = async (param: any) => {
    return Promise.reject('Remove old implementation')
}

export function getScales(state: any, price: number): Promise<any> {
    return Promise.reject('Remove old implementation')
    /*
    const url = `/${odm_product}/api/1/${odm_product}`;
    return new Promise<any>(async (resolve, reject) => {
        const {demandModule, simulationModule} = state
        const {assetState, offer} = demandModule
        const proposalList = await buildProposal([simulationModule], assetState)
        const proposal = proposalList[0]
        const assetList = proposal.proposalItems
            .map(item => item.proposalAssets)
            .reduce((a, b) => {
                a.push(...b);
                return a
            }, [])
        const accessoryProductInfo = proposal.proposalItems
            .map(item => item.proposalAccessories)
            .reduce((a, b) => {
                a.push(...b);
                return a
            }, [])
        const partyInfos = offer.associatedParties.map((associatedParty: any) => {
            const {third, role_code} = associatedParty
            const type = third.type.id
            third.organization.addresses = third.addresses
            const body = type.includes("Organization") ? {...third.organization} : {...third.person}
            removeAttributesRecursively(body)
            deleteAttributesRecursively(body, ['isDisabled'])
            return {
                partyData: JSON.stringify({
                    type,
                    role: role_code || 'CLIENT',
                    ...body
                })
            }
        })
        const assetInfo = assetList.map(asset => {
            removeAttributesRecursively(asset)
            return {
                assetData: JSON.stringify(asset)
            }
        })
        removeAttributesRecursively(proposal)
        const quoteInfo = JSON.stringify({
            ...proposal
        })
        const scaleList = await api().post(`${url}/product/`, {
            systemUid: "odm-product",
            applicationName: 'ORIGINATION',
            context: '',
            maxResults: 10,
            partyInfos,
            assetInfo,
            quoteInfo,
            accessoryProductInfo
        }).then(async res=>{
            if (res.data.length == 0)
            {
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                    message: `Product Unavailable`,
                    color: 'negative'
                  });
            }
            else{
            const group = groupBy(res.data, 'resourceUid')
            const list = Object.keys(group).map(key => group[key][0])
            const serviceResult = await convertScaleList({url, scaleList: list, partyInfos, assetInfo, quoteInfo, accessoryProductInfo, price})
            resolve(serviceResult)}
        })

    })
     */
}

export function getServices(): Promise<ServiceConfig[]> {
    return Promise.resolve([
        {
            id: 1,
            config: {
                required: true,
                selected: true
            },
            type: {
                id: "assurance",
                label: "Assurance"
            },
            name:
            {
                id: "pneumatique",
                label: "Pneumatique"
            },
            baseCalcule:
            {
                id: 'Montant Asset',
                label: 'Montant Asset',
            }
            ,
            min: 0.6,
            valuePercent: 0.625,
            max: 0.8,
            qt: 4,
            duration: 24,
            montantHt: 23,
            montantTtc: 27,
            paymentFrequence:
            {
                id: 'mois',
                label: 'Mois'
            },
            multiple: 1,
            term: {
                id: 'PayAdvanceIntAdvance',
                label: 'In advance'
            }
        },
        {
            id: 2,
            config: {
                required: false,
                selected: false
            },
            type: {
                id: "assurance",
                label: "Assurance"
            },
            name: {
                id: "contratEntretien",
                label: "Contrat entretien"
            },
            baseCalcule:
            {
                id: 'Montant Asset',
                label: 'Montant Asset',
            },
            min: 0.6,
            valuePercent: 0.625,
            max: 0.8,
            qt: 4,
            duration: 24,
            montantHt: 23,
            montantTtc: 27,
            paymentFrequence:
            {
                id: 'mois',
                label: 'Mois'
            },
            multiple: 1,
            term: {
                id: 'PayAdvanceIntAdvance',
                label: 'In advance'
            }
        },
        {
            id: 3,
            config: {
                required: false,
                selected: true
            },
            type: {
                id: "contrat",
                label: "Contrat"
            },
            name: {
                id: "fraisDossier",
                label: "Frais de dossier"
            },
            baseCalcule:
            {
                id: 'Montant XXXX',
                label: 'Montant XXXX',
            },
            min: 0.6,
            valuePercent: 0.625,
            max: 0.8,
            qt: 4,
            duration: 24,
            montantHt: 23,
            montantTtc: 27,
            paymentFrequence:
            {
                id: 'mois',
                label: 'Mois'
            },
            multiple: 1,
            term: {
                id: 'PayAdvanceIntAdvance',
                label: 'In advance'
            }
        }
    ])
}
function getRateFinancingTax() {
    return store.state.simulationModule.rateFinancingTax
}

function getRate(rate: number, isNormalMode: boolean) {
    return isNormalMode ? rate - getRateFinancingTax() : rate + getRateFinancingTax()
}
export function buildExpertModeState(state: SimulationState): ExpertModeState {
    const normalModeState = state.normalModeState
    const rate = getRate(extractNormalModeValue(normalModeState, 'rate')?.percentValue || 0, false)
    return {
        isCredit: state.productLabel === 'CREDIT',
        duration: extractNormalModeValue(normalModeState, 'duration')?.value,
        rate: rate,
        residualValue: extractNormalModeValue(normalModeState, 'residualValue')?.value,
        residualValueRate: extractNormalModeValue(normalModeState, 'residualValue')?.percentValue,
        monthlyValue: extractNormalModeValue(normalModeState, 'monthlyPayment')?.value,
        monthlyRate: extractNormalModeValue(normalModeState, 'monthlyPayment')?.percentValue,
        firstPaymentValue: extractNormalModeValue(normalModeState, 'firstPayment')?.value,
        firstPaymentRate: extractNormalModeValue(normalModeState, 'firstPayment')?.percentValue,
    }
}

export function buildNormalModeState(state: SimulationState) {
    const expertModeState = state.expertModeState
    const rate = getRate(expertModeState.rate || 0, true)
    return [
        setNormalModeValue(state.normalModeState, 'duration', expertModeState.duration),
        setNormalModeValue(state.normalModeState, 'residualValue', expertModeState.residualValue, expertModeState.residualValueRate),
        setNormalModeValue(state.normalModeState, 'monthlyPayment', expertModeState.monthlyValue, expertModeState.monthlyRate),
        setNormalModeValue(state.normalModeState, 'firstPayment', expertModeState.firstPaymentValue, expertModeState.firstPaymentRate),
        setNormalModeValue(state.normalModeState, 'rate', rate, rate),
    ].filter(item => item !== null)
}

export const extractNormalModeValue = (state: NormalModeState, attribute: string) => {
    return state?.attributes.filter(item => item.attribute === attribute)[0] || {}
}

export const setNormalModeValue = (state: NormalModeState, attribute: string, value?: number, rateValue?: number) => {
    if (!value && !rateValue)
        return null
    const object = extractNormalModeValue(state, attribute)
    object.value = value
    object.percentValue = rateValue
    return object
}


export async function buildProposalAssets(assetState: AssetState, isToOrchestration?: boolean | false): Promise<ProposalAsset[]> {
    return deepClone(assetState.proposalAssets).map((asset: ProposalAsset) => {
        if (asset.proposalAssetItems) {
            asset.proposalAssetItems = asset.proposalAssetItems.filter((t: ProposalAssetItem) => t.config.selected)
            asset.proposalAssetItems.forEach(proposalAssetItem => {
                if (isToOrchestration && typeof proposalAssetItem.type === 'string') {
                    proposalAssetItem.type = {
                        resourceUid: proposalAssetItem.type
                    }
                }
                deleteAttributesRecursively(proposalAssetItem, ['config'])
            })
        }
        deleteAttributesRecursively(asset, ['config'])
        return asset
    })
}


export async function saveAssociatedParty(associatedParty: AssociatedParty, daaqResourceUid?: string) {
    delete associatedParty.name
    return upsert('odm-offer', associatedParty, 'associatedparty', undefined, daaqResourceUid)
}

export const calculateSimulation = async (request: SimulationRequest) => {
    const baseUrl = `${settings.api_url}/${odm_simulation}/api/1/${odm_simulation}/`
    return new Promise<CalculationResponse>(async (resolve, reject) => {
        try {
            request?.accessories?.forEach((accessory) => {
                if (accessory.taxValue === null || accessory.taxValue === undefined) {
                    accessory.taxValue = TAXDEFAULT.val;
                }
            });
            const result = await api().post(`${baseUrl}/simulation/calculate/`, request)
            resolve(result.data)
            store.dispatch("middleOfficeModule/setCalculationSimulation",result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export const generateSimulationReport = async (request: SimulationRequest) => {
    const baseUrl = `${settings.api_url}/${odm_simulation}/api/1/${odm_simulation}/`
    return (await api().post(`${baseUrl}/simulation/report/`, request)).data
}

export const calculateMileage = async (request: any) => {
    const baseUrl = `${settings.api_url}/${odm_simulation}/api/1/${odm_simulation}/`
    return (await api().post(`${baseUrl}/simulation/evaluateProposalMileage/`, request)).data
}
export const calculateResidualValue = async (request: any) => {
    const baseUrl = `${settings.api_url}/${odm_simulation}/api/1/${odm_simulation}/`
    return (await api().post(`${baseUrl}/simulation/evaluateProposalResidualValue/`, request)).data
}
