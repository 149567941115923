import { Module } from "vuex";
import { PicklistState } from "@/types";
import {
  fetchReasons,
  getAccessoryCalculationMethod,
  getAccessoryPaymentDatesOption,
  getAccountStatus,
  getAccountType,
  getActionType,
  getActivityAsset,
  getActivitySector,
  getAddressPreferenceType,
  getAddressType,
  getAssetCatalog,
  getAssetStatut,
  getAssetType,
  getAssetsMock,
  getBaseCalcule,
  getBodiesAsset,
  getBrand,
  getBrandsAsset,
  getCategoriesAsset,
  getChannel,
  getClientStatus,
  getClientType,
  getColorsAsset,
  getContactType,
  getCountries,
  getDecisionMotif,
  getDecisionOpinion,
  getDecisionReserve,
  getDecisionStatus,
  getDelegationLevels,
  getDemandStatus,
  getEmissionsCO2Asset,
  getFacilityType,
  getFinancialInstitution,
  getFinishesAsset,
  getFuelTypesAsset,
  getGarantyBasis,
  getGarantyRules,
  getGarantyStatus,
  getGarantyType,
  getGender,
  getGeographicZone,
  getIdProof,
  getJobActivity,
  getJobOccupationType,
  getJobPositionType,
  getLanguage,
  getLegalCategory,
  getMatrimonialRegime,
  getModelsAsset,
  getModule,
  getNamesServices,
  getNationality,
  getNature,
  getNatureBalanceSheet,
  getOccupation,
  getOrderItemStatus,
  getOrderServiceType,
  getOrderStatus,
  getOrderTypeFinancing,
  getPartyClass,
  getPartyClassificationType,
  getPartyRelationTypes,
  getPartyRules,
  getPaymentDate,
  getPaymentFrequence,
  getPeriodRateOption,
  getPeriodicity,
  getPicklistInitialValues,
  getPostalCodes,
  getPowersHPAsset,
  getProductAvailabilityContext,
  getProductBasis,
  getProductTax,
  getProductTaxServices,
  getQualificationType,
  getRange,
  getRelationType,
  getRelations,
  getRoleStatus,
  getScaleCategory,
  getScaleClassification,
  getScaleFinancialProfile,
  getScaleGracePeriodCalculationMethod,
  getScaleProductFrequency,
  getScaleRateBasis,
  getScaleStatus,
  getServiceType,
  getSignatureMethods,
  getStatusMarital,
  getStatusOrderList,
  getTerm,
  getTitles,
  getTypeOutstanding,
  getTypeService,
  getUnderlyingType,
  getUsageAsset,
  getUserSecurityGroup,
  getUserTeam,
  getWarrantMethod,
  getcodeNaceOptions,
  getcontractType,
  getdocumentType,
  getpropertyStatus,
  getroleOrder,
  getuserNetwork,
  getuserNetworkNode,
} from "@/store/services/picklist";
import Notify from 'quasar/src/plugins/Notify.js';;
import { translateComboBox, translatePicklist } from "@/utils";
import store from "..";
import i18n from "@/i18n";

const picklistModule: Module<PicklistState, any> = {
  namespaced: true,
  state: {},
  getters: {
    financialProfileItems: (state) => {
        if (!state.financialProfile || !state.financialProfile.items || state.financialProfile.items.length === 0) {
            getScaleFinancialProfile().then((financialProfile) => {
              if(financialProfile.items.length === 0) {
                return;
              }
              state.financialProfile = financialProfile;
            });
        }
        return state.financialProfile?.items ?? [];
    },
    scaleStatusItems: (state) => {
        if (!state.scaleStatus || !state.scaleStatus.items || state.scaleStatus.items.length === 0) {
            getScaleStatus().then((scaleStatus) => {
              if(scaleStatus.items.length === 0) {
                return;
              }
              state.scaleStatus = scaleStatus;
            });
        }
        if (state.scaleStatus?.items) {
            return translateComboBox(state.scaleStatus.items, 'scales.picklist.status');
        } else {
          return [];
        }
    },
    scaleCategoryItems: (state) => {
        if (!state.scaleCategory || !state.scaleCategory.items || state.scaleCategory.items.length === 0) {
            getScaleCategory().then((scaleCategory) => {
              if(scaleCategory.items.length === 0) {
                return;
              }
              state.scaleCategory = scaleCategory;
            });
        }
        return state.scaleCategory?.items ?? [];
    },
    assetCategoryItems: (state) => {
        if (!state.categoryAsset || !state.categoryAsset.items || state.categoryAsset.items.length === 0) {
            getCategoriesAsset().then((categoryAsset) => {
              if(categoryAsset.items.length === 0) {
                return;
              }
              state.categoryAsset = categoryAsset;
            });
        }
        return state.categoryAsset?.items ?? [];
    },
    usageAssetItems: (state) => {
        if (!state.usageAsset || !state.usageAsset.items || state.usageAsset.items.length === 0) {
            getUsageAsset().then((usageAsset) => {
              if(usageAsset.items.length === 0) {
                return;
              }
              state.usageAsset = usageAsset;
            });
        }
        return state.usageAsset?.items ?? [];
    },
    colorsList: (state) => {
        if (!state.colors || !state.colors.items || state.colors.items.length === 0) {
            getColorsAsset().then((colors) => {
              if(colors.items.length === 0) {
                return;
              }
              state.colors = colors;
            });
        }
        return state.colors?.items ?? [];
    },
    activityList: (state) => {
        if (!state.activity || !state.activity.items || state.activity.items.length === 0) {
            getActivityAsset().then((activity) => {
              if(activity.items.length === 0) {
                return;
              }
              state.activity = activity;
            });
        }
        return state.activity?.items ?? [];
    },
    productTax: (state) => {
        if (!state.productTax || !state.productTax.items || state.productTax.items.length === 0) {
            getProductTax().then((productTax) => {
              if(productTax.items.length === 0) {
                return;
              }
              state.productTax = productTax;
            });
        }
        if (state.productTax?.items) {
            return translateComboBox(state.productTax.items, 'picklists.productTaxOptions');
        } else {
          return [];
        }
    },
    productTaxServicesItems: (state) => {
      if (!state.productTaxServices || !state.productTaxServices.items || state.productTaxServices.items.length === 0) {
          getProductTaxServices().then((productTaxServices) => {
            if(productTaxServices.items.length === 0) {
              return;
            }
            state.productTaxServices = productTaxServices;
          });
      }
      return state.productTaxServices?.items ?? [];
  },
  scaleFrequencyItems: (state) => {
      if (!state.scalefrequency || !state.scalefrequency.items || state.scalefrequency.items.length === 0) {
          getScaleProductFrequency().then((scaleFrequency) => {
            if(scaleFrequency.items.length === 0) {
              return;
            }
            state.scalefrequency = scaleFrequency;
          });
      }
      return state.scalefrequency?.items ?? [];
  },
  scaleRateBasisItems: (state) => {
      if (!state.scaleRateBasis || !state.scaleRateBasis.items || state.scaleRateBasis.items.length === 0) {
          getScaleRateBasis().then((scaleRateBasis) => {
            if(scaleRateBasis.items.length === 0) {
              return;
            }
            state.scaleRateBasis = scaleRateBasis;
          });
      }
      return state.scaleRateBasis?.items ?? [];
  },
  scaleGracePeriodCalculationMethodItems: (state) => {
      if (!state.scaleGracePeriodCalculationMethod || !state.scaleGracePeriodCalculationMethod.items || state.scaleGracePeriodCalculationMethod.items.length === 0) {
          getScaleGracePeriodCalculationMethod().then((scaleGracePeriodCalculationMethod) => {
            if(scaleGracePeriodCalculationMethod.items.length === 0) {
              return;
            }
            state.scaleGracePeriodCalculationMethod = scaleGracePeriodCalculationMethod;
          });
      }
      return state.scaleGracePeriodCalculationMethod?.items ?? [];
  },
  signatureMethodsItems: (state) => {
      if (!state.signatureMethods || !state.signatureMethods.items || state.signatureMethods.items.length === 0) {
          getSignatureMethods().then((signatureMethods) => {
            if(signatureMethods.items.length === 0) {
              return;
            }
            state.signatureMethods = signatureMethods;
          });
      }
      return state.signatureMethods?.items ?? [];
  },
  serviceTypeItems: (state) => {
      if (!state.serviceType || !state.serviceType.items || state.serviceType.items.length === 0) {
          getServiceType().then((serviceType) => {
            if(serviceType.items.length === 0) {
              return;
            }
            state.serviceType = serviceType;
          });
      }
      if (state.serviceType?.items) {
          return translateComboBox(state.serviceType.items, 'scales.picklist.serviceTypes');
      } else {
        return [];
      }
  },
  partyClassificationTypeItems: (state) => {
      if (!state.partyClassificationType || !state.partyClassificationType.items || state.partyClassificationType.items.length === 0) {
          getPartyClassificationType().then((partyClassificationType) => {
            if(partyClassificationType.items.length === 0) {
              return;
            }
            state.partyClassificationType = partyClassificationType;
          });
      }
      if (state.partyClassificationType?.items) {
          return translateComboBox(state.partyClassificationType.items, 'picklists.partyClassificationType');
      } else {
        return [];
      }
  },
  productBasisItems: (state) => {
      if (!state.productBasis || !state.productBasis.items || state.productBasis.items.length === 0) {
          getProductBasis().then((productBasis) => {
            if(productBasis.items.length === 0) {
              return;
            }
            state.productBasis = productBasis;
          });
      }
      if (state.productBasis?.items) {
          return translateComboBox(state.productBasis.items, 'scales.picklist.productBasis');
      } else {
        return [];
      }
  },
  accessoryCalculationMethodItems: (state) => {
      if (!state.accessoryCalculationMethod || !state.accessoryCalculationMethod.items || state.accessoryCalculationMethod.items.length === 0) {
          getAccessoryCalculationMethod().then((accessoryCalculationMethod) => {
            if(accessoryCalculationMethod.items.length === 0) {
              return;
            }
            state.accessoryCalculationMethod = accessoryCalculationMethod;
          });
      }
      if (state.accessoryCalculationMethod?.items) {
          return translateComboBox(state.accessoryCalculationMethod.items, 'scales.picklist.accessoryCalculationMethod');
      } else {
        return [];
      }
  },
  accessoryPaymentDatesOptionItems: (state) => {
      if (!state.accessoryPaymentDatesOption || !state.accessoryPaymentDatesOption.items || state.accessoryPaymentDatesOption.items.length === 0) {
          getAccessoryPaymentDatesOption().then((accessoryPaymentDatesOption) => {
            if(accessoryPaymentDatesOption.items.length === 0) {
              return;
            }
            state.accessoryPaymentDatesOption = accessoryPaymentDatesOption;
          });
      }
      return state.accessoryPaymentDatesOption?.items ?? [];
  },
  assetTypeItems: (state) => {
      if (!state.assetTypeItems || !state.assetTypeItems.items || state.assetTypeItems.items.length === 0) {
          getAssetType().then((assetTypeItems) => {
            if(assetTypeItems.items.length === 0) {
              return;
            }
            state.assetTypeItems = assetTypeItems;
          });
      }
      return state.assetTypeItems?.items ?? [];
  },
  assetStatutItems: (state) => {
    if (!state.assetStatut || !state.assetStatut.items || state.assetStatut.items.length === 0) {
        getAssetStatut().then((assetStatut) => {
          if(assetStatut.items.length === 0) {
            return;
          }
          state.assetStatut = assetStatut;
        });
    }
    return state.assetStatut?.items ?? [];
  },
  assetCatalogItems: (state) => {
    if (!state.assetCatalog || !state.assetCatalog.items || state.assetCatalog.items.length === 0) {
        getAssetCatalog().then((assetCatalog) => {
          if(assetCatalog.items.length === 0) {
            return;
          }
          state.assetCatalog = assetCatalog;
        });
    }
    return state.assetCatalog?.items ?? [];
  },
  garantyStatusItems: (state) => {
    if (!state.garantyStatus || !state.garantyStatus.items || state.garantyStatus.items.length === 0) {
        getGarantyStatus().then((garantyStatus) => {
          if(garantyStatus.items.length === 0) {
            return;
          }
          state.garantyStatus = garantyStatus;
        });
    }
    if (state.garantyStatus?.items) {
        return translateComboBox(state.garantyStatus.items, 'garanty.garantyFilter.picklist.status');
    } else {
      return [];
    }
  },
  garantyTypeItems: (state) => {
    if (!state.garantyType || !state.garantyType.items || state.garantyType.items.length === 0) {
        getGarantyType().then((garantyType) => {
          if(garantyType.items.length === 0) {
            return;
          }
          state.garantyType = garantyType;
        });
    }
    if (state.garantyType?.items) {
        return translateComboBox(state.garantyType.items, 'garanty.garantyFilter.picklist.type');
    } else {
      return [];
    }
  },
  typeOutstandingItems: (state) => {
    if (!state.typeOutstanding || !state.typeOutstanding.items || state.typeOutstanding.items.length === 0) {
        getTypeOutstanding().then((typeOutstanding) => {
          if(typeOutstanding.items.length === 0) {
            return;
          }
          state.typeOutstanding = typeOutstanding;
        });
    }
    return state.typeOutstanding?.items ?? [];
  },
  financialInstitutionItems: (state) => {
    if (!state.financialInstitution || !state.financialInstitution.items || state.financialInstitution.items.length === 0) {
        getFinancialInstitution().then((financialInstitution) => {
          if(financialInstitution.items.length === 0) {
            return;
          }
          state.financialInstitution = financialInstitution;
        });
    }
    return state.financialInstitution?.items ?? [];
  },
  underlyingTypeItems: (state) => {
    if (!state.underlyingType || !state.underlyingType.items || state.underlyingType.items.length === 0) {
        getUnderlyingType().then((underlyingType) => {
          if(underlyingType.items.length === 0) {
            return;
          }
          state.underlyingType = underlyingType;
        });
    }
    return state.underlyingType?.items ?? [];
  },
  garantybasisItems: (state) => {
    if (!state.garantyBasis || !state.garantyBasis.items || state.garantyBasis.items.length === 0) {
        getGarantyBasis().then((garantyBasis) => {
          if(garantyBasis.items.length === 0) {
            return;
          }
          state.garantyBasis = garantyBasis;
        });
    }
    return state.garantyBasis?.items ?? [];
  },
  genderItems: (state) => {
    if (!state.gender || !state.gender.items || state.gender.items.length === 0) {
        getGender().then((gender) => {
          if(gender.items.length === 0) {
            return;
          }
          state.gender = gender;
        });
    }
    return state.gender?.items ?? [];
  },
  propertyStatusItems: (state) => {
    if (!state.propertyStatus || !state.propertyStatus.items || state.propertyStatus.items.length === 0) {
        getpropertyStatus().then((propertyStatus) => {
          if(propertyStatus.items.length === 0) {
            return;
          }
          state.propertyStatus = propertyStatus;
        });
    }
    return state.propertyStatus?.items ?? [];
  },
  contractTypeItems: (state) => {
    if (!state.contractType || !state.contractType.items || state.contractType.items.length === 0) {
        getcontractType().then((contractType) => {
          if(contractType.items.length === 0) {
            return;
          }
          state.contractType = contractType;
        });
    }
    return state.contractType?.items ?? [];
  },
  productAvailabilityContextItems: (state) => {
    if (!state.productAvailabilityContext || !state.productAvailabilityContext.items || state.productAvailabilityContext.items.length === 0) {
        getProductAvailabilityContext().then((productAvailabilityContext) => {
          if(productAvailabilityContext.items.length === 0) {
            return;
          }
          state.productAvailabilityContext = productAvailabilityContext;
        });
    }
    return state.productAvailabilityContext?.items ?? [];
  },
  roleItems: (state) => {
    if (!state.role || !state.role.items || state.role.items.length === 0) {
        getPartyRules().then((role) => {
          if(role.items.length === 0) {
            return;
          }
          state.role = role;
        });
    }
    if (state.role?.items) {
        return translateComboBox(state.role.items, 'demand.party.roles');
    } else {
      return [];
    }
  },
  roleOrderItems: (state) => {
    if (!state.roleOrder || !state.roleOrder.items || state.roleOrder.items.length === 0) {
        getroleOrder().then((roleOrder) => {
          if(roleOrder.items.length === 0) {
            return;
          }
          state.roleOrder = roleOrder;
        });
    }
    if (state.roleOrder?.items) {
        return translateComboBox(state.roleOrder.items, 'demand.party.roles');
    } else {
      return [];
    }
  },
  geographicZoneItems: (state) => {
    if (!state.geographicZone || !state.geographicZone.items || state.geographicZone.items.length === 0) {
        getGeographicZone().then((geographicZone) => {
          if(geographicZone.items.length === 0) {
            return;
          }
          state.geographicZone = geographicZone;
        });
    }
    if (state.geographicZone?.items) {
        return translateComboBox(state.geographicZone.items, 'demand.party.geographicZone');
    } else {
      return [];
    }
  },
  facilityTypeItems: (state) => {
    if (!state.facilityType || !state.facilityType.items || state.facilityType.items.length === 0) {
        getFacilityType().then((facilityType) => {
          if(facilityType.items.length === 0) {
            return;
          }
          state.facilityType = facilityType;
        });
    }
    if (state.facilityType?.items) {
        return translateComboBox(state.facilityType.items, 'middleOffice.party.organization.establishments.types');
    } else {
      return [];
    }
  },
  qualificationTypeItems: (state) => {
    if (!state.qualificationType || !state.qualificationType.items || state.qualificationType.items.length === 0) {
        getQualificationType().then((qualificationType) => {
          if(qualificationType.items.length === 0) {
            return;
          }
          state.qualificationType = qualificationType;
        });
    }
    if (state.qualificationType?.items) {
        return translateComboBox(state.qualificationType.items, 'middleOffice.party.organization.contact.fonction');
    } else {
      return [];
    }
  },
  natureItems: (state) => {
    if (!state.nature || !state.nature.items || state.nature.items.length === 0) {
        getNature().then((nature) => {
          if(nature.items.length === 0) {
            return;
          }
          state.nature = nature;
        });
    }
    if (state.nature?.items) {
        return translateComboBox(state.nature.items, 'incomeAndExpenses.type');
    } else {
      return [];
    }
  },
  statusMaritalItems: (state) => {
    if (!state.statusMarital || !state.statusMarital.items || state.statusMarital.items.length === 0) {
        getStatusMarital().then((statusMarital) => {
          if(statusMarital.items.length === 0) {
            return;
          }
          state.statusMarital = statusMarital;
        });
    }
    if (state.statusMarital?.items) {
        return translateComboBox(state.statusMarital.items, 'demand.party.statusMarital');
    } else {
      return [];
    }
  },
  matrimonialRegimeItems: (state) => {
    if (!state.matrimonialRegime || !state.matrimonialRegime.items || state.matrimonialRegime.items.length === 0) {
        getMatrimonialRegime().then((matrimonialRegime) => {
          if(matrimonialRegime.items.length === 0) {
            return;
          }
          state.matrimonialRegime = matrimonialRegime;
        });
    }
    if (state.matrimonialRegime?.items) {
        return translateComboBox(state.matrimonialRegime.items, 'demand.party.regimeMatrimonial');
    } else {
      return [];
    }
  },

    //demand
    demandStatusItems: (state) => {
      if (!state.demandStatus || !state.demandStatus.items || state.demandStatus.items.length === 0) {
        getDemandStatus().then((demandStatus) => {
          if(demandStatus.items.length === 0) {
            return;
          }
          state.demandStatus = demandStatus
        });
      }
      if (state.demandStatus?.items) {
        return translatePicklist(state.demandStatus.items, 'enum.offer.status');
      } else {
        return [];
      }
    },
    brandItems: (state) => {
      if (!state.brand || !state.brand.items || state.brand.items.length === 0) {
        getBrand().then((brandItems: any) => {
          if(brandItems.items.length === 0) {
            return;
          }
          state.brand = brandItems;
        });
      }
      return state.brand?.items ?? [];
    },
    clientTypeItems: (state) => {
      if (!state.clientType || !state.clientType.items || state.clientType.items.length === 0) {
        getClientType().then((clientType) => {
          if(clientType.items.length === 0) {
            return;
          }
          state.clientType = clientType;
        });
      }
      return state.clientType?.items ?? [];
    },
    channelItems: (state) => {
      if (!state.channel || !state.channel.items || state.channel.items.length === 0) {
        getChannel().then((channel) => {
          if(channel.items.length === 0) {
            return;
          }
          state.channel = channel;
        });
      }
      return state.channel?.items ?? [];
    },
    moduleItems: (state) => {
      if (!state.module || !state.module.items || state.module.items.length === 0) {
        getModule().then((module) => {
          if(module.items.length === 0) {
            return;
          }
          state.module = module;
        });
      }
      return state.module?.items ?? [];
    },
    orderStatus: (state) => {
      if (!state.orderStatus || !state.orderStatus.items || state.orderStatus.items.length === 0) {
        getOrderStatus().then((orderStatus) => {
          if(orderStatus.items.length === 0) {
            return;
          }
          state.orderStatus = orderStatus;
        });
      }
      if (state.orderStatus?.items) {
        return translateComboBox(state.orderStatus.items, 'order.picklist.status');
      } else {
        return [];
      }
    },
    orderItemStatus: (state) => {
      if (!state.orderItemStatus || !state.orderItemStatus.items || state.orderItemStatus.items.length === 0) {
        getOrderItemStatus().then((order) => {
          if(order.items.length === 0) {
            return;
          }
          state.orderItemStatus = order;
        });
      }
      return state.orderItemStatus?.items ?? [];
    },
    orderServiceTypeItems: (state) => {
      if (!state.orderServiceType || !state.orderServiceType.items || state.orderServiceType.items.length === 0) {
        getOrderServiceType().then((res) => {
          if(res.items.length === 0) {
            return;
          }
          state.orderServiceType = res;
        });
      }
      return state.orderServiceType?.items ?? [];
    },
    rangeItems: (state) => {
      if (!state.range || !state.range.items || state.range.items.length === 0) {
        getRange().then((range) => {
          if(range.items.length === 0) {
            return;
          }
          state.range = range;
        });
      }
      return state.range?.items ?? [];
    },

    //Decision
    decisionOpinionItems: (state) => {
      if (!state.decisionOpinion || !state.decisionOpinion.items || state.decisionOpinion.items.length === 0) {
          getDecisionOpinion().then((decisionOpinion) => {
            if(decisionOpinion.items.length === 0) {
              return;
            }
            state.decisionOpinion = decisionOpinion;
          });
      }
      return state.decisionOpinion?.items ?? [];
  },
  delegationLevelsItems: (state) => {
      if (!state.delegationLevels || !state.delegationLevels.items || state.delegationLevels.items.length === 0) {
          getDelegationLevels().then((delegationLevels) => {
            if(delegationLevels.items.length === 0) {
              return;
            }
            state.delegationLevels = delegationLevels;
          });
      }
      if (state.delegationLevels?.items) {
        return state.delegationLevels.items.map(item => ({
          value: item.value,
          label: i18n.global.t(`decision.label.${item.label}`)
        }));
      } else {
        return [];
      }
  },
  userSecurityGroupItems: (state) => {
      if (!state.userSecurityGroup || !state.userSecurityGroup.items || state.userSecurityGroup.items.length === 0) {
          getUserSecurityGroup().then((userSecurityGroup) => {
            if(userSecurityGroup.items.length === 0) {
              return;
            }
            state.userSecurityGroup = userSecurityGroup;
          });
      }
      return state.userSecurityGroup?.items ?? [];
  },
  statusOrderList: (state) => {
    if (!state.statusOrder || !state.statusOrder.items || state.statusOrder.items.length === 0) {
      getStatusOrderList().then((statusOrder) => {
          if(statusOrder.items.length === 0) {
            return;
          }
          state.statusOrder = statusOrder;
        });
    }
    return state.statusOrder?.items ?? [];
},

  userTeamItems: (state) => {
      if (!state.userTeam || !state.userTeam.items || state.userTeam.items.length === 0) {
          getUserTeam().then((userTeam) => {
            if(userTeam.items.length === 0) {
              return;
            }
            state.userTeam = userTeam;
          });
      }
      return state.userTeam?.items ?? [];
  },
  userNetwork: (state) => {
      if (!state.userNetwork || !state.userNetwork.items || state.userNetwork.items.length === 0) {
          getuserNetwork().then((network) => {
            if(network.items.length === 0) {
              return;
            }
            state.userNetwork = network;
          });
      }
      return state.userNetwork?.items ?? [];
  },
  userNetworkNode: (state) => {
      if (!state.userNetworkNode || !state.userNetworkNode.items || state.userNetworkNode.items.length === 0) {
          getuserNetworkNode().then((userNetworkNode) => {
            if(userNetworkNode.items.length === 0) {
              return;
            }
            state.userNetworkNode = userNetworkNode;
          });
      }
      return state.userNetworkNode?.items ?? [];
  },
  documentTypeItems: (state) => {
      if (!state.documentType || !state.documentType.items || state.documentType.items.length === 0) {
          getdocumentType().then((documentType) => {
            if(documentType.items.length === 0) {
              return;
            }
            state.documentType = documentType;
          });
      }
      return state.documentType?.items ?? [];
  },
  jobOccupationTypeItems: (state) => {
      if (!state.jobOccupationType || !state.jobOccupationType.items || state.jobOccupationType.items.length === 0) {
          getJobOccupationType([]).then((jobOccupationType) => {
            if(jobOccupationType.items.length === 0) {
              return;
            }
            state.jobOccupationType = jobOccupationType;
          });
      }
    if (state.jobOccupationType?.items) {
        return translateComboBox(state.jobOccupationType.items, 'picklists.socioProfessionalCode.');
    } else {
      return [];
    }
  },
  codeNaceOptionsItems: (state) => {
    if (!state.codeNaceOptions || !state.codeNaceOptions.items || state.codeNaceOptions.items.length === 0) {
        getcodeNaceOptions().then((codeNaceOptions) => {
          if(codeNaceOptions.items.length === 0) {
            return;
          }
          state.codeNaceOptions = codeNaceOptions;
        });
    }
    if (state.codeNaceOptions?.items) {
        return translateComboBox(state.codeNaceOptions.items, 'picklists.codeNace.');
    } else {
      return [];
    }
  },
  typeServiceItems: (state) => {
    if (!state.typeService || !state.typeService.items || state.typeService.items.length === 0) {
        getTypeService().then((typeService) => {
          if(typeService.items.length === 0) {
            return;
          }
          state.typeService = typeService;
        });
    }
    return state.typeService?.items ?? [];
  },
  baseCalculeItems: (state) => {
      if (!state.baseCalcule || !state.baseCalcule.items || state.baseCalcule.items.length === 0) {
          getBaseCalcule().then((baseCalcule) => {
            if(baseCalcule.items.length === 0) {
              return;
            }
            state.baseCalcule = baseCalcule;
          });
      }
      return state.baseCalcule?.items ?? [];
  },
  termItems: (state) => {
      if (!state.term || !state.term.items || state.term.items.length === 0) {
          getTerm().then((term) => {
            if(term.items.length === 0) {
              return;
            }
            state.term = term;
          });
      }
      return state.term?.items ?? [];
  },
  paymentDateItems: (state) => {
      if (!state.paymentDate || !state.paymentDate.items || state.paymentDate.items.length === 0) {
          getPaymentDate().then((paymentDate) => {
            if(paymentDate.items.length === 0) {
              return;
            }
            state.paymentDate = paymentDate;
          });
      }
      return state.paymentDate?.items ?? [];
  },
  paymentFrequenceItems: (state) => {
      if (!state.paymentFrequence || !state.paymentFrequence.items || state.paymentFrequence.items.length === 0) {
          getPaymentFrequence().then((paymentFrequence) => {
            if(paymentFrequence.items.length === 0) {
              return;
            }
            state.paymentFrequence = paymentFrequence;
          });
      }
      return state.paymentFrequence?.items ?? [];
  },
  currentStatusCodeItems: (state) => {
      if (!state.current_status_code || !state.current_status_code.items || state.current_status_code.items.length === 0) {
          getClientStatus().then((current_status_code) => {
            if(current_status_code.items.length === 0) {
              return;
            }
            state.current_status_code = current_status_code;
          });
      }
      return state.current_status_code?.items ?? [];
  },
  activitySectorItems: (state) => {
      if (!state.activitySector || !state.activitySector.items || state.activitySector.items.length === 0) {
          getActivitySector().then((activitySector) => {
            if(activitySector.items.length === 0) {
              return;
            }
            state.activitySector = activitySector;
          });
      }
    if (state.activitySector?.items) {
      return translateComboBox(state.activitySector.items, 'picklists.activity.');
    } else {
      return [];
    }
  },
  legalCategoryItems: (state) => {
      if (!state.legalCategory || !state.legalCategory.items || state.legalCategory.items.length === 0) {
          getLegalCategory().then((legalCategory) => {
            if(legalCategory.items.length === 0) {
              return;
            }
            state.legalCategory = legalCategory;
          });
      }
      if (state.legalCategory?.items) {
        return translateComboBox(state.legalCategory.items, 'picklists.legal-category.');
      } else {
        return [];
      }
  },
  relationsItems: (state) => {
      if (!state.relations || !state.relations.items || state.relations.items.length === 0) {
          getRelations().then((relations) => {
            if(relations.items.length === 0) {
              return;
            }
            state.relations = relations;
          });
      }
      return state.relations?.items ?? [];
  },
  relationTypeItems: (state) => {
      if (!state.relationType || !state.relationType.items || state.relationType.items.length === 0) {
          getRelationType().then((relationType) => {
            if(relationType.items.length === 0) {
              return;
            }
            state.relationType = relationType;
          });
      }
      return state.relationType?.items ?? [];
  },
  countriesItems: (state) => {
    if (!state.countries || !state.countries.items || state.countries.items.length === 0) {
        getCountries().then((countries) => {
          if(countries.items.length === 0) {
            return;
          }
          state.countries = countries;
        });
    }
    return state.countries?.items ?? [];
  },
  postalCodesItems: (state) => {
    if (!state.postalCodes || !state.postalCodes.items || state.postalCodes.items.length === 0) {
        getPostalCodes().then((postalCodes) => {
          if(postalCodes.items.length === 0) {
            return;
          }
          state.postalCodes = postalCodes;
        });
    }
    return state.postalCodes?.items ?? [];
  },
  addressTypeItems: (state) => {
    if (!state.addressType || !state.addressType.items || state.addressType.items.length === 0) {
        getAddressType().then((addressType) => {
          if(addressType.items.length === 0) {
            return;
          }
          state.addressType = addressType;
        });
    }
    return state.addressType?.items ?? [];
  },
  addressPreferenceTypeItems: (state) => {
    if (!state.addressPreferenceType || !state.addressPreferenceType.items || state.addressPreferenceType.items.length === 0) {
        getAddressPreferenceType().then((addressPreferenceType) => {
          if(addressPreferenceType.items.length === 0) {
            return;
          }
          state.addressPreferenceType = addressPreferenceType;
        });
    }
    return state.addressPreferenceType?.items ?? [];
  },
  titlesItems: (state) => {
    if (!state.titles || !state.titles.items || state.titles.items.length === 0) {
        getTitles().then((titles) => {
          if(titles.items.length === 0) {
            return;
          }
          state.titles = titles;
        });
    }
    return state.titles?.items ?? [];
  },
  occupationItems: (state) => {
    if (!state.occupation || !state.occupation.items || state.occupation.items.length === 0) {
        getOccupation().then((occupation) => {
          if(occupation.items.length === 0) {
            return;
          }
          state.occupation = occupation;
        });
    }
    return state.occupation?.items ?? [];
  },
  periodRateOptionItems: (state) => {
    if (!state.periodRateOption || !state.periodRateOption.items || state.periodRateOption.items.length === 0) {
        getPeriodRateOption().then((periodRateOption) => {
          if(periodRateOption.items.length === 0) {
            return;
          }
          state.periodRateOption = periodRateOption;
        });
    }
    return state.periodRateOption?.items ?? [];
  },
  warrantMethodItems: (state) => {
    if (!state.warrantMethod || !state.warrantMethod.items || state.warrantMethod.items.length === 0) {
        getWarrantMethod().then((warrantMethod) => {
          if(warrantMethod.items.length === 0) {
            return;
          }
          state.warrantMethod = warrantMethod;
        });
    }
    return state.warrantMethod?.items ?? [];
  },

  contactTypeItems: (state) => {
    if (!state.contactType || !state.contactType.items || state.contactType.items.length === 0) {
      getContactType().then((contactType) => {
        if(contactType.items.length === 0) {
          return;
        }
        state.contactType = contactType;
      });
    }
    return state.contactType?.items ?? [];
  },
  idProofItems: (state) => {
    if (!state.IdProof || !state.IdProof.items || state.IdProof.items.length === 0) {
      getIdProof().then((idProof) => {
        if(idProof.items.length === 0) {
          return;
        }
        state.IdProof = idProof;
      });
    }
    return state.IdProof?.items ?? [];
  },
  accountTypeItems: (state) => {
    if (!state.accountType || !state.accountType.items || state.accountType.items.length === 0) {
      getAccountType().then((accountType) => {
        if(accountType.items.length === 0) {
          return;
        }
        state.accountType = accountType;
      });
    }
    return state.accountType?.items ?? [];
  },
  accountStatusItems: (state) => {
    if (!state.accountStatus || !state.accountStatus.items || state.accountStatus.items.length === 0) {
      getAccountStatus().then((accountStatus) => {
        if(accountStatus.items.length === 0) {
          return;
        }
        state.accountStatus = accountStatus;
      });
    }
    return state.accountStatus?.items ?? [];
  },
  nationalityItems: (state) => {
    if (!state.nationality || !state.nationality.items || state.nationality.items.length === 0) {
      getNationality([]).then((nationality) => {
        if(nationality.items.length === 0) {
          return;
        }
        state.nationality = nationality;
      });
    }
    return state.nationality?.items ?? [];
  },
  jobActivityItems: (state) => {
    if (!state.jobActivity || !state.jobActivity.items || state.jobActivity.items.length === 0) {
      getJobActivity().then((jobActivity) => {
        if(jobActivity.items.length === 0) {
          return;
        }
        state.jobActivity = jobActivity;
      });
    }
    return state.jobActivity?.items ?? [];
  },
  jobPositionTypeItems: (state) => {
    if (!state.jobPositionType || !state.jobPositionType.items || state.jobPositionType.items.length === 0) {
      getJobPositionType().then((jobPositionType) => {
        if(jobPositionType.items.length === 0) {
          return;
        }
        state.jobPositionType = jobPositionType;
      });
    }
    return state.jobPositionType?.items ?? [];
  },
  periodicityItems: (state) => {
    if (!state.periodicity || !state.periodicity.items || state.periodicity.items.length === 0) {
      getPeriodicity().then((periodicity) => {
        if(periodicity.items.length === 0) {
          return;
        }
        state.periodicity = periodicity;
      });
    }
    return state.periodicity?.items ?? [];
  },
  partyRelationTypeItems: (state) => {
    if (!state.partyRelationType || !state.partyRelationType.items || state.partyRelationType.items.length === 0) {
      getPartyRelationTypes().then((partyRelationType) => {
        if(partyRelationType.items.length === 0) {
          return;
        }
        state.partyRelationType = partyRelationType;
      });
    }
    return state.partyRelationType?.items ?? [];
  },
  languageItems: (state) => {
    if (!state.language || !state.language.items || state.language.items.length === 0) {
      getLanguage().then((language) => {
        if(language.items.length === 0) {
          return;
        }
        state.language = language;
      });
    }
    return state.language?.items ?? [];
  },
  bodyAssetItems: (state) => {
    if (!state.bodyAsset || !state.bodyAsset.items || state.bodyAsset.items.length === 0) {
      getBodiesAsset().then((bodyAsset) => {
        if(bodyAsset.items.length === 0) {
          return;
        }
        state.bodyAsset = bodyAsset;
      });
    }
    return state.bodyAsset?.items ?? [];
  },
  brandAssetItems: (state) => {
    if (!state.brandAsset || !state.brandAsset.items || state.brandAsset.items.length === 0) {
      getBrandsAsset().then((brandAsset) => {
        if(brandAsset.items.length === 0) {
          return;
        }
        state.brandAsset = brandAsset;
      });
    }
    return state.brandAsset?.items ?? [];
  },

  modelAssetItems: (state) => {
    if (!state.modelAsset || !state.modelAsset.items || state.modelAsset.items.length === 0) {
      getModelsAsset().then((modelAsset) => {
        if(modelAsset.items.length === 0) {
          return;
        }
        state.modelAsset = modelAsset;
      });
    }
    return state.modelAsset?.items ?? [];
  },
  finishingAssetItems: (state) => {
    if (!state.finishingAsset || !state.finishingAsset.items || state.finishingAsset.items.length === 0) {
      getFinishesAsset().then((finishingAsset) => {
        if(finishingAsset.items.length === 0) {
          return;
        }
        state.finishingAsset = finishingAsset;
      });
    }
    return state.finishingAsset?.items ?? [];
  },
  fuelTypeAssetItems: (state) => {
    if (!state.fuelTypeAsset || !state.fuelTypeAsset.items || state.fuelTypeAsset.items.length === 0) {
      getFuelTypesAsset().then((fuelTypeAsset) => {
        if(fuelTypeAsset.items.length === 0) {
          return;
        }
        state.fuelTypeAsset = fuelTypeAsset;
      });
    }
    return state.fuelTypeAsset?.items ?? [];
  },
  emissionCO2AssetItems: (state) => {
    if (!state.emissionCO2Asset || !state.emissionCO2Asset.items || state.emissionCO2Asset.items.length === 0) {
      getEmissionsCO2Asset().then((emissionCO2Asset) => {
        if(emissionCO2Asset.items.length === 0) {
          return;
        }
        state.emissionCO2Asset = emissionCO2Asset;
      });
    }
    return state.emissionCO2Asset?.items ?? [];
  },
  powerHPAssetItems: (state) => {
    if (!state.powerHPAsset || !state.powerHPAsset.items || state.powerHPAsset.items.length === 0) {
      getPowersHPAsset().then((powerHPAsset) => {
        if(powerHPAsset.items.length === 0) {
          return;
        }
        state.powerHPAsset = powerHPAsset;
      });
    }
    return state.powerHPAsset?.items ?? [];
  },
  garantyRoleItems: (state) => {
    if (!state.garantyrole || !state.garantyrole.items || state.garantyrole.items.length === 0) {
      getGarantyRules().then((garantyrole) => {
        if(garantyrole.items.length === 0) {
          return;
        }
        state.garantyrole = garantyrole;
      });
    }
    if(state.garantyrole?.items){
      return translateComboBox(state.garantyrole.items, 'demand.party.roles');
    } else {
      return [];
    }
  },
  roleStatusItems: (state) => {
    if (!state.roleStatus || !state.roleStatus.items || state.roleStatus.items.length === 0) {
      getRoleStatus().then((roleStatus) => {
        if(roleStatus.items.length === 0) {
          return;
        }
        state.roleStatus = roleStatus;
      });
    }
    return state.roleStatus?.items ?? [];
  },
  natureBalanceSheetItems: (state) => {
    if (!state.natureBalanceSheet || !state.natureBalanceSheet.items || state.natureBalanceSheet.items.length === 0) {
      getNatureBalanceSheet().then((natureBalanceSheet) => {
        if(natureBalanceSheet.items.length === 0) {
          return;
        }
        state.natureBalanceSheet = natureBalanceSheet;
      });
    }
    return state.natureBalanceSheet?.items ?? [];
  },
  decisionMotifItems: (state) => {
    if (!state.decisionMotif || !state.decisionMotif.items || state.decisionMotif.items.length === 0) {
      getDecisionMotif().then((decisionMotif) => {
        if(decisionMotif.items.length === 0) {
          return;
        }
        state.decisionMotif = decisionMotif;
      });
    }
    return state.decisionMotif?.items ?? [];
  },
  decisionreserveItems: (state) => {
    if (!state.decisionreserve || !state.decisionreserve.items || state.decisionreserve.items.length === 0) {
      getDecisionReserve().then((decisionreserve) => {
        if(decisionreserve.items.length === 0) {
          return;
        }
        state.decisionreserve = decisionreserve;
      });
    }
    return state.decisionreserve?.items ?? [];
  },
  decisionStatusItems: (state) => {
    if (!state.decisionStatus || !state.decisionStatus.items || state.decisionStatus.items.length === 0) {
      getDecisionStatus().then((decisionStatus) => {
        if(decisionStatus.items.length === 0) {
          return;
        }
        state.decisionStatus = decisionStatus;
      });
    }
    return state.decisionStatus?.items ?? [];
  },
  partyClassItems: (state) => {
    if (!state.partyClass || !state.partyClass.items || state.partyClass.items.length === 0) {
      getPartyClass().then((partyClass) => {
        if(partyClass.items.length === 0) {
          return;
        }
        state.partyClass = partyClass;
      });
    }
    if(state.partyClass?.items){
      return translateComboBox(state.partyClass.items, 'demand.party.partyClass');
    } else {
      return [];
    }
  },
  actionTypeItems: (state) => {
    if (!state.actionType || !state.actionType.items || state.actionType.items.length === 0) {
      getActionType().then((actionType) => {
        if(actionType.items.length === 0) {
          return;
        }
        state.actionType = actionType;
      });
    }
    return state.actionType?.items ?? [];
  },
  orderTypeFinancingItems: (state) => {
    if (!state.orderTypeFinancing || !state.orderTypeFinancing.items || state.orderTypeFinancing.items.length === 0) {
      getOrderTypeFinancing().then((orderTypeFinancing) => {
        if(orderTypeFinancing.items.length === 0) {
          return;
        }
        state.orderTypeFinancing = orderTypeFinancing;
      });
    }
    return state.orderTypeFinancing?.items ?? [];
  }
  },
  actions: {
    initializePicklists({ commit }) {
        getPicklistInitialValues()
        .then((res) => {
          commit("setPicklistInitialValue", res);
        })
        .catch((e) => {  
          if(store.state.authModule?.user.loggedIn) {
            Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
              message: `Request returned an error \n ${e}`,
              color: "negative",
            });
          }        
        });
    
    },
    setNamesServices({ commit }, payload) {
      const { parentId } = payload;
      getNamesServices(parentId)
        .then((res) => {
          commit("setPickListNamesServicesValue", res);
        })
        .catch((e) => {
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    applyPostalCodeQuery({ commit }, payload) {
      const { country_code, query } = payload;
      getPostalCodes(country_code, query)
        .then((res) => {
          commit("setPickListPostalCodeValue", res);
        })
        .catch((e) => {
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    applyNationalityQuery({ commit }, payload) {
      const { query , callback } = payload;
      getNationality(query)
        .then((res) => {
          commit("setPickListNationalityValue", res);
          callback(res);
        })
        .catch((e) => {
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    applyJobOccupationQuery({ commit }, payload) {
      const { query , callback} = payload;
      getJobOccupationType(query)
        .then((res) => {
          commit("setPickListJobOccupationValue", res);
          callback(res);
        })
        .catch((e) => {
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    applyAssetsQuery({ commit }, payload) {
      const { query } = payload;
      getAssetsMock(query)
        .then((res) => {
          commit("setPickListAssetsValue", res);
        })
        .catch((e) => {
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    fetchReasonsByStatus({ commit }, payload) {
      fetchReasons(payload)
        .then(response => commit('setReasons', response))
        .catch((e) => console.error(e));
    },
    populateScaleClassifications({ commit }, payload) {
      const { categoryId, callback } = payload;
      getScaleClassification(categoryId)
        .then(res => callback(res))
        .catch(err => console.error(err));
    }
  },
  mutations: {
    async setPicklistInitialValue(state, payload) {
      for (const property in payload) {
        if (payload[property]?.items && Array.isArray(payload[property].items)) {
          payload[property].items = await Promise.resolve(payload[property].items.sort((a: any, b: any) => a.label?.localeCompare(b?.label)));
        }
      }
      state.term = payload.term;
      state.paymentDate = payload.paymentDate;
      state.paymentFrequence = payload.paymentFrequence;
      state.baseCalcule = payload.baseCalcule;
      state.typeService = payload.typeService;
      state.demandStatus = payload.demandStatus;
      state.brand = payload.brand;
      state.range = payload.range;
      state.clientType = payload.clientType;
      state.current_status_code = payload.current_status_code;
      state.network = payload.network;
      state.networkNode = payload.networkNode;
      state.role = payload.role;
      state.garantyrole = payload.garantyrole;
      state.activitySector = payload.activitySector;
      state.legalCategory = payload.legalCategory;
      state.relations = payload.relations;
      state.countries = payload.countries;
      state.geographicZone = payload.geographicZone;
      state.cities = payload.cities;
      state.postalCodes = payload.postalCodes;
      state.statusMarital = payload.statusMarital;
      state.matrimonialRegime = payload.matrimonialRegime;
      state.addressType = payload.addressType;
      state.addressPreferenceType = payload.addressPreferenceType;
      state.titles = payload.titles;
      state.occupation = payload.occupation;
      state.periodRateOption = payload.periodRateOption;
      state.warrantMethod = payload.warrantMethod;
      state.contactType = payload.contactType;
      state.IdProof = payload.IdProof;
      state.facilityType = payload.facilityType;
      state.qualificationType = payload.qualificationType;
      state.relationType = payload.relationType;
      state.accountType = payload.accountType;
      state.accountStatus = payload.accountStatus;
      state.natureBalanceSheet = payload.natureBalanceSheet;
      state.roleStatus = payload.roleStatus;
      state.partyClass = payload.partyClass;
      state.nationality = payload.nationality;
      state.jobActivity = payload.jobActivity;
      state.jobPositionType = payload.jobPositionType;
      state.jobOccupationType = payload.jobOccupationType;
      state.nature = payload.nature;
      state.periodicity = payload.periodicity;
      state.partyRelationType = payload.partyRelationType;
      state.language = payload.language
      state.partyClassificationType = payload.partyClassificationType
      state.gender = payload.gender
      state.contractType = payload.contractType
      state.propertyStatus = payload.propertyStatus
      state.documentType = payload.documentType
      state.codeNaceOptions = payload.codeNaceOptions
      state.signatureMethods = payload.signatureMethods
      //Asset
      state.categoryAsset = payload.categoryAsset;
      state.usageAsset = payload.usageAsset;
      state.colors = payload.colors;
      state.activity = payload.activity;
      state.bodyAsset = payload.bodyAsset;
      state.brandAsset = payload.brandAsset;
      state.modelAsset = payload.modelAsset;
      state.finishingAsset = payload.finishingAsset;
      state.fuelTypeAsset = payload.fuelTypeAsset;
      state.emissionCO2Asset = payload.emissionCO2Asset;
      state.powerHPAsset = payload.powerHPAsset;
      state.assets = payload.assets;
      state.typeCredits = payload.typeCredits;
      state.organization = payload.organization;
      state.objects = payload.objects;

      state.channel = payload.channel;
      //Task
      state.actionType = payload.actionType;
      state.module = payload.module
      //decision
      state.decisionMotif = payload.decisionMotif;
      state.decisionreserve = payload.decisionreserve;
      state.decisionStatus = payload.decisionStatus;
      state.decisionOpinion = payload.decisionOpinion;
      // Accept Offer
      state.acceptDecisionStatus = payload.acceptDecisionStatus;

      state.financialProfile = payload.financialProfile;
      state.scaleStatus = payload.scaleStatus;
      state.scaleCategory = payload.scaleCategory;
      state.productTax = payload.productTax;
      state.productTaxServices = payload.productTaxServices;
      state.scalefrequency = payload.scalefrequency;
      state.scaleRateBasis = payload.scaleRateBasis;
      state.scaleGracePeriodCalculationMethod = payload.scaleGracePeriodCalculationMethod;
      state.serviceType = payload.serviceType;
      state.productBasis = payload.productBasis;
      state.accessoryCalculationMethod = payload.accessoryCalculationMethod;
      state.accessoryPaymentDatesOption = payload.accessoryPaymentDatesOption;
      state.assetTypeItems = payload.assetTypeItems;
      state.assetStatut = payload.assetStatut;
      state.assetCatalog = payload.assetCatalog;

      //Garanty
      state.garantyStatus = payload.garantyStatus;
      state.garantyType = payload.garantyType;
      state.typeOutstanding = payload.typeOutstanding;
      state.financialInstitution = payload.financialInstitution;
      state.underlyingType = payload.underlyingType
      state.garantyBasis = payload.garantyBasis;

      state.productAvailabilityContext = payload.productAvailabilityContext;

      // order
      state.orderStatus = payload.orderStatus;
      state.orderItemStatus = payload.orderItemStatus;
      state.orderTypeFinancing = payload.orderTypeFinancing;
      state.orderServiceType=payload.orderServiceType

      state.delegationLevels = payload.delegationLevels;

      state.justifyTypes = payload.justifyTypes;
      state.supportingDocumentType = payload.supportingDocumentType;
      state.roleOrder = payload.roleOrder;
      // User
      state.userSecurityGroup = payload.userSecurityGroup;
      state.userTeam = payload.userTeam;
      state.userNetwork = payload.userNetwork;
      state.userNetworkNode = payload.userNetworkNode;

    },
    setPickListFinancingProduct(state, payload) {
      state.financingProduct = payload;
    },
    setPickListNamesServicesValue(state, payload) {
      state.namesServices = payload;
    },
    setPickListPostalCodeValue(state, payload) {
      state.postalCodes = payload;
    },
    setPickListNationalityValue(state, payload) {
      state.nationality = payload;
    },
    setPickListJobOccupationValue(state, payload) {
      state.jobOccupationType = payload;
    },
    setPickListAssetsValue(state, payload) {
      state.assets = payload;
    },
    setReasons(state, payload) {
      state.reasons = {
        items: []
      }
      state.reasons = {
        items: payload.map((item: any) => {
          return {
            value: item.resourceUid,
            label: item.resourceUid
          };
        })
      }
    }
  },
};

export default picklistModule;
