import {RootState, SummaryPanelState} from "@/types";
import {Module} from "vuex";
import {getOffer} from "@/store/services/offerService";
import Notify from 'quasar/src/plugins/Notify.js';;
import {getOrderEditMode} from "@/store/services/order/orderService";
import {getSummaryParties} from "@/store/services/order/orderMapper";

const summaryPanelModule: Module<SummaryPanelState, RootState> = {
    namespaced: true,
    state: {},
    getters: {
        getOrderParties(state) {
            const {order} = state
            return order ? getSummaryParties(order) : []
        },
    },
    actions: {
        setOrder({commit}, payload) {
            const {orderId} = payload
            if (!orderId) {
                throw new Error('OrderId is required')
            }
            getOrderEditMode(orderId).then(order => {
                commit('setOrder', order)
                if (payload.callback) payload.callback(order)
            }).catch(err => {
                console.error(err)
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                    message: err.message,
                    color: 'negative'
                });
            })
        },
        setOfferFromTask({commit}, payload){
            const {offer} = payload
            commit('setOffer', offer)
            
        },
        setOffer({commit}, payload) {
            const offerId = payload.offer.resourceUid
            if (!offerId) {
                throw new Error('OfferId is required')
            }
            commit('setOffer', payload.offer)
        },
        cleanState({ commit }) {
            commit("setInitial");
            this.dispatch('controlsModule/cleanState')
        },
        gatheringProcessOrder: ({ commit }, payload) => {
            const { request } = payload;
            commit("setGatheringProcessOrder", request.gatheringProcess);
        },
    },
    mutations: {
        setProposalScale(state, payload) {
            state.proposalScale = payload
        },
        setOffer(state, payload) {
            state.offer = payload
        },
        setOrder(state, payload) {
            state.order = payload
        },
        setInitial(state) {
            delete state.offer
            delete state.order
        },
        setGatheringProcessOrder(state, payload) {
            state.gatheringProcessOrder = payload;
        },
    }
}

export default summaryPanelModule
