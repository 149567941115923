import { PackNoticeTemplateInfo } from './../../../../types/index';
import {Module} from "vuex";
import {NoticeTemplate, PrintOfferState, RootState} from "@/types";
import {
    download,
    generateDocument,
    getMail,
    getNoticeBody,
    getTemplates,
    sendMailWithAllDocument
} from "@/store/services/task/actions/printOfferService";
import Notify from 'quasar/src/plugins/Notify.js';;
import store from "@/store";
import {deepClone, deleteAttributesRecursively, mountNoticeMessage} from "@/utils";
import i18n from '@/i18n';
import { sendMessage } from '@/store/services/task/workflow';
import { errorTreatment } from '../../document/printDocumentModule';

const printOfferModule: Module<PrintOfferState, RootState> = {
    namespaced: true,
    state: {
        templates: [],
        emailSendList: [],
        body: '',
        showButtonsPreview: false,
        showButtonValidate: false
    },
    getters: {},
    actions: {
        sendMessage({ commit }, payload) {
            const { messageTosend, callback } = payload

            store.state.printDocumentModule!.isLoading = true;

            sendMessage(messageTosend)
                .then(async res => {
                    callback(res);
                })
                
                .catch(e => errorTreatment({ error: e, action: messageTosend.messageName }))
        },
        getTemplates({commit}) {
            getTemplates()
                .then(templates => commit('setTemplates', templates))
                .catch(err => {
                    console.error(err)
                    Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                        message: err.message || err.error || err,
                        color: 'negative'
                    })
                })
        },
        sendDocument({commit}, payload) {
            const {emailSendList,callback} = payload

            function getNoticeTemplates() {
                const filtered = deepClone(store.state.taskModule.printOfferModule.templates.filter((t: NoticeTemplate) => t.selected));
                deleteAttributesRecursively(filtered, ['documentOrder', 'qualification', 'numberOfCopies'])
                return filtered;
            }

        const noticeTemplates = getNoticeTemplates()
        sendMailWithAllDocument(noticeTemplates, emailSendList)
        .then(result =>{
            commit('checkDownload', true)
            commit('setShowSendTemplate', false)
            if ( callback ) {
                callback()
            }
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: i18n.global.t('task.printDocument.message'),
            color: 'positive'
          });
        })
        .catch(err => {
          console.error(err)
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: err.message || err.error || err,
            color: 'negative'
          })
        })
    },
    cleanState({ commit }) {
      commit("setInitial");
    },
    setPreviewTemplate({commit, dispatch}, payload) {
        const {template,callback} = payload
        if ( !template ) {
            throw new Error('Template is mandatory')
        }

        commit('setVisualizedTemplate', template)
        generateDocument(mountNoticeMessage(store.state.taskModule.offer, template))
            .then(data => {
                const contentPDF = atob(data.content)
                const arrPDF = new Uint8Array(contentPDF.length);
                for ( let i = 0; i < contentPDF.length; i++ ) {
                    arrPDF[i] = contentPDF.charCodeAt(i);
                }
                commit('setPreviewTemplate', arrPDF)
                if ( callback ) {
                    callback()
                }
            })
            .catch(err => {
                console.error(err)
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                    message: err.message || err.error || err,
                    color: 'negative'
                })
            })
    },
    getEmailSendListOption({ commit }, payload) {

      const {offer} = payload
      if (!offer) {
        throw new Error('Offer is mandatory')
      }
      getMail(offer).then(response =>{
        commit('setEmailSendListOption', response)
      }).catch(err => {
        console.error(err)
        Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
          message: err.message || err.error || err,
          color: 'negative'
        })
  })
    },

    getNoticeTemplateBody({ commit }){
       getNoticeBody().then(response=>{
        commit('setTemplateBody', response)

       }).catch(err => {
        console.error(err)})
    },

    downloadTemplate({commit, dispatch}, payload) {
        const {template,callback} = payload
        if ( !template ) {
            throw new Error('Template is mandatory')
        }
        const templates = this.state.taskModule.printOfferModule.templates.filter((t:any) => t.selected)[0]
        download(deepClone(templates)).then(data => {
            commit('checkDownload', true)
            if ( callback ) {
                callback()
            }
        })
            .catch(err => {
                    console.error(err)
                    Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                        message: err.message || err.error || err,
                        color: 'negative'
                    })
                })
        },
    sendTemplate({commit, dispatch}, payload) {
        const {template} = payload
        if (!template) {
            throw new Error('Template is mandatory')
        }
        if(!template.isPack || (template.isPack && !template.selected)){
            dispatch('setTemplateSelected', {template, selected: true})
        }
        commit('setShowSendTemplate', true)
    },
    setAllTemplateSelected({dispatch}, payload) {
        const {selected} = payload
        const {templates} = this.state.taskModule.printOfferModule
        templates.forEach(template => dispatch('setTemplateSelected', {template, selected}))
        dispatch('setSelectedTemplates')
    },
    setTemplateSelected({commit, dispatch}, payload) {
        const {template} = payload
        if (!template) {
            throw new Error('Template is mandatory')
        }
        commit('setTemplateSelected', payload)
        dispatch('setSelectedTemplates')
    },
    setTemplateSelectedItem({commit, dispatch}, payload) {
        const {template} = payload
        if (!template) {
            throw new Error('Template is mandatory')
        }
        commit('setTemplateSelectedItem', payload)
        dispatch('setSelectedTemplates')
    },
    changeShowSendTemplate({commit}, payload) {
        commit('setShowSendTemplate', payload)
    },
    addEmailSenderList({commit}, payload) {
        const {email} = payload
        if (!email) {
            throw new Error('Email is mandatory')
        }
        commit('addEmailSenderList', email)
    },
    removeEmailSenderList({commit}, payload) {
        const {email} = payload
        if (!email) {
            throw new Error('Email is mandatory')
        }
        commit('removeEmailSenderList', email)
    },
    setSelectedTemplates({commit}) {
        let payload = true
        this.state.taskModule.printOfferModule.templates.forEach((t:NoticeTemplate) => {
            if(t.selected){
                payload = false
            }else if(t.isPack){
                t.items?.forEach((p: PackNoticeTemplateInfo) => {
                    if(p.selected){
                        payload = false
                    }
                })
            }
        })
        commit('checkSelectedTemplates', payload)
    }
},
    mutations: {
        setInitial(state) {
            state.templates = []
            state.emailSendList = []
            state.body =  ''
            state.showButtonsPreview = false
            state.showButtonValidate= false
            delete state.previewTemplate
            delete state.visualizedTemplate
            delete state.emailSendListOption
        },
        setTemplates(state, payload) {
            state.templates = payload
        },
        setVisualizedTemplate(state, payload) {
            state.visualizedTemplate = payload
        },
        setTemplateSelected(state, payload) {
            const {template, selected} = payload
            template.selected = selected
            if (template.isPack) {
                for (const item of template.items) {
                    item.selected = selected
                }
            }
        },
        setTemplateSelectedItem(state, payload) {
            const {template, selected, parentNotice} = payload
            template.selected = selected
            if (template.selected) {
                parentNotice.selected = true
            } else {
                for (const item of parentNotice.items) {
                    if (item.selected) {
                        parentNotice.selected = true
                        break
                    }
                    parentNotice.selected = false
                }
            }
        },
        setPreviewTemplate(state, payload) {
            state.previewTemplate = {
                arr: payload,
                page: 1
            }
        },
        setEmailSendListOption(state, payload) {
            state.emailSendListOption = payload
        },
        setShowSendTemplate(state, payload) {
            state.showSendTemplate = payload
        },
        setTemplateBody(state, payload) {
            state.body = payload
        },
        addEmailSenderList(state, payload) {
            if (state.emailSendList.indexOf(payload) === -1) {
                state.emailSendList.push(payload)
            }
        },
        removeEmailSenderList(state, payload) {
            state.emailSendList = state.emailSendList.filter(email => email !== payload)
        },
        checkSelectedTemplates(state, payload) {
            state.showButtonsPreview = payload
        },
        checkDownload(state, payload) {
            state.showButtonValidate = payload
        },

    },
};

export default printOfferModule;
