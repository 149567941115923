import {
    AccessoryProductInfo,
    FinancingOfferItem,
    FinancingProductPackInfo,
    MoneyAmount,
    Offer,
    ProductSelectionParameters,
    ProductSummary,
    Proposal,
    ProposalAccessories,
    RootState,
    SimpleQuote,
    SimulationServiceItem,
} from "@/types";
import { buildProposalAssets, extractNormalModeValue } from "@/store/services/simulation";
import {
    deepClone,
    deleteAttributesRecursively,
    globalConfig,
    mapResourceUid,
    Masks,
    removeAttributesRecursively
} from "@/utils";
import moment from "moment";
import { verifyPartyInsured } from "@/store/services/financing/financingService";

export const INVESTMENT_BASIS_TAX_EXCLUSIVE = "taxExclusive"

export async function getProposalInfo(state: RootState, isToOrchestration?: boolean | false): Promise<Proposal[]> {
    const { financingModule } = state
    const { scaleSelected } = financingModule.scalesModule
    if (state.demandModule.isEdit) {
        return buildProposalInfoEditMode(state, isToOrchestration)
    } else {
        // const helper = scaleSelected ? await getHelperFromScale(state, scaleSelected) : await
        return buildProposalInfoFromSate(state, scaleSelected, isToOrchestration)
    }
}

export async function getProductSelectionParametersRequest(stateParam: RootState): Promise<ProductSelectionParameters> {
    const state = deepClone(stateParam)
    const { demandModule } = state
    const { offer } = demandModule
    const proposalList: Proposal[] = await getProposalInfoScale(state)
    const proposal = proposalList[0]
    const assetList = proposal.proposalItems
        .map(item => item.proposalAssets)
        .reduce((a, b) => {
            a.push(deepClone(b));
            return a
        }, [])
    const accessoryProductInfo: AccessoryProductInfo[] = []
    proposal.proposalItems
        .map(item => item.proposalAccessories.forEach(accessories => {
            accessoryProductInfo.push({
                currency: 'EUR',
                quantity: accessories.quantity,
                calculationMethodOption: accessories.proposalAccessoryCalculationMethodOption?.resourceUid,
                paymentDatesOption: accessories.proposalAccessoryPaymentDatesOption?.resourceUid,
                basis: String(accessories.basisValue),
                amount: accessories.amountWoTax,
                annualRate: accessories.rate,
            })
        }))

    const partDirig: any = []
    let partyInfo: any = []
    if (offer && offer.associatedParties) {
        partyInfo = offer.associatedParties
            .sort((offerAssociatedParty: any) => offerAssociatedParty.associatedParty.role_code === 'CUSTOMER' ? -1 : 0)
            .map((offerAssociatedParty: any) => {
                const { role_code } = offerAssociatedParty
                const { third } = offerAssociatedParty.associatedParty
                const type = third.type.id
                const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
                removeAttributesRecursively(body)
                deleteAttributesRecursively(body, ['isDisabled'])
                body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], partyInfo)
                if (!type.includes("Organization")) {
                    body.birthDate = body.birthDate || moment().format(Masks.dateMask)
                } else if (body.manager != undefined) {
                    partDirig.push(body.manager)
                }
                body.flagInsured = verifyPartyInsured(offerAssociatedParty)
                return {
                    partyData: JSON.stringify({
                        type,
                        role: role_code || 'CLIENT',
                        ...body
                    })
                }
            })


        if (partDirig.length > 0) {
            partyInfo.push({
                partyData: JSON.stringify({
                    type: 'party-FrenchPerson',
                    role: partDirig[0].roles[0].role.resourceUid,
                    flagInsured: true,
                    ...partDirig[0]
                })
            })
        }
    }
    const assetInfo = assetList.map(asset => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })
    removeAttributesRecursively(proposal)

    proposal.proposalItems.forEach((el: any) => {
        for (const key in el) {
            if (el[key] === undefined) {
                el[key] = null
            }
        }
        el.contractDuration = getContractDuration(el)
        el.ageEndOfContract = getAgeEndOfContract(el, partyInfo)
        el.financedValue = getFinancedValue(el, state)
    })
    const quoteInfo = JSON.stringify(proposal)
    return {
        applicationName: 'ORIGINATION',
        context: '',
        maxResults: 100,
        partyInfo,
        assetInfo,
        accessoryProductInfo,
        quoteInfo,
    }
}
export async function getProductSelectionEditMode(stateParam: RootState,offer:Offer): Promise<ProductSelectionParameters> {
    const state = deepClone(stateParam)
    const proposalList: Proposal[] = await getProposalInfoScale(state)
    const proposal = proposalList[0]
    const assetList = proposal.proposalItems
        .map(item => item.proposalAssets)
        .reduce((a, b) => {
            a.push(deepClone(b));
            return a
        }, [])
    const accessoryProductInfo: AccessoryProductInfo[] = []
    proposal.proposalItems
        .map(item => item.proposalAccessories.forEach(accessories => {
            accessoryProductInfo.push({
                currency: 'EUR',
                quantity: accessories.quantity,
                calculationMethodOption: accessories.proposalAccessoryCalculationMethodOption?.resourceUid,
                paymentDatesOption: accessories.proposalAccessoryPaymentDatesOption?.resourceUid,
                basis: String(accessories.basisValue),
                amount: accessories.amountWoTax,
                annualRate: accessories.rate,
            })
        }))

    const partDirig: any = []
    let partyInfo: any = []
    if (offer && offer.associatedParties) {
        partyInfo = offer.associatedParties
            .sort((offerAssociatedParty: any) => offerAssociatedParty.associatedParty.role_code === 'CUSTOMER' ? -1 : 0)
            .map((offerAssociatedParty: any) => {
                const { role_code } = offerAssociatedParty
                const { third } = offerAssociatedParty.associatedParty
                const type = third.type.id
                const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
                removeAttributesRecursively(body)
                deleteAttributesRecursively(body, ['isDisabled'])
                body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], partyInfo)
                if (!type.includes("Organization")) {
                    body.birthDate = body.birthDate || moment().format(Masks.dateMask)
                } else if (body.manager != undefined) {
                    partDirig.push(body.manager)
                }
                body.flagInsured = verifyPartyInsured(offerAssociatedParty)
                return {
                    partyData: JSON.stringify({
                        type,
                        role: role_code || 'CLIENT',
                        ...body
                    })
                }
            })


        if (partDirig.length > 0) {
            partyInfo.push({
                partyData: JSON.stringify({
                    type: 'party-FrenchPerson',
                    role: partDirig[0].roles[0].role.resourceUid,
                    flagInsured: true,
                    ...partDirig[0]
                })
            })
        }
    }
    const assetInfo = assetList.map(asset => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })
    removeAttributesRecursively(proposal)

    proposal.proposalItems.forEach((el: any) => {
        for (const key in el) {
            if (el[key] === undefined) {
                el[key] = null
            }
        }
        el.contractDuration = getContractDuration(el)
        el.ageEndOfContract = getAgeEndOfContract(el, partyInfo)
        el.financedValue = getFinancedValue(el, state)
    })
    const quoteInfo = JSON.stringify(proposal)
    return {
        applicationName: 'ORIGINATION',
        context: '',
        maxResults: 100,
        partyInfo,
        assetInfo,
        accessoryProductInfo,
        quoteInfo,
    }
}

// export async function getProductSelectionEditMode(offer: Offer): Promise<ProductSelectionParameters> {
//     //TODO TP-3076
//     const proposal = offer.proposals[0]
//     const proposalItem = proposal.proposalItems[0]
//     const accessoryProductInfo = proposalItem.proposalAccessories
//         .map((item: any) => {
//             const {
//                 amountWoTax,
//                 taxValue,
//                 basisValue,
//                 quantity,
//                 proposalAccessoryCalculationMethodOption,
//                 proposalAccessoryPaymentDatesOption
//             } = item
//             return {
//                 quantity,
//                 annualRate: taxValue,
//                 calculationMethodOption: proposalAccessoryCalculationMethodOption?.resourceUid,
//                 basis: basisValue,
//                 currency: amountWoTax.currency,
//                 paymentDatesOption: proposalAccessoryPaymentDatesOption?.resourceUid,
//                 amount: amountWoTax,
//             }
//         })
//
//     const assetList = proposal.proposalItems
//         .map(item => item.proposalAssets)
//         .reduce((a, b) => {
//             a.push(deepClone(b));
//             return a
//         }, [])
//
//     const partyInfo = (offer.associatedParties || []).map((offerAssociatedParty: any) => {
//         const { role_code } = offerAssociatedParty
//         const { third } = offerAssociatedParty.associatedParty
//         const type = third.type.id
//         const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
//         removeAttributesRecursively(body)
//         deleteAttributesRecursively(body, ['isDisabled'])
//         return {
//             partyData: JSON.stringify({
//                 type,
//                 role: role_code || 'CLIENT',
//                 ...body
//             })
//         }
//     })
//
//     const assetInfo = assetList.map(asset => {
//         removeAttributesRecursively(asset)
//         return {
//             assetData: JSON.stringify(asset)
//         }
//     })
//     removeAttributesRecursively(proposal)
//     const quoteInfo = JSON.stringify(proposal)
//     return {
//         applicationName: 'ORIGINATION',
//         context: '',
//         maxResults: 100,
//         partyInfo,
//         assetInfo,
//         accessoryProductInfo,
//         quoteInfo,
//     }
// }

function getPartyAge(partyInfo: any) {
    if (!partyInfo || !partyInfo.length) {
        return 0
    }
    const partyData = JSON.parse(partyInfo[0].partyData)
    return partyData.age || 0
}

function getContractDuration(proposalItem: any) {
    return proposalItem.numberOfPaymentTerm ? (proposalItem.numberOfPaymentTerm * proposalItem.periodBetween2Installments?.duration) : 0
}

function getAgeEndOfContract(proposalItem: any, partyInfo: any) {
    const contractDuration = getContractDuration(proposalItem)
    const partyAge = getPartyAge(partyInfo)
    return partyAge && contractDuration ? (partyAge + (contractDuration / 12)) : 0
}

function getFinancedValue(proposalItem: any, state: any) {
    const price = getPrice(state)
    const { downPayment } = proposalItem
    return downPayment ? price - downPayment : price
}

export async function getProposalInfoScale(state: RootState): Promise<Proposal[]> {
    const { financingModule } = state
    const scaleSelected  = financingModule?.scalesModule?.scaleSelected
    const helper = scaleSelected ? await getHelperFromScale(state, scaleSelected) : await buildProposalInfoFromSate(state)
    return buildProposalFromSelectedScale(state, helper)
}

export async function buildProposalInfoFromScale(state: RootState, scale: FinancingProductPackInfo): Promise<Proposal[]> {
    const helper = await getHelperFromScale(state, scale)
    return buildProposalFromSelectedScale(state, helper)
}

export async function buildProposalInfoFromSate(state: RootState, scaleSelected?: any, isToOrchestration?: boolean | false): Promise<Proposal[]> {
    const helper: any = await getHelperFromState(state)
    helper.scaleSelected = scaleSelected
    return buildProposalFromSelectedScale(state, helper, false, isToOrchestration)
}

export async function buildProposalInfoEditMode(state: RootState, isToOrchestration?: boolean | false): Promise<Proposal[]> {
    const helper: any = await getHelperFromState(state)
    helper.scaleSelected = state.financingModule.scalesModule.scaleSelected
    const proposal = getProposalSelected(state.demandModule.offer?.proposals || [])
    const result = await buildProposalFromSelectedScale(state, helper, true, isToOrchestration)
    const resultItem = result[0]
    resultItem.resourceUid = proposal.resourceUid
    resultItem.proposalItems[0].resourceUid = proposal.proposalItems[0].resourceUid
    return result
}

export function getAccessories(scale: FinancingProductPackInfo | any, servicePartyInsurance: any): SimulationServiceItem[] {
    const insuranceList = servicePartyInsurance
        .filter((o: any) => o.beneficiaries && o.beneficiaries.length)
        .map((o: any) => {
            const copy = deepClone(o)
            copy.beneficiaries = copy.beneficiaries.filter((c: any) => c._controls?.selected)
            deleteAttributesRecursively(copy, ['_controls'])
            copy.beneficiaries.forEach((c: any) => deleteAttributesRecursively(c, ['_controls']))
            delete copy.qualification
            return copy
        })

    const servicePartyInsuranceProductUidList = servicePartyInsurance.map((item: any) => item.productUid)
    const servicesSelectedRef = scale.scalePresentation
        .services?.filter((service: any) => service.config.selected).map((service: any) => service.reference)
    return [...scale.items
        .filter((s: any) => {
            if (!(s.amount?.amount || s.annualRate)) {
                return false
            }
            const hasPartyInsurance = !servicePartyInsuranceProductUidList.includes(s.resourceUid)
            if (servicesSelectedRef) {
                return servicesSelectedRef.includes(s.resourceUid) && hasPartyInsurance
            }
            return hasPartyInsurance
        })
        .map((s: any) => {
            const odmSimulationObjectTypeBasePath = 'odm.simulation.simulationaccessoryitem'
            let objectType
            switch (s.objectType) {
                case "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.insuranceproductitem.assetinsuranceproductitem":
                    objectType = `${odmSimulationObjectTypeBasePath}.simulationinsuranceitem.simulationassetinsuranceitem`
                    break
                case "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.maintenanceproductitem":
                    objectType = `${odmSimulationObjectTypeBasePath}.simulationmaintenanceitem`
                    break
                case "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.simpleserviceproductitem":
                    objectType = `${odmSimulationObjectTypeBasePath}.simulationsimpleserviceitem`
                    break
                case "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.insuranceproductitem.partyinsuranceproductitem":
                    objectType = `${odmSimulationObjectTypeBasePath}.simulationinsuranceitem.simulationpartyinsuranceitem`
                    break
                case "odm.product.financingproductpackitem.accessoryproductitem.originationcommissionproductitem":
                    objectType = `${odmSimulationObjectTypeBasePath}.simulationcommissionitem`
                    break
                default:
                    return
            }
            const amount = s.amount || { amount: 0, currency: "EUR" }
            return {
                objectType,
                productUid: s.productUid || s.resourceUid,
                currency: amount.currency,
                amount,
                flagIncludingAPRC: s.flagIncludingAPRC,
                quantity: 1,
                paymentDatesOption: s.paymentDatesOption,
                calculationMethodOption: s.calculationMethodOption,
                annualRate: s.annualRate,
                basis: s.basis,
                basisValue: s.basisValue,
                tax: s.tax,
                taxValue: s.taxValue
            }
        })
        .filter((o: any) => !!o),
    ...insuranceList
    ]
}

export async function getHelperFromScale(state: RootState, scaleSelected: FinancingProductPackInfo) {
    const { financingModule } = state
    const { services } = financingModule.serviceModule
    const price = getPriceScale(state, scaleSelected)
    const priceWoTax = financingModule.proposalAssetItemsTotalWoTax || 0

    let payment = 0
    let firstPaymentPercentage = 0
    let firstPayment = 0
    let duration = 0
    let residualValue = 0
    let millage = 0
    let spread = 0
    let nominalRate = 0
    let downPayment = 0
    let downPaymentPercentage = 0
    let financingOfferResourceUid = ''
    let residualValuePercentage = 0
    const rateBaseValue = scaleSelected.scalePresentation?.rateFinancingTax || 0
    let gracePeriodTerms = 0
    if (scaleSelected.items) {
        const scaleItem: FinancingOfferItem = scaleSelected.items.filter((s: any) => s.objectType === "odm.product.financingproductpackitem.financingofferitem")[0]
        financingOfferResourceUid = scaleItem.resourceUid || ''
        payment = scaleItem.payment?.amount || payment
        duration = scaleItem.numberOfPaymentTerms || duration
        spread = Number((scaleItem.annualRate || 0).toFixed(5))
        nominalRate = spread + rateBaseValue
        firstPaymentPercentage = scaleItem.firstPayment?.amount || firstPaymentPercentage
        firstPayment = price * firstPaymentPercentage / 100
        downPaymentPercentage = scaleItem.downPayment?.amount || 0
        downPayment = price * downPaymentPercentage / 100
        gracePeriodTerms = scaleItem.defaultNumberGracePeriodTerms || gracePeriodTerms

        const residualValueSlider = scaleSelected.scalePresentation.sliders.filter((s: any) => s.attribute === 'residualValue')[0]
        if (residualValueSlider) {
            residualValuePercentage = residualValueSlider.value
            residualValue = residualValuePercentage ? residualValuePercentage * price / 100 : 0
        }

        const mileageSlider = scaleSelected.scalePresentation.sliders.filter((s: any) => s.attribute === 'mileage')[0]
        if (mileageSlider) {
            millage = mileageSlider.value || millage
        }

    }

    return {
        financingOfferResourceUid,
        price,
        payment,
        firstPaymentPercentage,
        firstPayment,
        duration,
        residualValue,
        residualValuePercentage,
        millage,
        downPayment,
        downPaymentPercentage,
        services,
        scaleSelected,
        priceWoTax,
        rateBaseValue,
        spread,
        nominalRate,
        gracePeriodTerms
    }
}

export async function getHelperFromState(state: RootState) {
    const { simulationModule, financingModule } = state
    const services  = financingModule?.serviceModule?.services
    const scaleSelected = financingModule?.scalesModule?.scaleSelected
    let gracePeriodCalculationMethodOption: any = "";
    if (scaleSelected?.items) {
        const scaleItem: FinancingOfferItem = scaleSelected.items.filter((s: any) => s.objectType === "odm.product.financingproductpackitem.financingofferitem")[0];
        gracePeriodCalculationMethodOption = scaleItem.gracePeriodCalculationMethodOption;
    }

    const rateBaseValue = scaleSelected?.scalePresentation?.rateFinancingTax || 0
    const normalMode = simulationModule?.normalModeState
    const price = getPrice(state)
    const priceWoTax = financingModule?.proposalAssetItemsTotalWoTax || 0
    const payment: number = (extractNormalModeValue(normalMode, 'payment') || { value: 0 }).value || 0
    const duration: number = (extractNormalModeValue(normalMode, 'duration') || { value: 0 }).value || 0
    const gracePeriodTerms: number = (extractNormalModeValue(normalMode, 'gracePeriodTerms') || { value: 0 }).value || 0
    const mileage: number = (extractNormalModeValue(normalMode, 'mileage') || { value: 0 }).value || 0
    const spread = Number((extractNormalModeValue(normalMode, 'rate')?.percentValue || 0).toFixed(5))
    const nominalRate = spread + rateBaseValue
    const { downPayment, downPaymentPercentage } = buildDownPayment(state, price)
    const { firstPayment, firstPaymentPercentage } = buildFirstPayment(state, price)
    const { residualValue, residualValuePercentage } = buildResidualValue(state, price)
    return {
        price,
        priceWoTax,
        payment,
        firstPayment,
        firstPaymentPercentage,
        duration,
        residualValue,
        residualValuePercentage,
        mileage,
        downPayment,
        downPaymentPercentage,
        services,
        rateBaseValue,
        spread,
        nominalRate,
        gracePeriodTerms,
        gracePeriodCalculationMethodOption
    }
}

function buildDownPayment(state: RootState, price: number) {
    const { simulationModule } = state
    const normalMode = simulationModule?.normalModeState
    let downPayment = 0
    let downPaymentPercentage = 0
    const downPaymentNormalMode = extractNormalModeValue(normalMode, 'downPayment')
    if (price && downPaymentNormalMode) {
        const isPercentage = downPaymentNormalMode.type === 'PERCENT'
        if (isPercentage) {
            downPaymentPercentage = downPaymentNormalMode.percentValue || 0
            downPayment = ((price * downPaymentPercentage) / 100)
        } else {
            downPayment = downPaymentNormalMode.value || 0
            downPaymentPercentage = ((downPayment / price) * 100)
        }
    }
    return {
        downPayment, downPaymentPercentage
    }
}

function buildFirstPayment(state: RootState, price: number) {
    const { simulationModule } = state
    const normalMode = simulationModule?.normalModeState
    let firstPayment = 0
    let firstPaymentPercentage = 0
    const firstPaymentNormalMode = extractNormalModeValue(normalMode, 'firstPayment')
    if (price && firstPaymentNormalMode) {
        const isPercentage = firstPaymentNormalMode.type === 'PERCENT'
        if (isPercentage) {
            firstPaymentPercentage = firstPaymentNormalMode.percentValue || 0
            firstPayment = ((price * firstPaymentPercentage) / 100)
        } else {
            firstPayment = firstPaymentNormalMode.value || 0
            firstPaymentPercentage = ((firstPayment / price) * 100)
        }
    }
    return {
        firstPayment, firstPaymentPercentage
    }
}

function buildResidualValue(state: RootState, price: number) {
    const { simulationModule } = state
    const normalMode = simulationModule?.normalModeState
    let residualValue = 0
    let residualValuePercentage = 0
    const residualValueNormalMode = extractNormalModeValue(normalMode, 'residualValue')
    if (price && residualValueNormalMode) {
        const isPercentage = residualValueNormalMode.type === 'PERCENT'
        if (isPercentage) {
            residualValuePercentage = residualValueNormalMode.percentValue || 0
            residualValue = ((price * residualValuePercentage) / 100)
        } else {
            residualValue = residualValueNormalMode.value || 0
            residualValuePercentage = ((residualValue / price) * 100)
        }
    }
    return {
        residualValue, residualValuePercentage
    }
}

export function getPrice(state: RootState) {
    const { financingModule } = state
    const scalesModule = financingModule?.scalesModule
    const scaleSelected = scalesModule?.scaleSelected
    return getPriceScale(state, scaleSelected)
}

export function getPriceScale(state: RootState, scale?: FinancingProductPackInfo) {

    const { financingModule } = state
    if (financingModule) {
        return (scale?.investmentBasis === INVESTMENT_BASIS_TAX_EXCLUSIVE
            ? financingModule.proposalAssetItemsTotalWoTax
            : financingModule.proposalAssetTotalWithTax) || 0
    }
    else {
        return 0
    }
   
}

function buildNormalModeProposalItemOrSelectedScale(state: RootState, helper: any): any {
    const { simulationModule } = state
    const { isFinancingTypeTTC } = simulationModule
    const scaleSelected: FinancingProductPackInfo = helper.scaleSelected
    helper.financedValue = helper.price
    let financingOfferResourceUid = helper.financingOfferResourceUid
    if (scaleSelected) {
        const financingOfferItem = scaleSelected.items.filter((s: any) => s.objectType === 'odm.product.financingproductpackitem.financingofferitem')[0]
        financingOfferResourceUid = financingOfferResourceUid || financingOfferItem.resourceUid
        if (financingOfferItem) {
            helper.rateType = financingOfferItem.typeRate
            helper.periodBetween2Installments = scaleSelected.periodBetween2Installments || financingOfferItem.periodBetween2Installments
            helper.rateBasis = { resourceUid: financingOfferItem.basisRate }
        }
        helper.periodRateOption = { resourceUid: scaleSelected.periodRateOption }
        helper.paymentDatesOption = { resourceUid: scaleSelected.paymentDatesOption }
        helper.paymentTermsOption = { resourceUid: scaleSelected.paymentTermsOption }
        helper.financingProductPack = {
            resourceUid: scaleSelected.resourceUid,
            objectType: "odm.product.productfeature.product.productpack.financingproductpack",
            systemUid: "odm-product",
        }
        helper.aprc = scaleSelected.calculationResult?.aprc
        helper.air = scaleSelected.calculationResult?.air
        helper.financingProduct = `${scaleSelected.category}.${scaleSelected.categoryClass}`
        helper.activityType = scaleSelected.activityType
        helper.sumOfTotalPayments = scaleSelected.calculationResult?.sumOfTotalPayments
    } else {
        helper.periodBetween2Installments = {
            duration: 1,
            unit: 'MONTHS',
        }
        helper.rateType = ''
        helper.rateBaseValue = ''
        helper.rateBasis = { resourceUid: 'CostOfFunds' }
        helper.periodRateOption = { resourceUid: 'NominalPeriodRateMethod' }
        helper.paymentDatesOption = { resourceUid: 'FixedDate' }
        helper.paymentTermsOption = { resourceUid: 'PayAdvanceIntAdvance' }
    }

    const { price, priceWoTax } = helper
    if (helper.firstPaymentPercentage) {
        helper.firstPaymentWTax = Number((price * helper.firstPaymentPercentage / 100).toFixed(2))
        helper.firstPaymentWoTax = Number((priceWoTax * helper.firstPaymentPercentage / 100).toFixed(2))
    }

    helper.financialPaymentWTax = Number((helper.payment || 0).toFixed(2))
    helper.financialPaymentWoTax = Number((helper.payment || 0).toFixed(2))

    helper.financingOffer = {
        resourceUid: financingOfferResourceUid,
        objectType: 'odm.product.financingproductpackitem.financingofferitem',
        systemUid: 'odm-product'
    }
    return buildSimpleQuote(helper)
}

export async function buildSimpleQuote(helper: any): Promise<SimpleQuote> {
    const {
        periodBetween2Installments,
        nominalRate,
        financedValue,
        firstPaymentWoTax,
        firstPaymentWTax,
        financialPaymentWoTax,
        financialPaymentWTax,
        rateType,
        rateBaseValue,
        rateBasis,
        periodRateOption,
        paymentDatesOption,
        paymentTermsOption,
        financingProductPack,
        firstPaymentPercentage,
        aprc,
        air,
        financingProduct,
        financingOffer,
        residualValue,
        residualValuePercentage,
        activityType,
        sumOfTotalPayments,
        mileage,
        gracePeriodCalculationMethodOption
    } = helper
    return {
        financedValue,
        objectType: "odm.offer.proposalitem.contractorigination.simplequote",
        daaq: '/',
        startDate: globalConfig.formatters.formatDateService(new Date(), '-'),
        endDate: globalConfig.formatters.formatDateServiceAddDays(30, new Date(), '-'),
        proposalAccessories: [],
        proposalAssets: [],
        proposalGuarantees: [],
        numberOfPaymentTerm: helper.duration,
        periodBetween2Installments,
        nominalRate,
        financingProductPack,
        spread: helper.spread,
        ratePeriodicity: periodBetween2Installments,
        gracePeriodDuration: helper.gracePeriodTerms,
        gracePeriodPeriodicity: periodBetween2Installments,
        gracePeriodRate: nominalRate,
        //gracePeriodCalculationMethodOption, TODO check it because this attribute is getting error on the back to save a offer
        firstPaymentWoTax,
        firstPaymentWTax,
        firstPaymentPercentage,
        //residualValueAmount: residualValue,
        residualValueAmountWoTax: residualValue,
        residualValueAmountWTax: residualValue,
        residualValuePercentage,
        financialPaymentWoTax,
        financialPaymentWTax,
        rateType,
        rateBaseValue,
        downPayment: helper.downPayment,
        downPaymentPercentage: helper.downPaymentPercentage,
        rateBasis,
        periodRateOption,
        paymentDatesOption,
        paymentTermsOption,
        aprc,
        air,
        financingProduct,
        financingOffer,
        activityType,
        sumOfTotalPayments,
        mileage
    }
}

export const mapProposalAccessories = (proposalAccessoriesPackItem: any): AccessoryProductInfo => {
    const currency = proposalAccessoriesPackItem.amountWTax.currency || 'EUR'
    return {
        currency,
        quantity: proposalAccessoriesPackItem.quantity,
        calculationMethodOption: proposalAccessoriesPackItem.proposalAccessoryCalculationMethodOption.resourceUid,
        paymentDatesOption: proposalAccessoriesPackItem.proposalAccessoryPaymentDatesOption.resourceUid,
        basis: proposalAccessoriesPackItem.basis,
        amount: proposalAccessoriesPackItem.amountWoTax,
        annualRate: proposalAccessoriesPackItem.rate
    }
}


export async function buildProposalFromSelectedScale(state: RootState, helper: any, isEditMode?: boolean, isToOrchestration?: boolean | false): Promise<Proposal[]> {
    const result: Proposal[] = []
    const { demandModule, financingModule } = state
    const { assetState } = demandModule
    const proposalAssets = await buildProposalAssets(assetState, isToOrchestration)
    const { scaleSelected } = financingModule.scalesModule
    const proposalAccessories = await buildProposalAccessories(financingModule.scalesModule.scaleSelected)
    const proposal: Proposal = {
        status: {
            objectType: 'odm.offer.proposalstatus',
            resourceUid: 'INITIAL'
        },
        proposalItems: [],
        flagSelected: true, // TODO change when multiple tabs
        daaq: '/'
    }
    if (isEditMode) {
        proposal.resourceUid = scaleSelected?.scalePresentation.proposalResourceUid
        helper.financingOfferResourceUid = scaleSelected?.scalePresentation.financingOfferResourceUid
    }
    const proposalItem: any = await buildNormalModeProposalItemOrSelectedScale(state, helper)

    if (isEditMode) {
        proposalItem.resourceUid = scaleSelected?.scalePresentation.proposalItemResourceUid
    }
    proposalItem.proposalAssets = proposalAssets
    proposalItem.proposalAccessories = proposalAccessories

    if (isToOrchestration && !isEditMode) {
        if (state.demandModule.partyTaxRate) {
            proposalItem.financialPaymentWTax = proposalItem.financialPaymentWoTax + ((state.demandModule.partyTaxRate / 100) * proposalItem.financialPaymentWoTax)
        }
    }

    proposal.proposalItems = [proposalItem]
    result.push(proposal)
    return result;
}

async function buildProposalAccessories(scale?: FinancingProductPackInfo): Promise<ProposalAccessories[]> {
    let returnValue: ProposalAccessories[] = [];
    if(scale?.scalePresentation.services){
        scale?.scalePresentation.services.forEach((service: any) => {
            let result: any;
            if (service.config.selected) {
                const rate = service.rate * 100
                const currency = 'EUR'
                let amount = service.amountValueWTax
                const amountWTax: MoneyAmount = {amount, currency}
                amount = service.amountValueWoTax
                const amountWoTax: MoneyAmount = {amount, currency}
                result = {
                    resourceUid: service.resourceUid,
                    objectType: service.objectType,
                    accessoryProduct: {
                        resourceUid: service.reference,
                        objectType: service.objectType,
                        systemUid: service.systemUid
                    },
                    proposalAccessoryCalculationMethodOption: {
                        resourceUid: service.calculationMethodOption || '',
                        objectType: 'odm.offer.proposalaccessorycalculationmethodoption'
                    },
                    proposalAccessoryPaymentDatesOption: {
                        resourceUid: service.paymentDatesOption || '',
                        objectType: 'odm.offer.proposalaccessorypaymentdatesoption'
                    },
                    amountWTax,
                    amountWoTax,
                    rate,
                    basis: service.basis,
                    servicePaymentSchedules: [],
                    label: service.reference
                }

                switch (service.objectType) {
                    case 'odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.insuranceproductitem.assetinsuranceproductitem':
                        result.objectType = 'odm.offer.proposalaccessories.proposalinsurance.proposalassetinsurance'
                        break
                    case 'odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.insuranceproductitem.partyinsuranceproductitem':
                        result.objectType = 'odm.offer.proposalaccessories.proposalinsurance.proposalpartyinsurance'
                        break
                    case 'odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.maintenanceproductitem':
                        result.objectType = 'odm.offer.proposalaccessories.proposalmaintenance'
                        break
                    case 'odm.product.financingproductpackitem.accessoryproductitem.originationcommissionproductitem':
                        result.objectType = 'odm.offer.proposalaccessories.proposalcommission'
                        break
                    case 'odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.simpleserviceproductitem':
                        result.objectType = 'odm.offer.proposalaccessories.proposalsimpleservice'
                        result.quantity = 1
                        break
                }
                returnValue.push(result)
            }
        })
    }

    if(scale?.scalePresentation.servicePartyInsurance && scale?.scalePresentation.servicePartyInsurance.length > 0){
        scale?.scalePresentation.servicePartyInsurance.forEach((service: any) => {
            if(service._controls.selected && service.beneficiaries && service.beneficiaries.length > 0){
                let result: any;
                const associatedInsuranceBeneficiaries: any = [];
                const [firstName, lastName] = service.beneficiaries[0].partyName.split(' ');
                const rate = service.beneficiaries[0].rate * 100
                const currency = 'EUR'
                const amount = service.beneficiaries[0].amountWTax
                const amountWTax: MoneyAmount = { amount: (amount * (1 + rate / 100)), currency }
                const amountWoTax: MoneyAmount = { amount, currency }
                const payload = {
                    objectType: "odm.offer.associatedinsurancebeneficiaries",
                    systemUid: "odm-offer",
                    coverage: service.beneficiaries[0].coverage,
                    basisValue: service.beneficiaries[0].basisValue,
                    basis: service.beneficiaries[0].basis,
                    rate: service.beneficiaries[0].rate,
                    taxValue: service.taxValue,
                    amountWTax: {
                        amount: service.beneficiaries[0].amountWTax,
                        currency: currency,
                    },
                    amountWoTax: {
                        amount: service.beneficiaries[0].amountWoTax,
                        currency: currency,
                    },
                    associatedParty: {
                        resourceUid: service.beneficiaries[0].partyUid,
                        objectType: "odm.offer.associatedparty",
                        systemUid: "odm-offer",
                    },
                    businessData: {
                        firstName: firstName,
                        familyName: lastName,
                        birthDate: service.beneficiaries[0].partyBirthDate,
                        role: service.beneficiaries[0].partyRole
                    }
                };

                associatedInsuranceBeneficiaries.push(payload);

                result = {
                    associatedInsuranceBeneficiaries: associatedInsuranceBeneficiaries,
                    resourceUid: service.reference,
                    objectType: "odm.offer.proposalaccessories.proposalinsurance.proposalpartyinsurance",
                    accessoryProduct: {
                        resourceUid: service.resourceUid,
                        objectType: "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.insuranceproductitem.partyinsuranceproductitem",
                        systemUid: "odm-simulation"
                    },
                    proposalAccessoryCalculationMethodOption: {
                        resourceUid: service.calculationMethodOption || '',
                        objectType: 'odm.offer.proposalaccessorycalculationmethodoption'
                    },
                    proposalAccessoryPaymentDatesOption: {
                        resourceUid: service.paymentDatesOption || '',
                        objectType: 'odm.offer.proposalaccessorypaymentdatesoption'
                    },
                    amountWTax ,
                    amountWoTax ,
                    rate,
                    basis: service.basis,
                    servicePaymentSchedules: [],
                    label: service.resourceUid
                }

                switch (service.objectType) {
                    case 'odm.simulation.simulationaccessoryitem.simulationinsuranceitem.simulationpartyinsuranceitem':
                        result.objectType = 'odm.offer.proposalaccessories.proposalinsurance.proposalpartyinsurance'
                        break
                }

                returnValue.push(result)
            }
        })
    }

    return returnValue
}

export function getMissingPacks(state: RootState, packs: ProductSummary[]): ProductSummary[] {
    const statePacksIds = state.financingModule.packs.map(mapResourceUid)
    return packs.filter(item => statePacksIds.indexOf(item.productUid.resourceUid || '') < 0)
}

export function getRemovedPacks(state: RootState, packs: ProductSummary[]): ProductSummary[] {
    const statePacksIds = packs.map(mapResourceUid)
    const statePacks = state.financingModule.packs
    return statePacks.filter(item => statePacksIds.indexOf(item.resourceUid) < 0)
}

export function getRemainingPacks(state: RootState, packs: ProductSummary[]): ProductSummary[] {
    const removed = getRemovedPacks(state, packs).map(mapResourceUid)
    const statePacks = state.financingModule.packs
    return statePacks.filter(item => removed.indexOf(item.resourceUid) < 0)
}

export function getCalculatedWithErrorPacks(state: RootState): ProductSummary[] {
    const statePacks = state.financingModule.packs
    const scalesWithErrorId = state.financingModule.scalesModule.scales
        .filter((s) => s.scalePresentation.loadingError)
        .map((s) => s.resourceUid)
    return statePacks.filter(item => scalesWithErrorId.indexOf(item.resourceUid) >= 0)
}


export function getProposalSelected(proposals: Proposal[]): Proposal {
    return proposals.filter((p: Proposal) => p.flagSelected)[0]
}

