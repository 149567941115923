import { Module } from "vuex";
import {
  ContactType,
  DemandState, KSIOPFinancialExposure, MoneyAmount,
  Offer,
  OfferAssociatedParty,
  RootState,
  SignatureMethodEnum,
  TaskSearchCriteria, ThirdType,
} from "@/types";
import store from "@/store";
import {
  buildFinancialExposures, callSaveFinancialExposure,
  deleteManager,
  getAssetBodyStyle,
  getAssetBrand,
  getAssetCategory,
  getAssetCustom,
  getAssetEnergy,
  getAssetModel,
  getAssetSubCategory,
  getassetTax,
  getAssetVersion,
  getCommercial,
  getDestributer,
  getGoodSubCategory,
  getNetwork,
  getNetworkAll,
  getNetworkNode,
  getNetworkNodeAll,
  getOffer,
  getPartyOrganization,
  getPartyRules,
  getPartyTypeByRole,
  getPartyUser,
  handleOfferForSave,
  PartyAssociated,
  searchAssetProductList,
  searchGuaranteeList,
  searchOffer,
  searchOfferPartyOrder,
  searchProductTax,
  setEvaluatedTaxRate,
} from "@/store/services/offerService";
import Notify from 'quasar/src/plugins/Notify.js';;
import {
  deepClone,
  deleteAttributesRecursively,
  formatDateRecursively,
  getOfferInfo,
  globalConfig,
  isManualSignature,
  Masks,
  UON,
} from "@/utils";
import moment from "moment/moment";
import {
  calculateProposalAsset,
  getAssetProduct,
} from "@/store/services/asset/assetMapper";
import { getTaskListByUser } from "@/store/services/task/workflow";
import { initWorkflow } from "../services/task/taskService";
import { getProposalStatus } from "../services/financing/proposalStatus";
import i18n from "@/i18n";
import { deletePartyContactMechanisms } from "@/commons/party-middleware";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import { getGoodProposalItem } from "../services/configuration/AssetService";

const demandModule: Module<DemandState, any> = {
  namespaced: true,
  state: {
    isSaving: false,
    isSummaryCharge: false,
    isEdit: false,
    assetState: {
      isFirstTab: true,
      mode: "manualMode",
      proposalAssets: [],
      credits: [],
      projects: [],
      overdraw: {
        overdraft: 0,
        totalAmountWTax: {
          amount: 0,
          currency: "EUR",
        },
        quantity: 1,
        _business_data: {
          displayAssetType: "DECOUVBANC",
          takenBack: true,
        },
      },
      cash: {
        treasury: 0,
        totalAmountWTax: {
          amount: 0,
          currency: "EUR",
        },
        quantity: 1,
        _business_data: {
          displayAssetType: "TRESORERIE",
          takenBack: true,
        },
      },
      toShowAssetBox: true,
    },
    deletedContacts: [],
    partyTaxRate: 0,
    selectedAssetFrom:{},
    selectedProductAsset:{},
    tempSelectedOfferGoods: {},
    quoteInfo: "",
    partyInfo: "",
    signatureMethod:""
  },
  getters: {
    getDeletedManager(state) {
      return state.deletedManager;
    },
    getCreditAsset() {
      return {
        supplier: {
          type: {
            value: "",
            label: "",
          },
        },
        party: {
          party: {
            objectType: UON.ObjectType.associatedParty,
            systemUid: UON.SystemUid.odmOffer,
          },
        },
        monthlyPaymentFormatted: 0,
        amountToRepay: 0,
        totalAmountWTax: 0,
        editable: true,
        quantity: 1,
        _business_data: {
          typeCredits: {
            type: {
              value: "",
              label: "",
            },
          },
          object: {
            type: {
              value: "",
              label: "",
            },
          },
          monthlyPayment: 0,
          originalAmount: 0,
          takenBack: false,
          startDate: moment(new Date()).format("DD/MM/YYYY"),
          endDate: "",
          displayAssetType: "CREDIT",
        },
      };
    },
    getProjectsAsset() {
      return {
        supplier: {
          type: {
            value: "",
            label: "",
          },
        },
        party: {
          party: {
            objectType: UON.ObjectType.associatedParty,
            systemUid: UON.SystemUid.odmOffer,
          },
        },
        totalAmountWTax: 0,
        editable: true,
        quantity: 1,
        _business_data: {
          typeProjects: {
            type: {
              value: "",
              label: "",
            },
          },
          amount: 0,
          takenBack: true,
          displayAssetType: "PROJET",
        },
      };
    },
    getAssetInit() {
      return {
        config: {
          readOnly: false,
          proposalAssetCalculated: {
            product: {
              name: "",
              unitaryPrice: 0,
              discount: 0,
              tax: 0,
              priceWithDiscount: 0,
              priceWithTax: 0,
            },
          },
          productAssetModel: undefined,
          assetType: undefined,
          formBind: {
            supplier: {
              name: "",
            },
            brand: {
              id: "",
              label: "",
              config: {},
            },
            version: {
              id: "",
              label: "",
              config: {},
            },
            range: {
              id: "",
              label: "",
              config: {},
            },
            carBodyStyle: {
              id: "",
              label: "",
              config: {},
            },
            engineType: {
              id: "",
              label: "",
              config: {},
            },
          },
        },
        systemUid: "odm-offer",
        businessData: null,
        daaq: "/",
        description: "",
        taxCode: null,
        taxValue: 0,
        quantity: 1,
        discountPercentage: 0,
        amountWoTax: { amount: 0, currency: "EUR" },
        discountAmount: { amount: 0, currency: "EUR" },
        taxAmount: { amount: 0, currency: "EUR" },
        amountWTax: { amount: 0, currency: "EUR" },
        totalAmountWoTax: { amount: 0, currency: "EUR" },
        totalTaxAmount: { amount: 0, currency: "EUR" },
        totalAmountWTax: { amount: 0, currency: "EUR" },
        assetProduct: null,
        packAssetProduct: null,
        proposalAssetItems: [],
        party: undefined,
        usage: null,
        flagNew: true,
        nbOfCylinder: null,
        nbOfGear: 0,
        taxHorsePower: null,
        horsePower: 0,
        co2Emissions: 0,
        mineType: null,
        fuelConsumptionUrban: null,
        fuelConsumptionExtraUrban: null,
        fuelConsumptionCombined: 0,
        brand: "",
        range: "",
        version: "",
        carBodyStyle: "",
        gearBox: null,
        engineType: null,
        matriculation: null,
        matriculationDate: null,
        chassisReference: null,
        nbOfDoor: null,
        nbOfSeat: null,
      };
    },
    getProposalAssetItemInit() {
      return {
        config: {
          required: false,
          selected: true,
          selectModel: {
            label: "",
            id: "",
          },
          unitaryAmountModel: 0,
          amountWithTaxModel: 0,
          taxValueModel: {
            label: "",
            id: "",
          },
          purchaseAmountWTaxModel: 0,
          purchaseAmountWoTaxModel: 0,
          reference: undefined,
        },
        objectType: "odm.offer.proposalassetitem",
        type: "",
        quantity: 1,
        label: "",
        discountPercentage: 0,
        taxValue: 0,
        amountWTax: { amount: 0, currency: "EUR" },
        discountAmount: { amount: 0, currency: "EUR" },
        taxAmount: { amount: 0, currency: "EUR" },
        amountWoTax: { amount: 0, currency: "EUR" },
        totalAmountWoTax: { amount: 0, currency: "EUR" },
        totalTaxAmount: { amount: 0, currency: "EUR" },
        totalAmountWTax: { amount: 0, currency: "EUR" },
        purchaseAmountWoTax: { amount: 0, currency: "EUR" },
        purchaseAmountWTax: { amount: 0, currency: "EUR" },
      };
    },
    getOfferInit(): any {
      return {
        reference: "",
        associatedParties: [
          store.getters["demandModule/getOfferAssociatedPartyInit"],
        ],
        validity: {
          from: "",
          until: "",
        },
        assignedSalesPerson: {
          id: "",
          label: "",
        },
        associatedUsers: [],
        proposals: [store.getters["demandModule/getProposalAssetItemInit"]],
        associatedNetworks: "",
        associatedNetworkNodes: "",
        channel: "",
        salesChannel: "",
        status: {
          resourceUid: "INITIAL",
        },
      };
    },
    getOfferAssociatedPartyInit(): OfferAssociatedParty {
      return {
        id: 1,
        tabName: "CUSTOMER",
        role_code: "CUSTOMER",
        showDeleteBtn: false,
        objectType: "",
        systemUid: "",
        resourceUid: "",
        associatedParty: {
          third: store.getters["demandModule/getThirdInit"],
        },
        signatureMethod: "ELECTRONIC",
      };
    },
    getAssetAssociatedPartyInit() {
      return {
        party: {},
        name: "",
      };
    },
    getManagerInit() {
      return {
        objectType: UON.ObjectType.frenchPerson,
        title: {
          type: {
            value: "M",
          },
        },
        contacts: [
          {
            contactMechanism: {
              phoneNumber: "",
              type: {
                id: ContactType.PHONE_CONTACT,
                config: {
                  objectType: "odm.party.contactmechanism.phonecontact",
                  fields: [
                    "objectType",
                    "systemUid",
                    "resourceUid",
                    "phoneNumber",
                  ],
                },
              },
              daaq: "/",
            },
          },
        ],
        roles: [
          {
            objectType: "odm.party.partyrole",
            role: {
              objectType: "odm.party.role",
              systemUid: "odm-party",
              resourceUid: "DIRG",
            },
            status: {
              objectType: "odm.party.rolestatus",
              resourceUid: "NEW",
            },
            daaq: "/",
          },
        ],
        age: 0,
        relationType: {
          type: {
            value: "DIRG",
          },
        },
        validity: {
          from: "",
          until: "",
        },
        birthplace: {
          type: {
            value: "",
            label: "",
            config: {
                city: "",
                department: "null",
                country: {
                    id: "",
                    label: ""
                }
            }
        },
          postalCode: "",
          country: {
            name: "",
            objectType: "odm.party.country",
          },
          inseeCode: "",
          region: "",
          department: "",
          city: "",
          objectType: "odm.party.postalcode",
        },
        daaq: "/",
        show: true,
      };
    },
    getLegalCategory() {
      return {
        objectType: "odm.party.organizationlegalcategory",
        type: {
          value: "",
        },
      };
    },
    getCodeNACE() {
      return {
        type: {
          value: "",
          label: "",
        },
      };
    },
    getGender() {
      return {
        type: {
          value: "",
          label: "",
        },
      };
    },
    getThirdInit() {
      return {
        type: {
          id: "",
          label: "",
        },
        relations: [],
        person: {
          title: {
            _controls: {
              attToRemove: ["type"],
            },
            type: {
              value: "M",
            },
          },
          birthChildren: [],
          firstName: "",
          familyName: "",
          birthDate: "",
          taxGeographicZone: "France_M",

          birthplace: {
            type: {
              id: "",
              label: "",
              config: {
                city: "",
                country: {
                  id: "",
                  label: "",
                },
              },
            },
            postalCode: "",
            country: {
              name: "",
              objectType: "odm.party.country",
            },
            inseeCode: "",
            region: "",
            department: "",
            city: "",
            objectType: "odm.party.postalcode",
          },
          currentMaritalStatus: {
            _controls: {
              attToRemove: ["type"],
            },
            type: {
              value: "",
              label: "",
            },
            ...store.getters["demandModule/getUniformOdmNameInit"],
          },
          currentMatrimonialRegime: {
            _controls: {
              attToRemove: ["type"],
            },
            type: {
              value: "",
              label: "",
            },
            ...store.getters["demandModule/getUniformOdmNameInit"],
          },
          maritalStatuses: [],
          contacts: [
            { ...store.getters["demandModule/getContactMechanismInit"] },
          ],
          financialExposures: [],
          ids: [{ ...store.getters["demandModule/getThirdIdProofInit"] }],
          addresses: [
            {
              ...store.getters["demandModule/getAddressInit"],
            },
          ],
          correspondents: [],
          nationality: {
            type: {
              id: "",
              label: "",
            },
          },
          gender: {
            type: {
              value: "",
              label: "",
            },
          },
          jobs: [{ ...store.getters["demandModule/getThirdJobsInit"] }],
          revenues: [{ ...store.getters["demandModule/getThirdAnalysisInit"] }],
          roles: [store.getters["demandModule/getRoleInit"]],
          classifications: [],
        },
        organization: {
          _controls: {
            attToRemove: ["type", "isDisabled"],
          },
          siren: "",
          name: "",
          codeApe: "",
          acronym: "",
          isCreated: true,
          commercialRegister: "",
          creationDate: "",
          taxGeographicZone: "France_M",
          numTVAIntraCom: "",
          activitySector: {
            objectType: "odm.party.activitysector",
            type: {
              value: undefined,
              label: "",
            },
          },
          codeNACE: {
            type: {
              value: "",
              label: "",
            },
          },
          legalCategory: store.getters["demandModule/getLegalCategory"],
          contacts: [],
          facilities: [],
          financialExposures: [],
          addresses: [
            {
              ...store.getters["demandModule/getAddressInit"],
            },
          ],
          correspondents: [],
          roles: [store.getters["demandModule/getRoleInit"]],
          manager: store.getters["demandModule/getManagerInit"],
          classifications: [],
        },
        isDisabled: true,
      };
    },
    getRoleInit() {
      return {
        objectType: "odm.party.partyrole",
        role: {
          objectType: "odm.party.role",
          systemUid: "odm-party",
          resourceUid: "CUSTOMER",
        },
        status: {
          objectType: "odm.party.rolestatus",
          resourceUid: "ACTIVATED",
        },
        validity: {
          from: formatDateLocale(
            moment().format("DD/MM/YYYY"),
            "DD/MM/YYYY",
            "fr"
          ),
          until: "",
        },
        preferences: [],
        daaq: "/",
      };
    },
    getContactMechanismInit() {
      return {
        contactMechanism: {
          _controls: {
            attToRemove: ["type", "isDisabled", "businessData"],
          },
          type: {
            id: ContactType.PHONE_CONTACT,
            label: i18n.global.t(
              "demand.party.contactMechanism.label.phonecontact"
            ),
            config: {
              objectType: "odm.party.contactmechanism.phonecontact",
              fields: [
                "phoneNumber",
                "acceptSMS",
                "objectType",
                "systemUid",
                "resourceUid",
                "validity",
                "nonSolicitation",
                "daaq",
              ],
            },
          },
          objectType: "odm.party.contactmechanism.phonecontact",
          emailAddress: "",
          identifier: "",
          phoneNumber: "",
          acceptSMS: false,
          nonSolicitation: false,
          isDisabled: false,
          daaq: "/",
        },
      };
    },
    getThirdIdProofInit() {
      return {
        _controls: {
          attToRemove: ["isDisabled"],
        },
        reference: "",
        emissionDate: "",
        authority: "",
        validity: {
          from: "",
          until: "",
        },
        documentType: {
          _controls: {
            attToRemove: ["type"],
          },
          type: {
            value: "",
            label: "",
          },
        },
        supportingDocument: null,
        objectType: "odm.party.identitydocument",
        isDisabled: true,
      };
    },
    getAddressInit() {
      return {
        daaq: "/",
        address: {
          type: {
            type: {
              id: "Residential",
              label: i18n.global.t("demand.party.addressType.Residential"),
            },
            objectType: "odm.party.addresstype",
          },
          line1: "",
          line2: "",
          line3: "",
          territory: "",
          postalCode: {
            type: {
              id: "",
              label: "",
              config: {
                city: "",
                country: {
                  id: "",
                  label: "",
                },
              },
            },
            postalCode: "",
            country: {
              name: "",
              objectType: "odm.party.country",
            },
            inseeCode: "",
            region: "",
            department: "",
            city: "",
            objectType: "odm.party.postalcode",
          },
          objectType: "odm.party.address",
          isDisabled: false,
          daaq: "/",
        },
        livingSituation: {
          type: {
            value: "",
            label: "",
          },
        },
      };
    },
    getCorrespondentInit() {
      return {
        _controls: {
          attToRemove: ["isDisabled"],
        },
        firstName: "",
        middleName: "",
        familyName: "",
        status: { objectType: "odm.party.partycorrespondentstatus" },
        signingAuthority: false,
        contactCorrespondent: [
          { ...store.getters["demandModule/getContactCorrespondentInit"] },
        ],
        qualificationType: {
          objectType: "odm.party.qualificationtype",
          resourceUid: "",
          type: {
            value: store.getters["picklistModule/qualificationTypeItems"][0].value
                    ?store.getters["picklistModule/qualificationTypeItems"][0].value
                    :'PDG',
          },
        },
        daaq: "/",
        isDisabled: false,
      };
    },
    getContactCorrespondentInit() {
      return { ...store.getters["demandModule/getContactMechanismInit"] };
    },
    getUniformOdmNameInit() {
      return {
        objectType: "",
        systemUid: "",
        resourceUid: "",
      };
    },
    getThirdJobsInit() {
      return {
        businessData: "",
        daaq: "/",
        contractType: {
          type: {
            label: "",
            value: "",
          },
        },
        positionType: {
          type: {
            label: "",
            value: "",
          },
        },
        occupationType: {
          type: {
            label: "",
            value: "",
          },
        },
        activity: {
          type: {
            label: "",
            value: "",
          },
        },
        validity: {
          from: "",
          until: "",
        },
        nbYearExpereinceActivity: "",
        nbYearExpereincePosition: "",
        employer: "",
        employerName: "",
        principal: false,
        nbHour: 0,
        employerCreationDate: "",
        tenureDate: "",
        jobLocation: { ...store.getters["thirdModule/getAddressInit"] },
      };
    },
    getSituationInit() {
      return {
        validity: {
          from: "",
          until: "",
        },
        maritalStatus: {},
        matrimonialRegime: {},
      };
    },
    getThirdAnalysisInit() {
      return {
        label: "",
        analysisDate: "",
        status: {
          type: {
            value: "",
            label: "",
          },
        },
        yearlyExpensesTotal: {
          currency: "EUR",
          amount: 0,
        },
        yearlyIncomeTotal: {
          currency: "EUR",
          amount: 0,
        },
        detail: [
          { ...store.getters["demandModule/getIncomeInit"] },
          { ...store.getters["demandModule/getExpenseInit"] },
        ],
        isDisabled: false,
      };
    },
    getIncomeInit() {
      return {
        type: "INCOME",
        nature: {
          type: {
            value: "",
          },
        },
        periodicity: {
          type: {
            label: "",
            value: "",
          },
        },
        value: "",
        label: "",
        validity: {
          from: "",
          until: "",
        },
        isDisabled: false,
      };
    },
    getExpenseInit() {
      return {
        type: "EXPENSE",
        nature: {
          type: {
            label: "",
            value: "",
          },
        },
        periodicity: {
          type: {
            label: "",
            value: "",
          },
        },
        value: "",
        label: "",
        validity: {
          from: "",
          until: "",
        },
        isDisabled: false,
      };
    },
    getPartyTaxRate(state) {
      return state.partyTaxRate;
    },
    getSelectedAssetFromInit() {
      return {}
    },
    getSlectedProductAssetInit() {
      return {}
    }
  },
  actions: {
    getProposalStatus({ commit }, payload) {
      const statusId = payload.statusId;
      getProposalStatus(statusId).then((response) => {
        if (payload.callback) {
          payload.callback(response.data);
        }
      });
    },
    mockState({ commit }, payload) {
      // TODO remove
      const { state } = payload;
      commit("setMockState", state);
    },
    recoverState({ commit }, payload) {
      const callback = payload ? payload.callback : null;
      const prevState = localStorage.getItem(
        globalConfig.storageKeys.demandModule
      );
      if (prevState) {
        const obj = JSON.parse(prevState);
        commit("setRecoverState", obj);
        if (callback) {
          callback(obj.offer);
        }
      }
    },
    networknode({ commit }, payload) {
      const { filter, callback } = payload;
      getNetworkNode(filter)
        .then((res) => {
          if (res.items.length == 1) {
            commit("setNetworkNode", res.items[0]);
          }
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    networknodeAll({ commit }, payload) {
      const { filter, callback } = payload;
      getNetworkNodeAll(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    getClientAssociated({ commit }, payload) {
      const { request, callback } = payload;
      PartyAssociated(request.resourceUid)
        .then((res) => {
          callback(res.party.resourceUid);
        })
        .catch((e) => console.error(e));
    },
    commercial({ commit }, payload) {
      const { filter, callback } = payload;
      getCommercial(filter)
        .then((res) => {
          if (res.items.length == 1) {
            commit("setCommercial", res.items[0]);
          }
          callback(res);
        })
        .catch((e) => console.error(e));
    },

    destributer({ commit }, payload) {
      const { filter, callback } = payload;
      getDestributer(filter)
        .then((res) => callback(res))
        .catch((e) => console.error(e));
    },
    partyTypeByRole({ commit }, payload) {
      const { filter, callback } = payload;
      getPartyTypeByRole(filter)
        .then((res) => callback(res))
        .catch((e) => console.error(e));
    },
    network({ commit }, payload) {
      const { filter, callback } = payload;
      getNetwork(filter)
        .then((res) => {
          if (res.items.length == 1) {
            commit("setNetwork", res.items[0]);
          }
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    networkAll({ commit }, payload) {
      const { filter, callback } = payload;
      getNetworkAll(filter)
        .then((res) => {
          if (res.items.length == 1) {
            commit("setNetwork", res.items[0]);
          }
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    assetCategory({ commit }, payload) {
      const { filter, callback } = payload;
      getAssetCategory(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    assetSubCategory({ commit }, payload) {
      const { filter, callback } = payload;
      getAssetSubCategory(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    goodSubCategory({ commit }, payload) {
      const { filter, callback } = payload;
      getGoodSubCategory(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    assetBodyStyle({ commit }, payload) {
      const { filter, callback } = payload;
      getAssetBodyStyle(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },

    assetBrand({ commit }, payload) {
      const { filter, callback } = payload;
      console.log(filter) 
      getAssetBrand(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    assetModel({ commit }, payload) {
      const { filter, callback } = payload;
      getAssetModel(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    assetVersion({ commit }, payload) {
      const { filter, callback } = payload;
      getAssetVersion(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    assetEnergy({ commit }, payload) {
      const { filter, callback } = payload;
      getAssetEnergy(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    assetCustom({ commit }, payload) {
      const { filter, callback } = payload;
      getAssetCustom(filter)
        .then((res) => {
          callback(res.items);
        })
        .catch((e) => console.error(e));
    },
    assetTax({ commit }, payload) {
      const { callback } = payload;
      getassetTax()
        .then((res) => {
          callback(res.items);
        })
        .catch((e) => console.error(e));
    },
    cleanState({ commit }, payload) {
      this.dispatch("financingModule/cleanState");
      this.dispatch("simulationModule/cleanState");
      this.dispatch("controlsModule/cleanState");
      commit("setInitial", payload);
    },
    changeAssetMode({ commit }, payload) {
      commit("setAssetMode", payload);
    },
    changeChannel({ commit }, payload) {
      commit("setChannel", payload);
    },
    addAsset({ commit }, payload) {
      commit("addAsset", payload);
    },
    setFirstTab({ commit }, payload) {
      commit("setFirstTab", payload);
    },
    changeAssetState({ commit }, payload) {
      commit("changeAssetState", payload);
    },
    saveOffer({ commit, dispatch, state }, body) {
      const payload = deepClone(body);
      commit("setIsSaving", true);
      if (state.deletedContacts && state.deletedContacts?.length > 0) {
        deletePartyContactMechanisms(state.deletedContacts);
        state.deletedContacts = [];
      }
      if (!payload.salesChannel) {
        delete payload.salesChannel;
      }
      deleteAttributesRecursively(payload, ["isDisabled"]);
      formatDateRecursively(payload, "DD/MM/YYYY", Masks.dateMask);
      handleOfferForSave(payload, this.state)
        .then((response) => {
          if (state.deletedManager) {
            deleteManager({
              manager: state.deletedManager,
              daaqResourceUid:
                (this.state as RootState).authModule?.daaqResourceUid ?? "/",
            })
              .catch((error) => {
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                  message: error.message,
                  color: "negative",
                });
              })
              .finally(() => {
                state.deletedManager = undefined;
              });
          }

          const { offer, offerAssets } = response;
          commit("setOffer", offer);
          if (offerAssets && offerAssets.length) {
            commit("setIsEdit", true);
            dispatch("updateAssetState", { offerAssets: offerAssets });
          }
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Succès: la demande ${offer.reference} a été enregistrée!`,
            color: "positive",
          });
        })
        .catch((error) => {
          if (Array.isArray(error) && error.every(i => typeof i === 'string')) {
            error.forEach((s: string) => {
              Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                message: i18n.global.t(s),
                color: "negative",
              });
            })
          }
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: i18n.global.t("main.dialog.errorMessage.saveError"),
            color: "negative",
          });
          return Promise.reject(error);
        })
        .finally(() => {
          commit("setIsSaving", false);
          store.dispatch("setSummaryAvailable", true);
          commit("setIsSummaryCharge", !state.isSummaryCharge);
        });
    },
    addAssociatedParty({ commit }, payload) {
      commit("addAssociatedParty", payload);
    },
    searchOffer({ commit }, payload) {
      const { filter,offset,limit, callback } = payload;
      searchOffer(filter,offset,limit)
        .then((response) => callback(response))
        .catch((error) => {});
    },

    searchOfferPartyOrder({ commit }, payload) {
      const { offerFilter, filterParty, orderFilter, callback } = payload;
      searchOfferPartyOrder(offerFilter, filterParty, orderFilter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },

    editOffer({ commit, dispatch }, payload) {
      const { resourceUid, callback } = payload;
      store.dispatch("setSummaryAvailable", false);
      getOffer(resourceUid, this.state)
        .then((response) => {
          const { offer, offerAssets, proposalScale } = response;
          commit("setOffer", offer);
          store.dispatch("setSummaryAvailable", true);
          //allows you to update the current context with the offer that was retrieved from the server
          this.dispatch("setCurrentContext", offer);
          //-------------------------------------
          if (offerAssets && offerAssets.length) {
            commit("setProposals", offer.proposals);
            dispatch("updateAssetState", { offerAssets: offerAssets });
            dispatch("setProposalScale", { proposalScale });
            this.dispatch("financingModule/calculateProposalAssetCarAmounts", {
              skipRecalculateScaleSelected: true,
            });
            commit("setIsEdit", true);
            this.dispatch("demandModule/setFirstTab", false);
          }

          const offerInfo = getOfferInfo(offer)
          dispatch("setPartyInfo", offerInfo)
          dispatch("setQuoteInfo", offerInfo)
          dispatch("setSignatureMethod", offer)
          callback();
        })
        .catch((error) => {
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: error.message,
            color: "negative",
          });
        });
    },
    getAssociatedPartyOrganization({ commit }, payload) {
      const { associatedParty, callback } = payload;
      getPartyOrganization(associatedParty).then((response) => {
        callback(response);
      });
    },
    searchAssetProductList({ commit }, payload) {
      searchAssetProductList().then((response: any) => {
        if (payload && payload.callback) {
          payload.callback(response);
        }
      });
    },
    searchGuaranteeList({ commit }, payload) {
      const { offer, callback } = payload;
      searchGuaranteeList(offer, this.state).then((response: any) => {
        if (callback) {
          callback(response);
        }
      });
    },
    searchAsset({ commit }, payload) {
      const { id, category, index, refreshAssetItems } = payload;
      if (id) {
        const proposalAsset =
          this.state.demandModule.assetState.proposalAssets[index];
        getAssetProduct(id, proposalAsset, category, refreshAssetItems)
          .then((res) => {
            commit("setProposalAssetByIndex", {
              proposalAsset: res,
              index,
            });
            if (payload.callback) {
              payload.callback(res);
            }
          })
          .catch((e: any) => {
            console.error(e);
          });
      }
    },
    initOfferWorkflow({ commit }, payload) {
      const offer = this.state.demandModule.offer;
      initWorkflow(offer)
        .then((res) => {
          if (payload.callback) {
            payload.callback();
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    },
    setProposalAssetByIndex({ commit }, payload) {
      commit("setProposalAssetByIndex", payload);
    },
    async fetchUser({ commit }, payload) {
      return getPartyUser();
    },
    searchProductTax({ commit }, payload) {
      const { code, callback } = payload;
      return searchProductTax(code)
        .then((response: any) => callback(response))
        .catch((e: any) => {
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    fetchRules() {
      return getPartyRules();
    },
    updateAssetState({ commit }, payload) {
      const { offerAssets, index } = payload;
      if (offerAssets) {
        if (index !== undefined) {
          commit("updateSpecificAssetState", payload);
        } else {
          commit("updateAssetState", offerAssets);
        }
      }
    },
    changeAssetReadOnly({ commit }, payload) {
      commit("setAssetReadOnly", payload);
    },
    removeSpecificAssetState({ commit }, payload) {
      commit("removeSpecificAssetState", payload);
    },
    addNewProposalAssetItem({ commit }, payload) {
      const { type, index } = payload;
      const proposalAsset =
        this.state.demandModule.assetState.proposalAssets[index];
      const proposalAssetItem = deepClone(
        store.getters["demandModule/getProposalAssetItemInit"]
      );
      proposalAssetItem.config.reference = (Math.random() + 1)
        .toString(36)
        .substring(7);
      proposalAssetItem.type = type;
      proposalAsset.proposalAssetItems.push(proposalAssetItem);
      commit("setProposalAssetByIndex", { proposalAsset, index });
    },
    updateProposalAssetItem({ commit }, payload) {
      commit("updateProposalAssetItem", payload);
    },
    removeProposalAssetItem({ commit }, payload) {
      commit("removeProposalAssetItem", payload);
    },
    calculateProposalAsset({ commit }, payload) {
      const { index } = payload;
      const proposalAsset =
        this.state.demandModule.assetState.proposalAssets[index];

      if(proposalAsset){
        calculateProposalAsset(proposalAsset);
        commit("setProposalAssetByIndex", { proposalAsset, index });
      }
    },
    calculateProposalAssetItem({ commit }, payload) {
      const { index, proposalAssetItem } = payload;
      const proposalAsset =
        this.state.demandModule.assetState.proposalAssets[index];
      calculateProposalAsset(proposalAsset);
      commit("updateProposalAssetItem", { proposalAssetItem, index });
    },
    setProposalItemFields({ commit }, payload) {
      const { resourceUid, callback } = payload;
      getGoodProposalItem(resourceUid)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    setProposalScale({ commit }, payload) {
      const { proposalScale } = payload;
      commit("setProposalScale", proposalScale);
      this.dispatch("financingModule/scalesModule/setScaleSelected", {
        scale: proposalScale,
      });
    },
    setQuoteInfo({commit},payload) {
      const { quoteInfo } = payload;
      commit("setQuoteInfo",quoteInfo)
    },
    setPartyInfo({ commit }, payload) {
      const { partyInfo } = payload;
      commit("setPartyInfo", partyInfo)
    },
    setSignatureMethod({ commit }, payload) {
      const signatureMethod = isManualSignature(payload.associatedParties) ? SignatureMethodEnum.MANSIGNATURE : SignatureMethodEnum.ELECSIGNATURE
      commit("setSignatureMethod",signatureMethod)
  },
    setEvaluatedTaxRate({ commit }, payload) {
      const { taxCode, requestBody, callback } = payload;
      setEvaluatedTaxRate(taxCode, requestBody)
        .then((res) => {
          commit("setPartyTaxRate", res);
          callback(res);
        })
        .catch((e) => console.error(e));
    },
    async workflowInitialized({ commit }, payload) {
      const taskCriteria: TaskSearchCriteria = {
        searchVariables: JSON.stringify([
          {
            name: "offerReference",
            operator: "eq",
            value: payload,
          },
        ]),
      };
      const res = await getTaskListByUser(taskCriteria || {});
      return res.data.length > 0;
    },
    initSelectedAssetFrom({ commit,  getters }) {
      const selectedAssetFrom = getters.getSelectedAssetFromInit
      commit('setSelectedAssetFrom', selectedAssetFrom)
    },
    initSelectedProductAsset({ commit,  getters }) {
        const slectedProductAsset = getters.getSlectedProductAssetInit
        commit('setSlectedProductAsset', slectedProductAsset)
    },
    updateTempSelectedOfferGoods({ commit }, tempSelectedOfferGoods) {
      commit('updateTempSelectedOfferGoods', tempSelectedOfferGoods);
    },
    setNetwork({ commit }, payload) {
      commit("setNetwork", payload);
    },
    setNetworkNode({ commit }, payload) {
      commit("setNetworkNode", payload);
    },
    recalculateKSIOP({ commit }, payload) {
      const {financial, associatedPartyIndex, currency, callback} = payload
      const financialExposure = buildFinancialExposures([financial])[0]
      const priceWTax: MoneyAmount = {
        amount: store.state.financingModule.proposalAssetTotalWithTax || 0,
        currency: currency
      }
      const ksiopFE: KSIOPFinancialExposure = {
        financialExposure,
        priceWTax
      }
      const associatedParty = this.state.demandModule.offer.associatedParties.filter((ap:any) => ap.id === associatedPartyIndex)[0].associatedParty
      if (associatedParty.third.type.id === ThirdType.PERSON) {
        const pp = associatedParty.third.person
        ksiopFE.resourceUid = pp.resourceUid
        ksiopFE.firstName = pp.firstName
        ksiopFE.familyName = pp.familyName
        ksiopFE.birthDate = pp.birthDate
      } else {
        const pm = associatedParty.third.organization;
        ksiopFE.resourceUid = pm.resourceUid
        ksiopFE.siret = pm.siret
      }
      const servicePI = this.state.financingModule.scalesModule.scaleSelected?.scalePresentation?.servicePartyInsurance || []
      if (servicePI.length) {
        let totalCoverage = 0
        servicePI.forEach((pi: any) => {
          totalCoverage = totalCoverage + Number((pi.beneficiaries || [])
              .filter((item: any) => item.partyUid === ksiopFE.resourceUid)
              .map((item: any) => item.coverage)
              .reduce((a: number, b: number) => a + b, 0))
        })
        ksiopFE.coverage = totalCoverage
      }
      callSaveFinancialExposure(ksiopFE).then(res => {
        if (callback) {
          callback(res)
        }
      })
    }
  },
  mutations: {
    setIsSaving(state, payload) {
      state.isSaving = payload;
    },
    setIsSummaryCharge(state, payload) {
      state.isSummaryCharge = payload;
    },
    setProposalScale(state, payload) {
      state.proposalScaleId = payload.resourceUid;
    },
    setProposals(state, payload) {
      state.proposals = payload;
    },
    setIsEdit(state, payload) {
      state.isEdit = payload;
    },
    updateAssetState(state, payload) {
      if (payload.length) {
        state.assetState.proposalAssets = payload;
      }
    },
    updateSpecificAssetState(state, payload) {
      const { offerAssets, index } = payload;
      state.assetState.proposalAssets[index] = offerAssets[0];
    },
    removeSpecificAssetState(state, payload) {
      const { index } = payload;
      state.assetState.proposalAssets.splice(index, 1);
    },
    setMockState(state, payload) {
      // TODO remove
      const newState: DemandState = payload;
      state.offer = newState.offer;
      state.assetState = newState.assetState;
      state.showSummary = newState.showSummary;
      state.showRightSummary = newState.showRightSummary;
      state.showDecisionTab = newState.showDecisionTab;
      state.showUploadTab = newState.showUploadTab;
      state.mockState = true;
    },
    setRecoverState(state, payload) {
      state.offer = payload.offer;
      state.assetState = payload.assetState;
      state.showSummary = payload.showSummary;
    },
    setNetwork(state, payload) {
      if (state.offer) {
        state.offer.associatedNetworks = payload?.id ?? "";
      }
    },
    setNetworkNode(state, payload) {
      if (state.offer) {
        state.offer.associatedNetworkNodes = payload?.id ?? "";
      }
    },
    setCommercial(state, payload) {
      if (state.offer) {
        state.offer.assignedSalesPerson = payload;
      }
    },
    setInitial(state) {
      state.assetState.isFirstTab = true;
      state.assetState.proposalAssets = [];
      state.showSummary = true;
      state.showRightSummary = false;
      state.isEdit = false;
      state.proposals = [];
      state.partyTaxRate = 0;
    },
    setAssetMode(state, payload) {
      state.assetState.mode = payload;
    },
    setChannel(state, payload) {
      if (state.offer?.salesChannel || state.offer?.salesChannel == "") {
        state.offer.salesChannel = payload;
      }
    },
    addAsset(state, payload) {
      const { asset } = payload;
      const isFirstTab = state.assetState.isFirstTab;
      if (isFirstTab) {
        state.assetState.proposalAssets[0] = asset;
      } else {
        state.assetState.proposalAssets.push(asset);
      }
    },
    setFirstTab(state, payload) {
      state.assetState.isFirstTab = payload;
    },
    changeAssetState(state, payload) {
      if (
        state.assetState.proposalAssets.some(
          (item) => item.resourceUid === payload.resourceUid
        )
      ) {
        state.assetState.proposalAssets = [
          deepClone(state.assetState).proposalAssets.filter(
            (att: any) => att.resourceUid !== payload.resourceUid
          ),
          payload,
        ];
      } else {
        state.assetState.proposalAssets.push(payload);
      }
    },
    addAssociatedParty(state, payload) {
      state.offer?.associatedParties?.push(payload);
    },
    setOffer(state, payload: Offer) {
      state.offer = payload;
    },
    setQuoteInfo(state, payload: string) {
      state.quoteInfo = JSON.stringify(payload)
    },
    setPartyInfo(state,payload:string) {
      state.partyInfo = JSON.stringify(payload)
    },
    setSignatureMethod(state, payload) {
      state.signatureMethod = payload
    },
    setProposalAssetByIndex(state, payload) {
      const { proposalAsset, index } = payload;
      state.assetState.proposalAssets[index] = proposalAsset;
    },
    setAssetReadOnly(state, payload) {
      const { readOnly, index } = payload;
      state.proposals = payload;
      state.assetState.proposalAssets[index].config.readOnly = readOnly;
    },
    updateProposalAssetItem(state, payload) {
      const { proposalAssetItem, index } = payload;
      const proposalAsset = state.assetState.proposalAssets[index];
      if (proposalAsset.proposalAssetItems) {
        const proposalAssetItemStateIndex =
          proposalAsset.proposalAssetItems.indexOf(proposalAssetItem);
        proposalAsset.proposalAssetItems[proposalAssetItemStateIndex] =
          proposalAssetItem;
      }
    },
    removeProposalAssetItem(state, payload) {
      const { proposalAssetItem, index } = payload;
      const proposalAsset = state.assetState.proposalAssets[index];
      proposalAsset.proposalAssetItems = (
        proposalAsset.proposalAssetItems || []
      ).filter((item) => {
        const compareResourceUid =
          !!item.resourceUid &&
          !!proposalAssetItem.resourceUid &&
          item.resourceUid === proposalAssetItem.resourceUid;
        const compareReference =
          item.config.reference &&
          proposalAssetItem.config.reference &&
          item.config.reference === proposalAssetItem.config.reference;
        const isRemoved = compareResourceUid || compareReference;
        return !isRemoved;
      });
    },
    setPartyTaxRate(state, payload) {
      state.partyTaxRate = payload[0].taxRate;
    },
    setSelectedAssetFrom(state, payload) {
      state.selectedAssetFrom = payload
    },
    setSlectedProductAsset(state, payload) {
      state.selectedProductAsset = payload
    },
    updateTempSelectedOfferGoods(state, payload) {
      state.tempSelectedOfferGoods = payload;
    }
  },
};

export default demandModule;
