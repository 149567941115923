import { QcHeader, GarantySearch, Classifications, GarantieDetails, Third } from "@/types";
import { headers, operator, setOtherFilters, getGuarantee, UON, unmountSelectFieldsV2, upsertWithDataReturn, mountSelectFields } from "@/utils";


import { api } from "@/auth/api";
import { settings } from "@/settings";
import store from "@/store";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";

export const garantyList = async (filter: GarantySearch,offset?:any , limit?: any) => {
  const operators = [];

  const qc_header: QcHeader = {
    qc: {
      queryId: "product-product-feature",
      offset: offset || 0,
      limit: limit || 100,
      parameters: {
        ...filter,
      },
    },
  };

  const PRODUCT_FEATURE = "product_product_feature";

  if (filter?.reference) {
    operators.push(
      operator({
        val: filter.reference,
        table: PRODUCT_FEATURE,
        dbField: "reference",
      }).LIKE
    );
  }


  if (filter?.type) {

    operators.push(
      operator({
        val: filter.type,
        table: PRODUCT_FEATURE,
        dbField: "_discriminator",
      }).LIKE
    );
  }

  if (filter?.guaranty_name) {
    operators.push(
      operator({
        val: filter.guaranty_name,
        table: PRODUCT_FEATURE,
        dbField: "name",
      }).LIKE
    );
  }

  if (filter?.status) {
    operators.push(
      operator({
        val: filter.status,
        table: PRODUCT_FEATURE,
        dbField: "status_code",
      }).LIKE
    );
  }

  setOtherFilters(operators, qc_header);

  return await api().get(settings.api_query_url, {
    headers: headers({
      qc: JSON.stringify(qc_header.qc),
    }),
  });
}


export async function SaveGaranty(body: GarantieDetails) {
  unmountSelectFieldsV2(body, [
    'status', 'role', 'basis', 'underlyingType']);
  let categoryClassResourceUid = "";
  let garantyType = "";
  if (body?.resourceUid === "") {
    delete body.resourceUid;
  }
  if (!body.validity) {
    intiValidity(body)
  }
  if (body.validity) {
    body.validity.from = body.validity.from ? formatDateLocale(body.validity.from, i18n.global.t("mask.format"), 'fr') : ''
    body.validity.until = body.validity.until ? formatDateLocale(body.validity.until, i18n.global.t("mask.format"), 'fr') : ''
  }
  if (body.garantietype.type.value) {
    switch (body.garantietype.type.value) {
      case 'PERSONAL':
        garantyType = "personalguarantee";
        categoryClassResourceUid = "GUARANTEE_TYPE.PERSONAL"
        delete body.underlyingType;
        delete body.commissionFlag;
        break;
      case 'BANK':
        garantyType = "bankguarantee";
        categoryClassResourceUid = "GUARANTEE_TYPE.BANK"
        delete body.underlyingType;
        break;
      case 'PROPERTYPLEDGING':
        garantyType = "propertypledging";
        categoryClassResourceUid = "GUARANTEE_TYPE.PROPERTYPLEDGING"
        delete body.commissionFlag;
        if (body.underlyingType !== undefined && typeof body.underlyingType === 'object' && Object.keys(body.underlyingType).length === 0) {
          delete body.underlyingType;
        }
        break;
      case 'BUYBACK':
        garantyType = "buyback";
        categoryClassResourceUid = "GUARANTEE_TYPE.BUYBACK"
        delete body.commissionFlag;
        if (body.underlyingType !== undefined && typeof body.underlyingType === 'object' && Object.keys(body.underlyingType).length === 0) {
          delete body.underlyingType;
        }
        break;
      case 'MORTGAGE':
        garantyType = "mortgage";
        categoryClassResourceUid = "GUARANTEE_TYPE.MORTGAGE"
        delete body.commissionFlag;
        if (body.underlyingType !== undefined && typeof body.underlyingType === 'object' && Object.keys(body.underlyingType).length === 0) {
          delete body.underlyingType;
        }
        break;
    }
    body.objectType = "odm.product.productfeature.product.guaranteeproduct." + garantyType;
  }
  if (body.evaluationMethod === "Amount") {
    delete body.basis;
    delete body.rate;
  }
  else if (body.evaluationMethod === "Percentage") {
    delete body.amount;
  }
  else {
    delete body.evaluationMethod
    delete body.basis;
    delete body.rate;
    delete body.amount;
  }

  if (!body.partyReference?.resourceUid) {
    delete body.partyReference;
  }

  body.businessData = null;
  body.daaq = "/";
  body.qualifier = null;
  body.feature = [];
  if (!body.resourceUid) {
    body = buildClassificationGaranty(body, categoryClassResourceUid)
  }

  delete body.garantietype;

  const data = await upsertWithDataReturn(UON.SystemUid.odmProduct, body, garantyType);
  return data
}

function buildClassificationGaranty(body: GarantieDetails, categoryClassResourceUid: string) {

  const classification: Classifications[] = []

  classification.push({

    objectType: "odm.product.productclassification",
    systemUid: "odm-product",
    businessData: {},
    daaq: "/",
    validity: {
      "from": "",
      "until": ""
    },
    categoryClass: {
      objectType: "odm.product.productcategoryclass",
      systemUid: "odm-product",
      resourceUid: categoryClassResourceUid
    },
    priority: 1,
    category: {
      objectType: "odm.product.productcategory",
      systemUid: "odm-product",
      resourceUid: "GUARANTEE_TYPE"
    }
  })

  body.classifications = classification;
  return body
}

export async function getSelectedGaranty(resourceUid: string, discriminator: string): Promise<any> {

  const garantyType = discriminator.split('-')[1]?.toLowerCase()
  const url = `/odm-product/api/1/odm-product/${garantyType}/${resourceUid}/`;
  const data = (await api().get(url)).data
  return data
}


export async function buildSelectedGaranty(garanty: any) {
  const EmptyGarantyEdit = store.getters["garantyModule/getGarantyselectedInit"];
  let garantyType = "";
  mountSelectFields(garanty, ['status', 'role', 'evaluationMethod', 'underlyingType', 'basis']);
  const objectType = garanty.objectType
  const Type = objectType.substring(objectType.lastIndexOf('.') + 1);
  switch (Type) {
    case 'personalguarantee':
      garantyType = "PERSONAL";
      delete garanty.underlyingType;
      delete garanty.commissionFlag;
      break;
    case 'bankguarantee':
      garantyType = "BANK";
      garanty.commissionFlag ? EmptyGarantyEdit.commissionFlag = garanty.commissionFlag : delete garanty.commissionFlag
      delete garanty.underlyingType;
      break;
    case 'propertypledging':
      garantyType = "PROPERTYPLEDGING";
      delete garanty.commissionFlag;
      garanty.underlyingType ? EmptyGarantyEdit.underlyingType.type.value = garanty.underlyingType.type.value : delete garanty.underlyingType
      break;
    case 'buyback':
      garantyType = "BUYBACK";
      delete garanty.commissionFlag;
      garanty.underlyingType ? EmptyGarantyEdit.underlyingType.type.value = garanty.underlyingType.type.value : delete garanty.underlyingType
      break;
    case 'mortgage':
      garantyType = "MORTGAGE";
      delete garanty.commissionFlag;
      garanty.underlyingType ? EmptyGarantyEdit.underlyingType.type.value = garanty.underlyingType.type.value : delete garanty.underlyingType
      break;
  }

  if (!garanty.validity || garanty.validity === null || garanty.validity === "") {
    intiValidity(garanty)
  }

  if (garanty.validity) {
    EmptyGarantyEdit.validity = {
      from: garanty.validity && garanty.validity.from ? formatDateLocale(garanty.validity?.from, i18n.global.t("mask.format"), 'fr', 'DD/MM/YYYY') : '',
      until: garanty.validity && garanty.validity.until ? formatDateLocale(garanty.validity.until, i18n.global.t("mask.format"), 'fr', 'DD/MM/YYYY') : '',
    }
  }

  if (!garanty.basis || garanty.basis === "") {
    delete garanty.basis;
  }
  EmptyGarantyEdit.reference = garanty.reference
  EmptyGarantyEdit.name = garanty.name
  EmptyGarantyEdit.objectType = objectType
  EmptyGarantyEdit.resourceUid = garanty.resourceUid
  EmptyGarantyEdit.status.type.value = garanty.status.type.value
  EmptyGarantyEdit.qualifier = garanty.qualifier
  EmptyGarantyEdit.tax = garanty.tax
  EmptyGarantyEdit.feature = garanty.feature
  EmptyGarantyEdit.classifications = garanty.classifications
  EmptyGarantyEdit.partyReference = garanty.partyReference
  EmptyGarantyEdit.role.type.value = garanty.role.type.value
  EmptyGarantyEdit.evaluationMethod = garanty.evaluationMethod.type.value
  EmptyGarantyEdit.rate = garanty.rate
  EmptyGarantyEdit.garantietype.type.value = garantyType

  if (garanty.evaluationMethod.type.value === "Percentage") {
    garanty.basis ? EmptyGarantyEdit.basis.type.value = garanty.basis.type.value : delete garanty.basis
  }
  if (garanty.evaluationMethod.type.value === "Amount") {
    garanty.amount ? EmptyGarantyEdit.amount = garanty.amount : delete garanty.amount
  }

  return EmptyGarantyEdit
}

export async function getSelectedParty(resourceUid: string): Promise<any> {

  const url = `/odm-party/api/1/odm-party/party/${resourceUid}/`;
  const data = (await api().get(url)).data
  return data
}


export async function deleteSelectedGaranty(resourceUid: string, discriminator: string): Promise<any> {

  const garantyType = discriminator.split('-')[1]?.toLowerCase()
  const url = `/odm-product/api/1/odm-product/${garantyType}/${resourceUid}/`;
  const data = (await api().delete(url)).data
  return data
}

function intiValidity(body: GarantieDetails) {
  body.validity = {
    from: "",
    until: ""
  }
}


export async function getSelectedGuarante(guaranteeType: string, guaranteeId: string) {
  return await getGuarantee(UON.SystemUid.odmProduct, guaranteeType, guaranteeId);
}