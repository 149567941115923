import { Module } from "vuex";
import { ProposalGuarantee, ProposalGuaranteeState, RootState, UniformOdmName } from "@/types";
import { getProductGuarantee } from "@/store/services/financing/guarantee/proposalGuaranteeService";
import { getParty } from "@/store/services/searchThird";
import { calculatePercentageAmount } from "@/store/services/simulation";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import { isOrganization, isPerson, pickAttributes, UON } from "@/utils";
import { ProposalGuaranteeClass } from "@/store/services/financing/guarantee/domain/ProposalGuaranteeClass";
import { GuaranteeProductClass } from "@/store/services/financing/guarantee/domain/GuaranteeProductClass";
import { AssociatedPartyClass } from "@/store/services/financing/guarantee/domain/AssociatedPartyClass";

const checkPerson = (response: any) => {
    if (isPerson(response)) {
        return response.firstName + " " + response.familyName;
    }
    else if (isOrganization(response)) {
        return response.name;
    }
    else {
        return '';
    }
}

const getPartyLabel = async (res: any) => {
    const objectTypeArray = res.partyReference?.objectType?.split('.');
    if (objectTypeArray && res.partyReference?.resourceUid) {
        const type = `party-${objectTypeArray[objectTypeArray.length - 1]}`;
        const { response } = await getParty(type, res.partyReference?.resourceUid);
        return checkPerson(response);
    }
    return '';
}

const initProposalGuarantee = (): ProposalGuarantee => ({
    index: 0,
    label: "",
    productGuarantee: {
        reference: "",
        guaranteeType: "",
        evaluationMethod: {},
        underlyingType: {
            value: ''
        },
    },
    associatedParty: {
        party: {}
    },
    amount: {
        amount: 0,
        currency: "EUR",
    }
});

const setPartyCombo = (payload: ProposalGuaranteeClass) => {
    if (payload.associatedParty.third) {
        const third = payload.associatedParty.third;
        const party = pickAttributes(third,
            'objectType', 'resourceUid'
        );
        payload.associatedParty.party = {
            config: {
                objectType: party.objectType
            },
            value: party.resourceUid,
            label: checkPerson(third)
        };
        delete payload.associatedParty.third;
    }
}

const proposalGuaranteeModule: Module<ProposalGuaranteeState, RootState> = {
    namespaced: true,
    state: {
        proposalGuaranteeSelected: initProposalGuarantee(),
        proposalGuarantees: [],
    },
    getters: {
        getProposalGuarantees: (state) => state.proposalGuarantees,
        getProposalGuaranteeSelected: (state) => state.proposalGuaranteeSelected,
        getProductGuaranteeSelected: (state) => state.productGuaranteeSelected,
        getInitProposalGuarantee: () => initProposalGuarantee(),
    },
    actions: {
        showProposalGuaranteeDetail({ commit }) {
            commit("showGuaranteeDetail");
        },
        initProposalGuarantee({ commit, dispatch }, payload) {
            dispatch("showProposalGuaranteeDetail");
            if (payload) {
                const { productGuarantee, proposalGuaranteeIndex } = payload;
                const { resourceUid, productUid } = productGuarantee;
                const guaranteeType = productUid.objectType.substring(productUid.objectType.lastIndexOf('.') + 1);
                if (resourceUid === '') {
                    console.error('ProductGuarantee resourceUid not found');
                }

                const prepareCombobox = (uon: UniformOdmName, label: string) => ({
                    value: uon?.resourceUid,
                    label,
                    config: uon ?? undefined
                })

                getProductGuarantee(resourceUid, guaranteeType).then(async (response: any) => {
                    const partyLabel = await getPartyLabel(response);
                    commit('updateProductGuaranteeSelected', { partyLabel, response });

                    const guaranteeProduct = new GuaranteeProductClass(response);

                    const proposalGuarantee = new ProposalGuaranteeClass(response);
                    proposalGuarantee.objectType = UON.ObjectType.proposalGuarantee.objectType.get(guaranteeType);
                    proposalGuarantee.index = proposalGuaranteeIndex;
                    proposalGuarantee.associatedPartyFlag = !!response.partyReference?.resourceUid;
                    proposalGuarantee.productGuarantee = guaranteeProduct;

                    const associatedParty = new AssociatedPartyClass();
                    associatedParty.party = prepareCombobox(response.partyReference, partyLabel);
                    associatedParty.role = {
                        objectType: UON.ObjectType.associatedPartyRole,
                        systemUid: UON.SystemUid.odmOffer,
                        resourceUid: response.role?.resourceUid
                    }

                    proposalGuarantee.associatedParty = associatedParty

                    commit("setProposalGuaranteeSelected", proposalGuarantee);

                    const { basis, rate } = proposalGuarantee;
                    if (rate && basis?.resourceUid) {
                        dispatch("calculateProposalGuaranteePercentage", { guaranteeProduct, basis, rate })
                    }
                }).catch(e => console.error(e));
            }
        },
        calculateProposalGuaranteePercentage({ commit }, payload) {
            const { guaranteeProduct, basis, rate } = payload;
            if (basis && rate) {
                const guaranteeArray = [{
                    resourceUid: guaranteeProduct.resourceUid,
                    basis: {
                        objectType: "odm.offer.basis",
                        resourceUid: basis.resourceUid,
                    },
                    rate: rate
                }]

                calculatePercentageAmount(guaranteeArray, this.state).then((result: any) => {
                    commit('setBasisValue', result.guarantees[0])
                })
            } else {
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                    color: "negative",
                    message: i18n.global.t("guarantees.messages.missingInputs"),
                });
            }
        },
        editProposalGuarantee({ commit }, payload) {
            setPartyCombo(payload);
            commit('setProposalGuaranteeSelected', payload);
        },
        deleteProposalGuarantee({ commit }, rowIndex) {
            commit('deleteProposalGuarantee', rowIndex);
        },
        setProductGuaranteeSelected({ commit }, payload) {
            commit('setProductGuaranteeSelected', payload);
        },
        setProposalGuarantees({ commit }, payload) {
            commit('setProposalGuarantees', payload);
        },
        cleanState({ commit }) {
            commit('cleanState');
        }
    },
    mutations: {
        setProposalGuaranteeSelected(state, payload) {
            state.proposalGuaranteeSelected = payload;
        },
        deleteProposalGuarantee(state, rowIndex) {
            state.proposalGuarantees.splice(rowIndex, 1);
        },
        showGuaranteeDetail(state) {
            state.openGuarantee = !state.openGuarantee;
        },
        setProductGuaranteeSelected(state, payload) {
            state.productGuaranteeSelected = payload
        },
        updateProductGuaranteeSelected(state, payload) {
            const { response, partyLabel } = payload
            state.productGuaranteeSelected = {
                ...state.productGuaranteeSelected,
                role: response.role,
                partyReference: {
                    ...response.partyReference,
                    label: partyLabel
                }
            }
        },
        setBasisValue(state, payload) {

            if (state.proposalGuaranteeSelected) {
                state.proposalGuaranteeSelected.amount = payload.calculatedAmount
                state.proposalGuaranteeSelected.basisValue = payload.basisValue
            }
        },
        setProposalGuarantees(state, payload) {
            state.proposalGuarantees = payload
        },
        cleanState(state) {
            state.proposalGuarantees = [];
            state.openGuarantee = false;
        }
    },
}

export default proposalGuaranteeModule
