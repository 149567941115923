import { deepClone } from "@/utils";

export const setRule = (obj?: object) =>
  ({
    ...obj,
  } as { [key: string]: string });

export const setOrderedRule = (
  rule: { [p: string]: string },
  orderedKeys: string[]
) => {
  return Object.assign({}, ...orderedKeys.map((key) => ({ [key]: rule[key] })));
};

export const getChildOutputHeader = <T>(rule: T) => {
  return Object.entries(rule)
    .filter(([key]) => !key.toLowerCase().includes("toggle"))
    .map(([key], index: number) => ({
      label: `Output Header ${index + 1}`,
      externalId: `oh-${key}-businessRuleId`,
      defaultValue: "",
      businessRuleResultType: key,
    }));
};

export const getChildBusinessRuleResult = <T>(rule: T, briIndex: number) => {
  return Object.entries(rule)
    .filter(([key]) => !key.toLowerCase().includes("toggle"))
    .map(([key, value]) => ({
      result:
        value === null || value === "" || value === undefined
          ? "return 'null'"
          : `return '${value}'`,
      externalId: `bris-${key}-bri-${briIndex}-businessRuleId`,
      outputHeader: {
        label: null,
        externalId: `oh-${key}-businessRuleId`,
        defaultValue: null,
        businessRuleResultType: null,
      },
    }));
};

export const rulePayload = (params: {
  inputAttribute: string;
  inputDataType: string;
  orderedRule: { [p: string]: string };
}) => {
  const { inputAttribute, inputDataType, orderedRule } = params;
  const childInputHeader = {
    label: "Input Header 1",
    dataType: inputDataType,
    attribute: inputAttribute,
    externalId: "ih-inputHeader1AssessmentKey1-businessRuleId",
    defaultValue: "",
    assessmentKey: "inputHeader1AssessmentKey1",
  };
  const childOutputHeader = getChildOutputHeader(orderedRule);
  const childBusinessRuleItem = [
    {
      externalId: "bri-1-businessRuleId",
      evaluationOrder: 1,
      childBusinessRuleResult: getChildBusinessRuleResult(orderedRule, 1),
      childBusinessRuleAssessment: [
        {
          rule: "return true",
          externalId: "bria-inputHeader1AssessmentKey1-bri-1-businessRuleId",
          inputHeader: {
            label: null,
            dataType: null,
            attribute: null,
            externalId: "ih-inputHeader1AssessmentKey1-businessRuleId",
            defaultValue: null,
            assessmentKey: null,
          },
        },
      ],
    },
  ];

  return {
    label: "BusinessRule1",
    externalId: "businessRuleId",
    failIfNoResult: false,
    childInputHeader: [childInputHeader],
    childOutputHeader: childOutputHeader,
    childBusinessRuleItem: childBusinessRuleItem,
    returnMultipleResults: false,
  };
};

export const extract = (rule: any, results: any) => {
  const advancedResults: any = [];
  rule.childBusinessRuleItem.forEach((item: any) => {
    let mock = deepClone(results);
    item.childBusinessRuleResult.forEach((businessRuleResult: any) => {
      const outputHeaderId = businessRuleResult.outputHeader.externalId;
      const result = businessRuleResult.result;

      const resultMatch = result.match(/^return '([^']*)'$/);
      const outputHeaderIdMatch = outputHeaderId.match(
        /^oh-(\w+)-businessRuleId$/
      );

      if (resultMatch && outputHeaderIdMatch) {
        const extractBusinessRuleResult =
          resultMatch[1] === "null" ? null : resultMatch[1];
        const extractBusinessRuleType = outputHeaderIdMatch[1];

        mock[extractBusinessRuleType] = extractBusinessRuleResult || "";
      }
    });
    advancedResults.push(mock);
    mock = results;
  });

  return advancedResults;
};
