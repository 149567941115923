import { UON, headers, operator, setOtherFilters } from "@/utils";
import { api } from "@/auth/api";
import { settings } from "@/settings";

const odm_user = UON.SystemUid.odmUser;
const odm_configuration = UON.SystemUid.odmConfiguration;

export async function fetchAllDashboard() {
    return (
      await api().get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "configuration-dashboard-menu",
            offset: 0,
            limit: -1,
          }),
        }),
      })
    ).data;
}

export function getAllUsersByDashbordId(id: string) {
  return api().get(`${settings.api_url}/${odm_configuration}/api/1/${odm_configuration}/dashboard/external/${id}/user/`);
}

export async function deleteUserDashbord(id: string) {
  const baseUrl = `${settings.api_url}/${odm_configuration}/api/1/${odm_configuration}/dashboard/${id}/`;
  const result = await api().delete(`${baseUrl}`);
  return result;
}

export async function saveNewExternalDashboard(newExternalDashboard: any ,user: any) {    
  const baseUrl = `${settings.api_url}/${odm_configuration}/api/1/${odm_configuration}/dashboard/`;
    await api().post(`${baseUrl}`, {
      objectType: "odm.configuration.dashboard",
      systemUid: "odm-configuration",
      name: newExternalDashboard.name,
      user: {
        resourceUid: user,
        objectType: "odm.user.leaseforgeuser",
        systemUid: odm_user
      },
      externalDashboard: newExternalDashboard.externaldashboard
    })
}
    
