import { Availability, AvailabilitySearch, QcHeader, ScaleBuisinessRuleSection } from "@/types";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import {
    formatDateRecursively,
    getOperator,
    globalConfig,
    headers,
    Masks,
    operator, Paths,
    setOtherFilters,
    UON
} from "@/utils";
import { saveProductAvailability } from "@/store/services/financing/product";
import { useApi } from "@/requests/useApi";

import store from "@/store";
import { extract } from "@/commons/businessRules";

const buisinessRuleResults = {
    category: '',
    class: '',
    priority: 0,
    advancedConfigurationToggle: false,
}

export const saveAvailability = async (availability: any, daaqResourceUid?: string) => {
    formatDateRecursively(availability.validity, Masks.dateMask_Sys, Masks.dateMask)
    if (availability.availableCategories) {
        (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.AVAILABILITY as any] as any).selectedBuisinessRule!.returnMultipleResults = true
        deleteFromAvailabilityAvailableCategories(availability)
        availability.availableCategories.ruleEngineId = null
        availability.availableCategories.rule = (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.AVAILABILITY as any] as any).selectedBuisinessRule
    }
    return await saveProductAvailability(availability, daaqResourceUid)
}

export async function getAvailability(resourceUid: string): Promise<any> {
    const { response } = await useApi(UON.SystemUid.odmProduct, Paths.productAvailability).get(resourceUid)
    const availability = buildAvailabilityRule(response)
    return availability
}
export const buildAvailabilityRule = async (availability: any) => {

    if (availability.availableCategories.rule) {
        if (availability.availableCategories?.rule.returnMultipleResults) {

            (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.AVAILABILITY as any] as any).selectedBuisinessRule = availability.availableCategories.rule
        }
        const extracRule = extract(availability.availableCategories.rule, buisinessRuleResults);
        store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.AVAILABILITY });
        availability.availableCategories = extracRule[0] || {}
    }

    return availability



}
export const deleteFromAvailabilityAvailableCategories = async (availability: any) => {
    delete availability.availableCategories.advancedConfigurationToggle
    delete availability.availableCategories.category
    delete availability.availableCategories.class
    delete availability.availableCategories.priority

}


export const availabilityFilter = async (filter: AvailabilitySearch, offset?:any , limit?: any) => {

    const qc_header: QcHeader = {
        qc: {
            queryId: 'product-availability-filter-search',
            offset: offset || 0,
            limit: limit || 100,
            parameters: {
                ...filter
            }
        }
    };

    const operators = [];
    const PRODUCT_AVAILABILITY = "product_product_availability";

    if (filter?.name) {
        operators.push(
            operator(
                { val: filter.name, table: PRODUCT_AVAILABILITY, dbField: "application_name" }
            ).LIKE
        );
    }

    if (filter?.validity?.from && filter?.validity?.from !== '') {
        operators.push(
            operator(
                { val: filter.validity.from, table: PRODUCT_AVAILABILITY, dbField: "validity_from" }
            ).GREATER_THAN_EQUAL
        );
    }

    if (filter?.validity?.until && filter?.validity?.until !== '') {
        operators.push(
            operator(
                { val: filter.validity.until, table: PRODUCT_AVAILABILITY, dbField: "validity_until" }
            ).LESS_THAN_EQUAL
        );
    }

    if (filter?.context) {
        operators.push(
            operator(
                { val: filter.context, table: PRODUCT_AVAILABILITY, dbField: "context" }
            ).LIKE
        );
    }

    // if (filter?.context !== undefined) {
    //     operators.push(getOperator(PRODUCT_AVAILABILITY, "context", "EQUAL", globalConfig.formatters.getEqualValue(filter.context ), "context"))
    // }

    setOtherFilters(operators, qc_header);

    return await api().get(settings.api_query_url, {
        headers: headers({
            qc: JSON.stringify(qc_header.qc)
        })
    });
}

export const availabilityKeys: string[] = [
    'category',
    'class',
    'priority'
]

export const outputFormAdvanceConfiguration: any = {
    category: ["category"],
    class: ["class"],
    priority: ["priority"]
}
