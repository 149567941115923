import {Module} from "vuex";
import {OrderFinancing, OrderFinancingState} from "@/types";
import {getOrderFinancing} from "@/store/services/order/financing/orderFinancingService";


const orderFinancingModule: Module<OrderFinancingState, any> = {
    namespaced: true,
    state: {
         financing: {
             type: {
                 label: '',
                 value: ''
             },
             label: '',
             offerReference: '',
             scale: '',
             financingProductPack: {},
             financingOrganization:{},
             associatedParty: {
                 orderParty: {
                     party: {},
                     role: {},
                     third: {}
                }
             },
             financedAmountWTax: {
                 amount: 0,
                 currency: 'EUR'
             },
             financedAmountWoTax: {
                 amount: 0,
                 currency: 'EUR'
             },
             contribution: {
                 amount: 0,
                 currency: 'EUR'
             },
             firstRent: {
                 amount: 0,
                 currency: 'EUR'
             },
             numberOfInsurances: undefined,
         },
    },
    getters:{
        getOrderFinancingInit(): OrderFinancing {
            return {
                type: {
                    label: '',
                    value: ''
                },
                label: '',
                offerReference: '',
                scale: '',
                financingProductPack: {},
                financingOrganization:{},
                associatedParty: {
                    orderParty: {
                        party: {},
                        role: {},
                        third: {}
                    }
                },
                financedAmountWTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                financedAmountWoTax: {
                    amount: 0,
                    currency: 'EUR'
                },
                contribution: {
                    amount: 0,
                    currency: 'EUR'
                },
                firstRent: {
                    amount: 0,
                    currency: 'EUR'
                },
                numberOfInsurances: undefined,
            }
        },
        getOrderFinancing(state) {
            return state.financing;
        },
    },
    actions: {
        async getFinancing({commit, getters}, payload) {
            const {resourceUid, callback} = payload
            await getOrderFinancing(resourceUid).then((response: any) => {
                if (response) {
                    commit('setFinancing', response)
                    callback(response);
                } else {
                    commit('setFinancing', getters.getOrderFinancingInit)
                }
            })
        },
        setOrderAssociatedParty({ commit }, payload) {
            commit('setOrderAssociatedParty', payload)
        },
        setFinancingLabel({ commit }, payload) {
            commit('setFinancingLabel', payload)
        },
        cleanState({ commit }) {
            commit('setInitial')

        }
    },
    mutations: {
        setInitial(state) {
            state.financing = {}
        },
        setFinancing(state, payload) {
            state.financing = payload
        },
        setOrderAssociatedParty(state, payload){
            const {party, role, objectType} = payload
            if(state.financing && state.financing.associatedParty
                    && state.financing.associatedParty.orderParty
                    && state.financing.associatedParty.orderParty.party
                    && state.financing.associatedParty.orderParty.role) {
                        console.log(state.financing)
                state.financing.associatedParty.orderParty.party.resourceUid = party
                state.financing.associatedParty.orderParty.party.objectType = objectType
                state.financing.associatedParty.orderParty.role.resourceUid = role
            }
        },
        setFinancingLabel(state, payload){
            if(state.financing){
                state.financing.label = payload.value
            }
        },
    }
}

export default orderFinancingModule;
