
import store from "@/store";
import {Options, Vue} from "vue-class-component";
import {mapActions} from "vuex";
import {Masks, useDate} from "@/utils";
import i18n from "@/i18n";

@Options({
  props: ["entityState"],
  computed: {
    Masks() {
      return Masks
    },
    useDate() {
      return useDate
    }
  },
  methods: {
    ...mapActions(
        {
          callHistoricMessages: "messagingModule/historicMessages"
        }
    )
  },
  /**
   * Fetches historic messages when the component is created.
   *
   * @function
   * @name created
   */
  created() {
    this.callHistoricMessages({
      type: "MESSAGE",
      entityId: this.entityState.reference,
    })
  }
})
export default class SummaryMessage extends Vue {

  showComment?: boolean = false;
  showMessage = true;
  activeSubjectUid = null;

  /**
   * Gets historic messages from the store.
   *
   * @function
   * @name historicMessages
   * @returns any[] The historic messages.
   */
  get historicMessages(): any[] {
    return store.getters["messagingModule/getHistoricMessages"].sort((a:any, b:any) => {
      return new Date(b.messagePack[0].date).getTime() - new Date(a.messagePack[0].date).getTime();
    });
  }

  /**
   * Toggles the display of message details for a group.
   *
   * @function
   * @name toggleList
   * @param {any} groupIndex - The index of the message group to toggle.
   */
  toggleList(groupIndex: any) {
    this.activeSubjectUid =
      this.activeSubjectUid === groupIndex ? null : groupIndex;
    this.showMessage = false;
  }

  /**
   * Toggles the display of comments for a message.
   *
   * @function
   * @name toggleComment
   * @param {any} item - The message item to toggle comments for.
   */

  toggleComment(item: any) {
    item.showComment = !item.showComment;
  }

  /**
   * Truncates text if it exceeds a certain length.
   *
   * @function
   * @name truncatedText
   * @param {string} text - The text to truncate.
   * @returns {string} The truncated text.
   */

  truncatedText(text: string): string {
    if (text.length <= 100) {
      return text;
    }
    return text.slice(0, 100) + (this.showComment ? "" : "...");
  }

  /**
   * Checks if a "Show More" button should be displayed for a message.
   *
   * @function
   * @name shouldDisplayShowCommentButton
   * @param {any} item - The message item to check.
   * @returns {boolean} `true` if the button should be displayed; otherwise, `false`.
   */
  shouldDisplayShowCommentButton(item: any): boolean {
    return item.body.length > 100;
  }

  /**
   * Gets the count of message details for a group.
   *
   * @function
   * @name toggleCountMessage
   * @returns {string} The count of message details.
   * @param groupMessages
   */
  toggleCountMessage(groupMessages: any): string {
    return groupMessages.length;
  }

  /**
   * Checks if a message group should have a color change.
   *
   * @function
   * @name shouldChangeColor
   * @param {any} groupMessage - The object of the message group to check.
   * @returns {boolean} `true` if the color should change; otherwise, `false`.
   */
  shouldChangeColor(groupMessage: any): boolean {
    return groupMessage.status === "NEW";
  }

  /**
   * set the correct group name
   * @param code of the group
   * @returns string for the group name
   */
  setGroupName(code: string): string {
    const groupName = `${i18n.global.t('middleOffice.decision.profil.'.concat(code))}`;
    return groupName.includes('middleOffice') ? code : groupName;
  }

}
