
import { Vue } from "vue-class-component";
import { buildSupersetConfiguration, fetchGuestToken } from "@/store/services/dashboard";
import Notify from 'quasar/src/plugins/Notify.js';;
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { supersetConfiguration } from "@/utils/configuration/superset-config";
import {settings} from "@/settings";

export default class Home extends Vue {
  name = "Home-Teamwill";

  created() {
    setTimeout(async () => {
      const dynamicConfig = await buildSupersetConfiguration();
      try {
        const element = document.getElementById("superset-dashboard");
        if (element) {
          await embedDashboard({
            id: dynamicConfig.guestConfig.resources[0].id,
            supersetDomain: settings.supersetBasePath, //Use the base path from /settings/intex.ts file
            mountPoint: element,
            fetchGuestToken: () => fetchGuestToken(dynamicConfig),
            dashboardUiConfig: supersetConfiguration.dashboardUiConfig,
          });
        }
      } catch (e) {
        console.error(e);
        Notify.create({
            timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
             message: "Error initializing the dashboard",
             color: "negative",
        });
      }
    }, 100);
  }
}
