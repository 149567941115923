import { Module } from "vuex";
import {
  Analysis,
  AnalysisProcessStatusEnum,
  AnalysisSectionDefinition,
  AnalysisState,
  AnswerOption,
  Question,
  RootState
} from "@/types";
import {
  decisionMock,
  getAnalysis,
  getAnalysisAlgorithmeData,
  getAnalysisEvaluatedReserves,
  getAssociatedAnalysisProcess,
  getQuestionsByProcessId,
  getRatiosByProcessId,
  getScoreAnalysis,
  UpdateAssociatedAnalysisProcess,
  UpdateQuestionnaireGathering, validDocAnalysesProcess, validDocuments
} from "@/store/services/task/actions/analysisService"
import store from "@/store";
import { getGhatheringProcessItem, getHistoryRating, groupAndSortRatings, updateAnalysisFromTask } from '@/store/services/task/taskService';
import Notify from 'quasar/src/plugins/Notify.js';;

import {
  convertRatiodataToRatiovalue,
  deepClone,
  generateQuestionsByCategory,
  generateRatiosByCategory,
  getAnalysesDataRequest,
  initanalysisData
} from '@/utils';
import { faL } from "@fortawesome/free-solid-svg-icons";
import i18n from "@/i18n";
import { sendMessage } from "@/store/services/task/workflow";

// Function to find items with associated analysis process having resourceUid "65b38958c97daf7ef1474fd9"
function findItemsWithAnalysisProcessUid(decisionSteps: any, analysisProcessUid: string): any {
  const foundItems: any = [];

  for (const decisionStep of decisionSteps) {
    const associatedProcesses = decisionStep.associatedAnalysisProcess;
    for (const process of associatedProcesses) {
      if (process.analysisProcess.resourceUid === analysisProcessUid) {
        foundItems.push(decisionStep);
        break;
      }
    }
  }

  return foundItems;
}

const analysisModule: Module<AnalysisState, RootState> = {
  namespaced: true,
  state: {
    AlgorithmeChanged: false,
    questionnaire: {},
    questionnairegatheringItem: {},
    gatheringQuestions: [],
    selectedAnswersOptions: {},
    ratiosByCategories: {},
    analysis: {
      resourceUid: "",
      objectType: "odm.analysis.process",
      systemUid: "odm-analysis",
      businessData: null,
      daaq: "/",
      reference: "",
      owner: {
        objectType: "odm.user.leaseforgeuser",
        systemUid: "odm-user",
        resourceUid: ""
      },
      analysisEntity: {
        objectType: "odm.decision.decisionprocess",
        systemUid: "odm-decision",
        resourceUid: ""
      },
      analysisData: "",
      analysisScore: {
        resourceUid: "",
        objectType: "odm.analysis.analysisscore",
        systemUid: "odm-analysis"
      },
      status: {
        internalCode: "" as AnalysisProcessStatusEnum,
        defaultValue: true
      },
      assessment: {
        objectType: "odm.analysis.assessment",
        systemUid: "odm-analysis",
        resourceUid: ""
      },
      ratios: [],// in progress
      analysisRatings: [],// in progress
      historyRating: [],
      score: {},
      opinionQualifications: [{
        opinionreserves: [],
        opinion: {
          resourceUid: null
        },
        comment: "",
        opinionQualifications: []
      }]
    },
    reserves: [],
    evaluatedReserves: [],
    associatedAnalysisProcess: {},
    errors:[],
  },
  getters: {

    getOpinionQualifications(state) {
      return state.analysis?.opinionQualifications.length > 0 ? state.analysis?.opinionQualifications[0].opinionQualifications : []
    },


    getOpinion(state) {
      return state.analysis?.opinionQualifications[0].opinion
    },
    getAnalysisStatus(state) {
      return state.analysis?.status.resourceUid
    },
    analysisSections() {
      return [
        AnalysisSectionDefinition.PRT_IDT,
        AnalysisSectionDefinition.PRT_STU,
        AnalysisSectionDefinition.PRT_PROF,
        AnalysisSectionDefinition.PRT_STF,
        AnalysisSectionDefinition.AST_01,
        AnalysisSectionDefinition.FIN_01,
        AnalysisSectionDefinition.DOC_01
      ]
    },
    getAnalysisResourceUid(state) {
      return state.analysis?.resourceUid
    }
  },
  actions: {

    getHistoryRating({ commit }, payload) {
      const { filter, callback } = payload
      getHistoryRating(filter)
        .then(res => {
          callback(res)
        })
        .catch(e => console.error(e))
    },

    async analysis({ commit }, payload) {
      store.state.analysisModule!.isLoading = true;
      let selectedAnswerOptions: Record<string, AnswerOption> = {};
      const resourceUid = payload.data;
      const isEditing = payload.isEditing;
      const reCalculate = payload.reCalculate;
      let gatheringProcessId = "";
      let ghatheringProcessItem: any = [];
      let analysisRequest: Analysis;
      const contextModule = payload.route && payload.route._value.path.includes("/mo/") ? (store.state.middleOfficeModule.offer as any) : (store.state.taskModule.offer as any);

      if (!reCalculate) {
        console.log('recalculate no')
        try {

          contextModule.associatedGatheringProcess.forEach(async (associatedGatheringProcess: any) => {
            const res: any = await getGhatheringProcessItem(associatedGatheringProcess.gatheringProcess.resourceUid)
            if (res.contextType.rawValue === "QUESTIONNAIRE") {
              gatheringProcessId = associatedGatheringProcess.gatheringProcess.resourceUid;
              ghatheringProcessItem = res.gatheringProcessItem.filter(
                (item: any) => item.objectType.includes('questionnairegathering')
              )

              if (ghatheringProcessItem && ghatheringProcessItem.length !== 0) {
                const processQuestions = await getQuestionsByProcessId(gatheringProcessId);
                const processQuestionsByCatgory = generateQuestionsByCategory(processQuestions);
                commit('setQueastionnaire', processQuestionsByCatgory)
                commit('setGatheringProcessItem', {
                  "objectType": "odm.supportingdocument.gatheringprocessitem.questionnairegathering",
                  "resourceUid": ghatheringProcessItem[0].resourceUid,
                })
                commit('setAnalysisQuestions', ghatheringProcessItem[0].questions)


                // Function to find the matching question
                const findMatchingQuestion = (categoryId: string, questionId: string) => {
                  const category = processQuestionsByCatgory[categoryId];
                  if (category) {
                    for (const key in category) {
                      const question = category[key];
                      if (question.question_id === questionId) {
                        return question;
                      }
                    }
                  }
                  return null;
                };

                for (const questionData of ghatheringProcessItem[0].questions) {
                  const questionId = questionData.question.resourceUid;
                  const answerId = questionData.answer.resourceUid;

                  // Iterate through categories in the second JSON
                  for (const categoryKey in processQuestionsByCatgory) {
                    const category = processQuestionsByCatgory[categoryKey];

                    // Find the corresponding question in the second JSON
                    const questionInfo = findMatchingQuestion(categoryKey, questionId);

                    if (questionInfo) {
                      // Find the selected answer in the second JSON
                      const answerInfo = questionInfo.answerOptions.find((opt: any) => opt.answer_option_id === answerId);

                      if (answerInfo) {
                        // Store the selected answer option
                        selectedAnswerOptions[questionInfo.question_i18n_code] = answerInfo;
                        break; // Break the loop once the question is found in the current category
                      }
                    }
                  }
                }


              }

              else {
                selectedAnswerOptions = {}
              }


              commit('setAnalysisQuestionsAnswers', selectedAnswerOptions)

            }

          });
        } catch (error) {
          console.error(error);
        }

      }
      store.state.analysisModule!.AlgorithmeChanged = false
      const analysisData = await initanalysisData(contextModule, {})
      // Fetch analysis asynchronously
      const res = await getAnalysis(resourceUid);

      // const decisionEntityRessourceUid = payload.route && payload.route._value.path.includes("/mo/") ? store.state.middleOfficeModule.decisionStep?.find(item => {
      //   return item.associatedAnalysisProcess
      // }) : (store.state.taskModule.offer as any);

      const result = deepClone(res)
      analysisRequest = {
        ...res,
        historyRating: [],
        analysisScore: {
          resourceUid: "",
          objectType: "odm.analysis.analysisscore",
          systemUid: "odm-analysis"
        },
        score: {}
      }
      try {
        const ratiosByCatgories = await getRatiosByProcessId(resourceUid)
        commit('setRatiosByProcessId', generateRatiosByCategory(ratiosByCatgories))

      

        
     
        console.log('questionnaire', store.state.analysisModule?.gatheringQuestions)
        const calculateRatio = !isEditing ? await getAnalysisAlgorithmeData(resourceUid, getAnalysesDataRequest(await initanalysisData(contextModule, { questionnaire: JSON.stringify(store.state.analysisModule?.gatheringQuestions) })), "calculateRatio") : result;
        const evaluateRatio = !isEditing ? await getAnalysisAlgorithmeData(resourceUid, getAnalysesDataRequest(await initanalysisData(contextModule, { questionnaire: JSON.stringify(store.state.analysisModule?.gatheringQuestions) })), "evaluateRatio") : result
        const evaluateRating = !isEditing ? await getAnalysisAlgorithmeData(resourceUid, getAnalysesDataRequest(await initanalysisData(contextModule, { ratiosData: JSON.stringify(evaluateRatio.ratios), questionnaire: JSON.stringify(store.state.analysisModule?.gatheringQuestions) })), "evaluateRating") : result
        const filtredEvaluatedRating = !isEditing ? evaluateRating.ratings : evaluateRating.analysisRatings.filter((rating: any) => !rating.validity || !rating.validity.until)
        const evaluateScore = !isEditing ? await getAnalysisAlgorithmeData(resourceUid, getAnalysesDataRequest(await initanalysisData(contextModule, { ratingsData: JSON.stringify(evaluateRating.ratings), questionnaire: JSON.stringify(store.state.analysisModule?.gatheringQuestions) })), "evaluateScore") : result
        const score = await getScoreAnalysis(evaluateScore.analysisScore.resourceUid)
        analysisRequest = {
          ...res,
          analysisData: analysisData,
          ratios: convertRatiodataToRatiovalue(calculateRatio.ratios),
          historyRating: groupAndSortRatings(evaluateRating.analysisRatings),
          analysisRatings: filtredEvaluatedRating,
          analysisScore: evaluateScore.analysisScore,
          score: score
        }

        const analysisCamundaVariable = {
          evaluateRatios: evaluateRatio.ratios,
          evaluateRatings: filtredEvaluatedRating,
          evaluateScore: score
        }
        commit("setAnalysisCamundaVaiable", analysisCamundaVariable)




      }
      catch (error) {
        store.state.analysisModule!.errors.algo = "Analysis.errors.algo";
        console.error(error);
      }
      finally {
        store.state.analysisModule!.isLoading = false
        console.log('testData--------',analysisRequest)
        commit("setAnalysis", analysisRequest)
        //get reserves evaluation
        const analysisEvaluatedReserves = await getAnalysisEvaluatedReserves({ data: analysisData })
        commit("setEvaluatedReserves", analysisEvaluatedReserves.map((elem: any) => elem = { ...elem, comment: "" }))
        const AssociatedAnalysisProcessList = payload.route && payload.route._value.path.includes("/mo/") ? findItemsWithAnalysisProcessUid(store.state.middleOfficeModule.decisionStep, resourceUid)[0] : await getAssociatedAnalysisProcess(result.analysisEntity.resourceUid);


        // const AssociatedAnalysisProcess = AssociatedAnalysisProcessList.filter((item: any) => item.analysisProcess.resourceUid === resourceUid)
        const AssociatedAnalysisProcess = AssociatedAnalysisProcessList.associatedAnalysisProcess.filter((item: any) => item.analysisProcess.resourceUid === resourceUid)
        commit("setAssociatedAnalysisProcess", AssociatedAnalysisProcess[0])

        const payloadRequest: any = AssociatedAnalysisProcess[0]?.reserves.map((elem: any) => {
          const reserveName = store.state.analysisModule?.evaluatedReserves?.find(reserve => reserve.resourceUid === elem.reserve.resourceUid).reserveName
          let request = {
            businessData: null,
            comment: elem.comment,
            daaq: null,
            objectType: "odm.decision.reservedata",
            reserveName: reserveName,
            resourceUid: elem.reserve.resourceUid,
            systemUid: "odm-decision",
          }
          return request
        })
        commit('setAnalysisReserves', payloadRequest)
      }

    },



    updateAnalysisReserves({ commit }, payload) {
      commit('setAnalysisReserves', payload)
    },
    updateAnalysisState({ commit }, payload) {
      const start = AnalysisProcessStatusEnum.START
      const hold = AnalysisProcessStatusEnum.ONHOLD
      if (this.state?.analysisModule?.analysis?.status.resourceUid) {
        console.log("this.state.analysisModule.analysis.status.resourceUid ",this.state.analysisModule.analysis.status.resourceUid)
        this.state.analysisModule.analysis.status.resourceUid = payload;
        if (payload === AnalysisProcessStatusEnum.IN_PROGRESS ){store.dispatch("taskModule/setTaskStatus", start)}
        else if (payload === AnalysisProcessStatusEnum.ON_HOLD ){store.dispatch("taskModule/setTaskStatus", hold)}
        
      }
      this.dispatch("analysisModule/updateAnalysis")
    },
    updateAnalysis({ commit }) {
      
      const analysis = this.state.analysisModule?.analysis!
      const gatheringQuestionsState = store.state.analysisModule?.gatheringQuestions
      const answersState = store.state.analysisModule?.selectedAnswersOptions
      const updatedAnswers: Question[] = gatheringQuestionsState!.map((item) => {
        const questionId = item.question.resourceUid;
        const answerData: any = Object.values(answersState as any).find((data: any) => data.question_id === questionId);

        if (answerData) {
          // Update the answer resourceUid
          item.answer.resourceUid = answerData.answer_option_id;
        }
        return item;
      });

      const getAssociatedAnalysisProcessRequest = () => {
        const reserves = deepClone(store.state.analysisModule?.associatedAnalysisProcess.reserves)
        const selectedReserves = store.state.analysisModule?.reserves

        let request: any = [];
        selectedReserves?.forEach((elem: any) => {
          let resultReserve = reserves.find((el: any) => el.reserve.resourceUid === elem.resourceUid)

          if (resultReserve) {
            resultReserve.reserve.resourceUid = elem.resourceUid;
            resultReserve.comment = elem.comment ? elem.comment : "";
          }
          else {
            resultReserve = {
              "objectType": "odm.decision.elementreserve.analysisreserve",
              "systemUid": "odm-decision",
              "businessData": null,
              "daaq": "/",
              "comment": elem.comment,
              "reserve": {
                "objectType": "odm.decision.reserve",
                "systemUid": "odm-decision",
                "resourceUid": elem.resourceUid
              }
            }
          }

          request.push(resultReserve)


        });

        return {
          ...store.state.analysisModule?.associatedAnalysisProcess,
          reserves: request
        }

      };
      UpdateAssociatedAnalysisProcess(getAssociatedAnalysisProcessRequest())
        .then(response => {
          if (Object.keys(store.state.analysisModule?.questionnairegatheringItem || {}).length === 0) {
            // Object is empty
            updateAnalysisFromTask(analysis, store.state.demandModule.offer?.resourceUid)
              .then(response => {

                this.dispatch('analysisModule/analysis', { data: response.resourceUid, isEditing: true, reCalculate: false });

              })
              .catch(err => {
                console.error(err)
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                  message: err,
                  color: 'negative'
                });
              })


          } else {
            // Object is not empty

            UpdateQuestionnaireGathering(updatedAnswers, store.state.analysisModule?.questionnairegatheringItem)
              .then(response => {
                updateAnalysisFromTask(analysis, store.state.demandModule.offer?.resourceUid)
                  .then(response => {

                    this.dispatch('analysisModule/analysis', { data: response.resourceUid, isEditing: true, reCalculate: false });

                  })
                  .catch(err => {
                    console.error(err)
                    Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                      message: err,
                      color: 'negative'
                    });
                  })
              })
              .catch(err => {
                console.error(err)
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                  message: err,
                  color: 'negative'
                });
              })
          }

        }).catch(err => {
          console.error(err)
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: err,
            color: 'negative'
          });
        })







    },
    setInitialOpinionQualificationsState({ commit }, payload) {
      commit("setInitialOpinionQualificationsState", payload)
    },

    setAnalysis({ commit }, payload) {

      commit("setAnalysis", payload)
    },
    cleanState({ commit }) {
      commit("setInitial");
    },
    initAnalysisState({ commit }) {
      commit("initAnalysisState")
    },
    listDecisioMock(context, payload) {
      const { callback } = payload
      decisionMock()
        .then(response => {
          callback(response)
        })
        .catch(err => console.error(err));
    },
    setOpinionReserves({ commit }, payload) {
      commit('setOpinionReserves', payload)
    },

    validDocuments({ commit }, payload) {
      validDocAnalysesProcess().catch(() => console.error(i18n.global.t("demand.documents.valid")))
    },
    sendMessage({ commit }, payload) {
      const { messageTosend,resourceUid,callback} = payload
      store.state.analysisModule!.isLoading = true;
      sendMessage(messageTosend)
        .then(async res => {
          const analysis = await getAnalysis(resourceUid);
          if (this.state?.analysisModule?.analysis?.status.resourceUid) {
            this.state.analysisModule.analysis.status.resourceUid = analysis.status.resourceUid;
          }
          store.state.analysisModule!.isLoading = false;
          callback(res);
        })
        .catch(e => console.error(e))
    }
  },
  mutations: {

    setInitial(state) {
      //       state.analysis = {
      // };

    },
    setRatiosByProcessId(state, payload) {
      state.ratiosByCategories = payload
    },
    setAnalysisReserves(state, payload) {
      state.reserves = payload
    },
    setEvaluatedReserves(state, payload) {
      state.evaluatedReserves = payload
    },
    setGatheringProcessItem(state, payload) {
      state.questionnairegatheringItem = payload
    },
    setAnalysisQuestions(state, payload) {
      state.gatheringQuestions = payload
    },
    setQueastionnaire(state, payload) {
      state.questionnaire = payload
    },
    setAnalysisQuestionsAnswers(state, payload) {
      state.selectedAnswersOptions = payload
      state.AlgorithmeChanged = false
    },
    setAnalysisCamundaVaiable(state,payload) {
      state.analysisDataInfo = payload
    },
    setAnalysis(state, payload) {
      const opinionQualifications = deepClone(store.state.analysisModule!.analysis!.opinionQualifications)
      state.analysis = payload
      if (state?.analysis?.status?.resourceUid === "CREATED" && state?.analysis?.opinionQualifications.length === 0) {
        state.analysis.opinionQualifications = [{
          opinion: {

            resourceUid: null
          },
          comment: "",
          opinionQualifications: []
        }

        ];

        const sectionsArray = store.getters["taskModule/analysisModule/analysisSections"];
        sectionsArray.forEach((element: any) => {
          const OpinionQualification = {
            comment: "",
            qualificationType: {
              resourceUid: element
            }
          }
          state.analysis?.opinionQualifications[0].opinionQualifications.push(
            OpinionQualification
          )
        });

      }
      else if (state?.analysis?.opinionQualifications.length > 0)
      {
        opinionQualifications[0].opinionQualifications  = state.analysis?.opinionQualifications
        state.analysis!.opinionQualifications=opinionQualifications
      }
    },

    // opinionMaker:store.state.authModule?.user.userId

    setComment(state, { index, text }) {
      state.analysis!.opinionQualifications[0].opinionQualifications[index].comment = text
    },
    setGlobaleOpinion(state, { resourceUid }) {

      state.analysis!.opinionQualifications[0].opinion = { resourceUid }
    },
    setGlobaleComment(state, payload) {

      state.analysis!.opinionQualifications[0].comment = payload
    },
    initAnalysisState(state) {
      state.questionnaire = {}
      state.questionnairegatheringItem = {}
      state.gatheringQuestions = []
      state.selectedAnswersOptions = {}
      state.analysis = {
        resourceUid: "",
        objectType: "odm.analysis.process",
        systemUid: "odm-analysis",
        businessData: null,
        daaq: "/",
        reference: "",
        owner: {
          objectType: "odm.user.leaseforgeuser",
          systemUid: "odm-user",
          resourceUid: ""
        },
        analysisEntity: {
          objectType: "odm.decision.decisionprocess",
          systemUid: "odm-decision",
          resourceUid: ""
        },
        analysisData: "",
        analysisScore: {
          resourceUid: "",
          objectType: "odm.analysis.analysisscore",
          systemUid: "odm-analysis"
        },
        status: {
          internalCode: "" as AnalysisProcessStatusEnum,
          defaultValue: true
        },
        assessment: {
          objectType: "odm.analysis.assessment",
          systemUid: "odm-analysis",
          resourceUid: ""
        },
        ratios: [],// in progress
        analysisRatings: [],// in progress
        historyRating: [],
        score: {},
        opinionQualifications: [{

          opinion: {
            resourceUid: null
          },
          comment: "",
          opinionQualifications: []
        }]
      }
      state.reserves = []
      state.evaluatedReserves = []
      state.associatedAnalysisProcess = {}
    },

    setAssociatedAnalysisProcess(state, payload) {
      state.associatedAnalysisProcess = payload
    },
    setOpinionReserves(state, payload) {
      state.opinionreserves = payload
    },
    setRatingHistory(state, payload) {
      if (state.analysis)
        state.analysis.historyRating = payload
    }

  },
};

export default analysisModule;
