import {settings} from "@/settings";
import {api} from "@/auth/api";
import {MessageToSend, ProcessInitialisationContext, TaskActionEvent, TaskExecuteAction, TaskSearchCriteria} from "@/types";
import {variablesWorkflowOrder} from "@/utils/configuration/table-config";

const odm_workflow = `odm-workflow`
const baseUrl = `${settings.api_url}/${odm_workflow}/api/1/${odm_workflow}`

export async function getTaskListByUser(taskSearchCriteria: TaskSearchCriteria, offset?: any, limit?: any) {
    if (taskSearchCriteria && !taskSearchCriteria.initializeVariables) {
        taskSearchCriteria.includeAssignedTasks = true
        taskSearchCriteria.initializeVariables = variablesWorkflowOrder + "offerId;offerStatus;offerReference;customerName;_offerDecisionProcessId;_clientDecisionProcessId;associated_networks_id;customerRessourceUid;customerBirthDate;offerCreationDate;associated_network_nodes_id;customerType;offerPhase;customerReference;applicationName;analysisProcessId;_decisionAnalysisProcessId;additional_action_items;SignatureMethod";
    }
    taskSearchCriteria.offset = offset !== null && offset !== undefined ? offset : 0;
    taskSearchCriteria.limit = limit !== null && offset !== undefined ? limit : 10;

    return api().post(`${baseUrl}/task/`, taskSearchCriteria)
}
export async function initWorkflowProcess(processInitialisationContext: ProcessInitialisationContext) {
    return api().post(`${baseUrl}/process/init/`, processInitialisationContext)
}
export async function claimTask(taskActionEvent: TaskActionEvent) {
    return api().post(`${baseUrl}/task/claim/`, taskActionEvent)
}
export async function unClaimTask(taskActionEvent: TaskActionEvent) {
    return api().post(`${baseUrl}/task/unClaim/`, taskActionEvent)
}
export async function assignTask(taskActionEvent: TaskActionEvent) {
    return api().post(`${baseUrl}/task/assign/`, taskActionEvent)
}
export async function executeTask(taskExecuteAction: TaskExecuteAction) {
    if (taskExecuteAction.variables) {
        taskExecuteAction.variables = JSON.stringify(taskExecuteAction.variables)
    }
    console.log('sendVariables-------------', taskExecuteAction.variables)
    return api().post(`${baseUrl}/task/execute/`, taskExecuteAction)
}
export async function delegateTask(taskActionEvent: TaskActionEvent) {
    return api().post(`${baseUrl}/task/delegate`, taskActionEvent)
}
export async function getTaskHistoryByEntity(taskSearchCriteria: TaskSearchCriteria) {
    if (taskSearchCriteria && !taskSearchCriteria.initializeVariables) {
        taskSearchCriteria.initializeVariables = "offerId;offerStatus;offerReference;customerName;offer;comment";
    }
    return api().post(`${baseUrl}/history/task/`, taskSearchCriteria)
}
export async function getTaskUserAction(taskId: string) {
    return api().get(`${baseUrl}/history/task/${taskId}/`)
}

export async function getHistoryActivityByEntity(taskSearchCriteria: TaskSearchCriteria) {
    if (taskSearchCriteria && !taskSearchCriteria.initializeVariables) {
        taskSearchCriteria.initializeVariables = "orderReference;orderStatus;customerType;customerName;initialDeliveryDate;provisionalDeliveryDate";
    }
    return api().post(`${baseUrl}/history/activity/`, taskSearchCriteria)
}

export async function sendMessage(messageToSend: MessageToSend) {
    return api().post(`${baseUrl}/message/`, messageToSend)
}

export async function getTaskCountByUser(taskSearchCriteria: TaskSearchCriteria) {
    return api().post(`${baseUrl}/taskCount/`, taskSearchCriteria)
}
