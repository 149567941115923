
import {Options, Vue} from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import {formatWithMask} from "@/utils/configuration/formatters-config";
import store from "@/store";
import {globalConfig} from "@/utils";
import { processDocumentsOrder } from "@/commons/gathering-process-order";

@Options({
  methods: {formatWithMask},
  components: {LabelAndValue},
  props: [""],
})
export default class OrderSummaryPreConditions extends Vue {
  capitalize = globalConfig.capitalize;
  orderCheckPoints: any[] = [];
  ListGatheringProcessAssociated: any[] = [];


  setStatusBadgeClass(value: string) {
    switch (value) {
      case 'COMPLETED':
        return "badge badge-primary";
      case 'QUALIFIED':
        return "badge badge-primary";
      case 'PENDING':
        return "badge badge-danger";
      default:
        return "";
    }

  }

  get getGatheringProcess() {
    return store.state.summaryPanelModule.gatheringProcessOrder;
  }

  async orderProcess(ListGatheringProcessAssociated : any) {
    this.ListGatheringProcessAssociated = ListGatheringProcessAssociated ;
    console.log(this.ListGatheringProcessAssociated)
    for (const GatheringProcess of this.ListGatheringProcessAssociated) {
      await store.dispatch("taskModule/uploadDocumentModule/getRequiredDocument", {
        request: {
          resourceUid: GatheringProcess.gatheringProcess.resourceUid,
        },
        callback: (res: any) => {
          const requiredDocuments=res
          store.dispatch("taskModule/uploadDocumentModule/getProcess", {
            request: {
              resourceUid: GatheringProcess.gatheringProcess.resourceUid,
            },
            callback: (res: any) => processDocumentsOrder({
              gatheringProcess: res,
              requiredDocuments: requiredDocuments,
              supportingDocuments: res.supportingDocuments,
              receivedFiles: res.gatheringProcessItem[0].receivedFiles,
              module:"summaryPanelModule",
            })
          });
        },
      });
    }
  }

  created() {
    store.dispatch("summaryPanelModule/gatheringProcessOrder", {
            request: {
                gatheringProcess: store.state.taskModule.gatheringProcessOrder ||[],
            },
        })
    store.dispatch("taskModule/getOrderProcess", {
      callback: this.orderProcess,
      request: {
        variable: store.state.summaryPanelModule.order?.resourceUid,
      },
    });
  }

}
