import { Module } from "vuex";
import { Availability, AvailabilityState, ScaleBuisinessRuleSection } from "@/types";
import {
    availabilityFilter,
    getAvailability,
    saveAvailability
} from "@/store/services/configuration/AvailabilityService";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import { deepClone } from "@/utils";
import store from "@/store";

const getStartAvailability = (): Availability => {
    return {
        systemUid: "odm-product",
        objectType: 'odm.product.productavailability',
        daaq: '',
        validity: {
            from: '',
            until: ''
        },
        context: undefined,
        applicationName: '',
        availableCategories: {
            category: '',
            class: '',
            priority: undefined,
            advancedConfigurationToggle: true
        }
    }
}

const availabilityModule: Module<AvailabilityState, unknown> = {
    namespaced: true,
    state: {
        isLoading: false,
        openAvailability: false,
        availability: {
            systemUid: "odm-product",
            objectType: 'odm.product.productavailability',
            daaq: '',
            validity: {
                from: '',
                until: ''
            },
            context: undefined,
            applicationName: '',
            availableCategories: {
                category: '',
                class: '',
                priority: undefined,
                advancedConfigurationToggle: true
            }
        },
    },

    getters: {
        getAvailabilityObject: (state): Availability | undefined => {
            return state.availability;
        },
    },

    actions: {
        searchAvailability(context, payload) {
            const { filter, offset, limit,callback } = payload
            availabilityFilter(filter,offset,limit)
                .then(response => {
                    callback(response)
                })
                .catch(err => console.error(err));
        },

        showAvailabilityDetail({ commit }) {
            commit("showAvailabilityDetail");
            commit('startAvailability')
        },

        saveAvailability(context) {
            if (context.state.availability) {
                context.commit("toggleIsLoading");
                saveAvailability(deepClone(context.state.availability)).then(res => {
                    Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                        message: i18n.global.t('main.dialog.success', { entity: res.resourceUid }),
                        color: 'positive'
                    })
                    context.commit("toggleIsLoading")
                    store.dispatch("releaseLockState")

                }).catch(err => {
                    context.commit("toggleIsLoading")
                    console.error(err)
                    Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                        message: `Error saving third \n ${err}`,
                        color: 'negative'
                    });
                })
            }

        },

        getAvailability({ commit }, payload) {
            const { id, callback } = payload;
            getAvailability(id).then(res => {
                commit('setAvailability', res);
                if (callback) {
                    callback(res)
                }
            }).catch(err => {
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                    message: `Error: \n ${err}`,
                    color: 'negative'
                });
            })
        },

        showAvailabilityDetailEdit({ commit }) {
            commit("showAvailabilityDetail");
        },

        cleanState({ commit }) {
            commit('cleanAvailability')
        },
    },

    mutations: {
        toggleIsLoading(state) {
            state.isLoading = !state.isLoading
        },
        showAvailabilityDetail(state) {
            state.openAvailability = !state.openAvailability;
        },

        startAvailability(state) {
            state.availability = getStartAvailability()
        },

        setAvailability(state, payload) {
            if ((store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.AVAILABILITY as any] as any).selectedBuisinessRule?.returnMultipleResults) {
                state.availability = payload;
            }
        },

        cleanAvailability(state) {
            state.isLoading = false
            state.availability = getStartAvailability()
        },
    }
}

export default availabilityModule;
