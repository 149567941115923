import { deleteUserDashbord, fetchAllDashboard, getAllUsersByDashbordId, saveNewExternalDashboard } from "@/store/services/dashboard/dashboardService";
import { DashboardState } from "@/types";
import { Module } from "vuex";


const dashboardModule: Module<DashboardState, any> = {
  namespaced: true,
  state: {
    allDashboard: [],
    newExternalDashboard: {},
    selectedExternalDashboard: {},
    allUsers: [],
    selectedUsers: [],
    preExistingUsers: [],
    preExistingUsersIDs: [],
  },
  getters: {
    getAllDashboard(state) {
      return state.allDashboard;
    },
    getNewExternalDashboard(state) {
      return state.newExternalDashboard;
    },
    getAllUsers(state) {
      return state.allUsers;
    },
    getSelectedUsers(state) {
      return state.selectedUsers;
    },
    getPreExistingUsersIDs(state) {
      return state.preExistingUsersIDs;
    },
    getPreExistingUsers(state) {
      return state.preExistingUsers;
    },
    getSelectedExternalDashboard(state) {
      return state.selectedExternalDashboard;
    }
  },
  actions: {
    resetSelections({ commit }) {
      commit("resetSelections");
    },
    async getAllDashboard({ commit }) {
      const data = await fetchAllDashboard();
      commit("setDashboard", data);
      return data;
    },
    setNewExternalDashboard({ commit }, payload) {
      commit("setNewExternalDashboard", payload);
    },
    setSelectedExternalDashboard({ commit }, payload) {
      commit("setSelectedExternalDashboard", payload);
    },
    resetNewExternalDashboard({ commit }) {
      commit("setNewExternalDashboard", {});
    },
    setAllUsers({ commit }, payload) {
      commit("setAllUsers", payload);
    },
    setSelectedUsers({ commit }, payload) {
      commit("setSelectedUsers", payload);
    },
    setPreExistingUsersIDs({ commit }, payload) {
      commit("setPreExistingUsersIDs", payload);
    },
    setPreExistingUsers({ commit }, payload) {
      commit("setPreExistingUsers", payload);
    },
    deleteUserDashbord({ commit }, payload) {
      deleteUserDashbord(payload);
    },

    async getAllUsersByDashbordId({ getters }) {
      const { externaldashboard } = getters.getSelectedExternalDashboard;
      return await getAllUsersByDashbordId(externaldashboard);
    },

    async saveNewExternalDashboard({ getters, dispatch }) {
      const {
        getPreExistingUsersIDs,
        getSelectedUsers,
        getPreExistingUsers,
        getNewExternalDashboard,
        getSelectedExternalDashboard
      } = getters;
      if (getSelectedUsers) {
        getSelectedUsers
          .filter((s: any) => !getPreExistingUsersIDs.includes(s.user_id))
          .forEach((item: any) => {            
              return (saveNewExternalDashboard(JSON.stringify(getNewExternalDashboard) !== '{}' ? getNewExternalDashboard : getSelectedExternalDashboard, item.user_id));
          });
          
        const selectedUserIDs = getSelectedUsers.map((u: any) => u.user_id);

        getPreExistingUsers
          .filter((l: any) => !selectedUserIDs.includes(l.user.resourceUid))
          .forEach((item: any) => {
            dispatch("deleteUserDashbord", item.dashboard.resourceUid);
          });
      }
    },
  },
  mutations: {
    setDashboard(state, payload) {
      state.allDashboard = payload ?? [];
    },
    setNewExternalDashboard(state, payload) {
      state.newExternalDashboard = payload ?? "";
    },
    setSelectedExternalDashboard(state, payload) {
      state.selectedExternalDashboard = payload ?? {};
    },
    setAllUsers(state, payload) {
      state.allUsers = payload ?? [];
    },
    setSelectedUsers(state, payload) {
      state.selectedUsers = payload ?? [];
    },
    setPreExistingUsersIDs(state, payload) {
      state.preExistingUsersIDs = payload ?? [];
    },
    setPreExistingUsers(state, payload) {
      state.preExistingUsers = payload ?? [];
    },
    resetSelections(state) {
      state.selectedUsers = [];
      state.preExistingUsersIDs = [];
      state.preExistingUsers = [];
      state.newExternalDashboard = {},
      state.selectedExternalDashboard = {}
    },
  }


};
export default dashboardModule;
