import {Module} from "vuex";
import {
    filterParty,

    searchBankAgency,
    searchOrganization,
    searchOrganizationAsset,
    searchParties,
    searchPerson, searchSupplier, searchSupplierAddress,

} from '@/store/services/searchThird'
import {SearchPersonState} from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import { downloadFile } from "../services/offerService";
const errorTreatment = (options: Options) => {
    const {
        error,
        action = ''
    } = options;
    console.error(`${action} action => ${error.response?.data?.title ?? ''} \n ${error}`)
}
interface Options {
    error: any,
    action: string
}

const searchThirdModule: Module<SearchPersonState, any> = {
    namespaced: true,
    state: {

    },
    getters: {

    },
    actions: {
        cleanState ({commit}, payload) {
            commit('setInitial', payload)
        },
        searchPerson({commit}, payload) {
            const { filter, callback } = payload
            searchPerson(filter)
                .then(res => callback(res))
                .catch(e => console.error(e))
        },
        searchBankAgency({commit}, payload) {
            const { filter, callback } = payload
            searchBankAgency(filter)
                .then(res => callback(res))
                .catch(e => {
                    Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                        message: `Search failed. \n ${e}`,
                        color: 'negative'
                    });
                    callback([])
                })
        },
        searchOrganization({commit}, payload) {
            const { filter, callback } = payload
            searchOrganization(filter)
                .then(res => {
                    res = res.filter(
                        (value, index, self) => {
                            return index === self.findIndex((t) => t.id === value.id)
                        })
                    callback(res)
                })
                .catch(e => console.error(e))
        },
        searchSupplierAddress({commit}, payload) {
            const { filter, callback } = payload
            searchSupplierAddress(filter)
                .then(res => {
                    callback(res)
                })
                .catch(e => console.error(e))
        },
        searchOrganizationAsset({commit}, payload) {
            const { filter, callback } = payload
            searchOrganizationAsset(filter)
                .then(res => {
                    res = res.filter(
                        (value, index, self) => {
                            return index === self.findIndex((t) => t.id === value.id)
                        })
                    callback(res)
                })
                .catch(e => console.error(e))
        },
        searchSupplier({commit}, payload) {
            const { filter, callback } = payload
            searchSupplier(filter)
                .then(res => {
                    res = res.filter(
                        (value, index, self) => {
                            return index === self.findIndex((t) => t.id === value.id)
                        })
                    callback(res)
                })
                .catch(e => console.error(e))
        },
        searchParties({commit}, payload) {
            const { filter, callback } = payload
            searchParties(filter)
                .then(res => callback(res))
                .catch(e => console.error(e))
        },downloadFile: ({ commit }, payload) => {
            const { request, callback } = payload
            downloadFile(request).then(response => {
                                                 if ( callback ) {
                                                     callback(response)
                                                 }
                                             })
                                             .catch(e => errorTreatment({error: e, action: 'downloadFile'}))
        },

        
        
        filterParty({commit}, payload) {
            const { filter,offset,limit, callback } = payload
            filterParty(filter, offset, limit)
                .then(res => callback(res))
                .catch(e => console.error(e))
        },
    },
    mutations: {
        setInitial(state, payload) {
        },
        
    },
}

export default searchThirdModule;
