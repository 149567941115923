import i18n from "@/i18n";
import {UON, globalConfig} from "@/utils";
import {useApi} from "@/requests/useApi";
import store from "./store";

export const treatLocale = (value: any) => {
    let aValue;
    if (typeof value === 'object') {
        aValue = value?.id.split('-');
    }
    else aValue = value.split('-');
    return {
        language: aValue[0],
        country: aValue[1]
    }
}

// Function to fetch and set the messages for the selected language
export async function loadMessages(options: { language: string, country?: string }) {
    const locale = `${options.language}${options.country ? '-' + options.country : ''}`;
    try {
        const { response } = await useApi(UON.SystemUid.odmConfiguration, 'translation/translate/').anonymous(options);
        if (response) {
            i18n.global.setLocaleMessage(locale, response);
            i18n.global.locale.value = locale; // Set the current locale
            localStorage.setItem('locale', locale);
        }
        else console.error(`No settled language for the specific locale ${locale} in database!`)
    } catch (error) {
        console.error('Error fetching messages:', error);
    }
}

export const getTranslations = async (value: any, checkLocal?: boolean) => {
    let msgValue: any;
    if (checkLocal) {
        msgValue = localStorage.getItem('locale') || value
        const option = store.getters["authModule/getUserConnected"].preferredLanguages.filter((item: any) => (item.id === msgValue || item.id === msgValue.id) )
        store.state.authModule!.user.selectedLanguage = option[0]
    }
    else {
        msgValue = value
        store.state.authModule!.user.selectedLanguage = value
    }

    const { language, country } = treatLocale(msgValue);
    await loadMessages({ language: language, country: country });
}
