import {Module} from "vuex";
import {OrderCommissionState} from "@/types";
import {UpdateOrderCommission, calculateOrderCommission, getCommissionPackItems, getCommissionProductPack, getOrderCommissionTask} from "@/store/services/order/commission/commissionService";

const orderCommissionModule: Module<OrderCommissionState, any> = {
    namespaced: true,
    state: {
        orderCommissionResultTable: [],
        formBind: {
            decision: '',
            decisionDate: '',
            comment: '',
        },
        commission:{}
    },
    getters:{
        getInitCommission(){
            return {
                role:'',
                accessoryProduct:{
                     objectType	:	"odm.product.financingproductpackitem.accessoryproductitem.commissionproductitem.assetcommissionproductitem",
                     systemUid	:	"odm-product",
                     resourceUid	:	"Prime"
                },
                adjustAmount:{
                    amount	:	0,
                    currency	:	"EUR"
                },
                calculatedAmountWTax:{
                    amount	:	0,
                    currency	:	"EUR"
                },
                beneficiary: null,
                orderAccessoryPaymentDatesOption: null,
                commissionAmountWoTax: null,
                commissionAmountWTax: null,
                commissionType: "COMMISSION_TYPE.PRIME",
                calculatedAmountWoTax: null,
                taxValue:null,
                taxCode:null,
                rate:0,
                objectType: "odm.order.ordercommission",
                systemUid: "odm-order",
                basisValue: 0,

              }
        }
    },
    actions: {
        setOrderCommissionResult({commit}, payload) {
            // TODO EF-262 - finish
            commit('setOrderCommissionResult', {})
        },
        setOrderCommissionResultTable({commit}, payload) {
            // TODO EF-262 - remove mock
            //calculateOrderCommission()
            const {order,commissionProductPackUid}=payload
            getCommissionPackItems(order,commissionProductPackUid).then((res) => {
                UpdateOrderCommission(order,res,commissionProductPackUid).then((result:any) => {
                    this.dispatch('taskModule/setOrderUpdate', result)
                })
                commit('setOrderCommissionResultTable', res)
            }
            )
        },
        setOrderCommissionTask({commit}, payload) {
            // TODO EF-262 - remove mock
            const task = getOrderCommissionTask()
            this.dispatch('taskModule/getSelectedTaskOrderDetail', { task, callback: () => {} })

        },
        getCommissionProductPack({commit}, payload) {
            const task = getCommissionProductPack(payload).then((res) =>{
                const commissionProductPackList=[]
                for (const item of res){
                    commissionProductPackList.push({value:item.resourceUid,
                    label:item.resourceUid.replaceAll(".", "_")})
                }
              

                commit('setCommissionProductPack', commissionProductPackList)})
            //this.dispatch('taskModule/getSelectedTaskOrderDetail', { task, callback: () => {} })

        },
        setCommission({commit}, payload) {
          commit('setCommission', payload)
        },
    },
    mutations: {
        setOrderCommissionResult(state, payload) {
            state.orderCommissionResult = payload
        },
        setOrderCommissionResultTable(state, payload) {
            state.orderCommissionResultTable = payload
        },
        setCommissionProductPack(state, payload) {
            state.commissionProductPack = payload
        },
        setCommission(state, payload) {
            state.commission = payload
        },
    }
}

export default orderCommissionModule;
