
  import { Options, Vue } from "vue-class-component";
  import LabelAndValue from "@/components/general/LabelAndValue.vue";
  import { globalConfig } from "@/utils";
  import { formatWithMask } from "@/utils/configuration/formatters-config";
  import store from "@/store";
import { proposalAccessoriesObjectTypes } from "@/types";
  
  @Options({
    components: { LabelAndValue },
    props: ["proposal", "valueStyle", "labelStyle", "divValueStyle"],
  })
  export default class SummaryFinancingGuarantee extends Vue {
    proposalPartyInsuranceObjectType = proposalAccessoriesObjectTypes.proposalPartyInsuranceObjectType
    capitalize = globalConfig.capitalize;
    proposal?: any;
    labelStyle?: any;
  
    baseServiceKey = "demand.product.services.names";

    get proposalGuarantees() {
      return this.proposal.proposalGuarantees || []
    }
  
    get guaranteeList() {
      return this.proposalGuarantees.map((guarantee: any) => {
        return {
          label: `${this.baseServiceKey}.${guarantee.resourceUid.replaceAll(
            ".",
            "_"
          )}`,
          value: `${formatWithMask(
            guarantee.amount,
            2
          )} ${globalConfig.currencyMapper('EUR')}`,
        };
      });
    }
    valueStyle = {
      "font-weight": "bold",
      "margin-left": "20px",
      color: "black",
    };
  
    divValueStyle = {
      display: "flex",
      "justify-content": "end",
      "padding-right": "10px",
    };
    labelStyleItem = {
      "font-size": "1rem",
      color: "gray",
      "font-weight": "bold",
    };
  }
  