import { Module } from "vuex";
import { MessageStatusEnum, Notifications } from "@/types";
import {
  getAllMessageByCurrentUser,
  getMessageByRessourceUid,
  updateMessageStatus,
} from "@/store/services/communication/notification";
import { replyMessageService, transferMessage } from "@/store/services/communication/messaging";

import store from '@/store'
const notificationModule: Module<Notifications, any> = {
  namespaced: true,
  state: {
    notifications: [],
    countNotifications: 0,
    msgUID: "",
    msgStatusTabs: ["ALL", "READ", "NEW"],
    msgSelectedStatusTab: "ALL"
  },
  mutations: {

    setSelectedStatusTab(state, payload) {
      state.msgSelectedStatusTab = payload
    },


    setNewMessage(state, payload) {
      state.notifications.unshift(payload);

      //computer messages with unread status
      state.countNotifications = state.notifications.filter(
        (el: any) => el.status?.resourceUid !== MessageStatusEnum.READ
      ).length;
    },
    /**
     * This mutation makes it possible to modify the list of module messages.
     * Updates the list of notifications by filtering messages with a "CLOSED" status and counting the number of unread messages.
     * @param {PanelNotification} state - The current state of the module.
     * @param {Message[]} payload - The new list of messages.
     * @return {void} The list of messages after the update.
     */
    setData(state, payload) {
      //retrieve only the list of messages that are not closed
      state.notifications = payload.filter(
        (el: any) => el.status.resourceUid !== MessageStatusEnum.CLOSED
      );

      //computer messages with unread status
      state.countNotifications = state.notifications.filter(
        (el: any) => el.status?.resourceUid !== MessageStatusEnum.READ
      ).length;
    },

    /**
     * This mutation allows to change the status of a message from "unread" to "read".
     * @param {PanelNotification} state - The current state of the module.
     * @param {number} payload - The index of the message to modify.
     * @return {empty}
     **/

    updateStateOfMessage(state, payload: { index: number; messageEvent: any; ActionType: string }) {
      if (
        state.notifications[payload.index].status!.resourceUid ===
        MessageStatusEnum.NEW
      ) {
        state.countNotifications--;
      }
      if (payload.ActionType === "Delete") {
        state.notifications.splice(payload.index, 1)
      }
    },

    /**
     * Delete message from message list.
     * @param {Notifications} state - The current state of the module.
     * @param {number} payload - The index of the message to be deleted.
     * @return {empty}
     **/

    deleteAnMessage(state, payload: { index: number; messageEvent: any }) {
      /**
       * Decrease notification counter if notification deleted
       * is unread.
       */
      if (
        state.notifications[payload.index].status?.resourceUid ===
        MessageStatusEnum.CLOSED
      ) {
        state.countNotifications--;
      }

      /**
       * Delete the notification from the index given in the notice board.
       */
      state.notifications = state.notifications.filter(
        (el, index) => index != payload.index
      );
    },

    setMsgUID(state, payload) {
      state.msgUID = payload;
    },

  },
  actions: {
    /**
     * This action retrieves notification messages and stores them in the module state.
     * @return {Promise<PanelNotification>}
     */
    async fetchData({ commit }, payload) {
      let _notifications: any = null;
      if (payload !== null) {
        const { ressourceUid } = payload;


        _notifications = await getMessageByRessourceUid(ressourceUid);

        if (store.state.authModule?.user.userId != _notifications.senderUid.resourceUid) {
          commit("setNewMessage", _notifications);
        }
      } else {
        try {
          _notifications = await getAllMessageByCurrentUser();
          /**
           *  sorted list of notifs by date Time
           */
          const sortedNotifications = _notifications.sort(
            (a: any, b: any) =>
              new Date(b.communicationDate).getTime() -
              new Date(a.communicationDate).getTime()
          );
          /**
           *  trim attributes of notif.metadata
           *  */
          const __notifications = sortedNotifications.map((notification: any) => {
            return {
              ...notification, metadata: [...notification.metadata].map((obj: any) => {
                obj.value = obj.value.trimEnd();
                return obj;
              })
            };
          });
          commit("setData", __notifications);
        } catch (error) {
          console.error(error);
        }
      }

    },


    updateSelectedStatusTab({ commit }, payload: any) {
      commit("setSelectedStatusTab", payload)
    },



    /**
     * This action changes the status of a message from "unread" to "read".
     * @param {number} payload - The index of the message to modify.
     * @return {empty}
     */
    updateState({ dispatch }, payload: any) {
      const { index, notification, ActionType } = payload;
      if(ActionType === "Delete"){
        store.dispatch("showConfirmationDeleteDialog", {
          message: `Are you sure you want to delete this notification?`,
          deleteAction: 'Notification',
          payload: payload,
          confirmation: true
        })
      }else{
        dispatch("updateStateOfMessage",payload);
      }
    },
     
    updateStateOfMessage({ commit }, payload: any) {
      const { index, notification, ActionType } = payload;
      commit("updateStateOfMessage", { index, ActionType })
      updateMessageStatus(notification, ActionType)
    },

    /**
     * Sets the unique identifier of the message to be replied to in the notification module state.
     *
     * @async
     * @function setMsgUID
     * @param {string} payload - The unique identifier of the message to be replied to.
     */
    setMsgUID({ commit }, payload) {
      commit("setMsgUID", payload);
    },
    /**
     * Sends a reply message to a system event using the replyMessageService.
     * @function messageReply
     * @param {Message} payload - The message to be sent as a reply.
     * @throws {Error} An error object containing details about the failed API call.
     */
    async messageReply({ commit }, payload) {
      try {
        const res = await replyMessageService(payload);
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * transfer selected message from notifications layout
     * @param commit
     * @param payload
     */
    async transferMessage({ commit }, payload) {
      try {
        const res = await new Promise((resolve, reject) => {
          transferMessage(payload)
            .then(resolve)
            .catch(reject);
        });
        return res;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }


  },
  getters: {
    /**
     * Returns the list of notifications.
     * @param {PanelNotification} state - The current state of the module.
     * @return {Message[]} The list of notifications.
     */
    getData(state) {
      return state.notifications;
    },
    /**
     * Returns the number of unread notifications.
     * @param {PanelNotification} state - The current state of the module.
     * @return {number} The number of unread notifications.
     */
    getDataSize(state) {
      return state.countNotifications;
    },


    /**
     * Retrieves the unique identifier of the message to be replied to from the notification module state.
     * @returns {string} The unique identifier of the message to be replied to.
     */
    getMsgUID(state) {
      return state.msgUID;
    },
  },
};
export default notificationModule;
