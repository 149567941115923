import {Module} from "vuex";
import {FinancingState, FinancingStateEnum, ProductSummary, RootState, SearchVariableType,} from "@/types";
import serviceModule from "@/store/modules/financing/serviceModule";
import scalesModule from "@/store/modules/financing/scalesModule";
import formModule from "@/store/modules/financing/formModule";
import {
    buildNormalModeFromScale,
    calculateFromState,
    calculateScales,
    getPacks,
    getScales,
    getSimulationReport,
    recalculateMillageValue,
    recalculateResidualValue,
    recalculateScaleSelected,
} from "@/store/services/financing/financingService";
import {deepClone, groupBy} from "@/utils";
import {
    getCalculatedWithErrorPacks,
    getMissingPacks,
    getRemainingPacks,
    getRemovedPacks,
    INVESTMENT_BASIS_TAX_EXCLUSIVE,
} from "@/store/services/financing/financingMapper";
import {calculateProposalAssetCarAmounts} from "@/store/services/asset/assetMapper";
import Notify from 'quasar/src/plugins/Notify.js';;
import {updatePartyInsurance} from "@/store/services/financing/partyInsurance";
import proposalGuaranteeModule from "@/store/modules/financing/proposalGuaranteeModule";


const financingModule: Module<FinancingState, RootState> = {
    namespaced: true,
    state: {
        currentState: FinancingStateEnum.INITIAL,
        scalesModule: {
            scales: []
        },
        formModule: {},
        serviceModule: {
            services: [],
            calculationLoading: false,
            calculationPendent: false
        },
        packs: [],
        financingProducts: []
    },
    getters: {
        simulationPrice(state) { return state.scalesModule?.scaleSelected?.investmentBasis === INVESTMENT_BASIS_TAX_EXCLUSIVE
            ? state.proposalAssetItemsTotalWoTax
            : state.proposalAssetTotalWithTax },
        isInitialState(state) { return state.currentState === FinancingStateEnum.INITIAL },
        isCalculatedState(state) { return state.currentState === FinancingStateEnum.CALCULATED },
        isServiceDetailState(state) { return state.currentState === FinancingStateEnum.SERVICE_DETAIL },
        isGlobalPaymentScheduleState(state) { return state.currentState === FinancingStateEnum.GLOBAL_PAYMENT_SCHEDULE },
        isExpertModeState(state) { return state.currentState === FinancingStateEnum.EXPERT },
        isSavedState(state) { return state.currentState === FinancingStateEnum.SAVED },
        packsCombobox(state) {
            const group = groupBy(state.financingProducts, 'categoryClass')
            return [{ value: 'NULL', label: '' }, ...Object.keys(group).map(key => group[key][0])]
        }
    },
    actions: {
        initState ({commit, dispatch}) {
            const price = this.state.financingModule.proposalAssetTotalWithTax || 0
            if (price > 0) {
                getPacks(this.state).then(result => {
                    const { packs, financingProducts } = result
                    dispatch('getScales', { productSummary: packs })
                    commit('setPacks', packs)
                    commit('setFinancingProducts', financingProducts)
                })
            }
        },
        // getScales ({dispatch}, payload) {
        //     const {productSummary} = payload
        //     if (!productSummary) {
        //         throw new Error('productSummary is mandatory')
        //     }
        //     const packAutoZenList = productSummary.filter((p: any) => p.resourceUid === 'finance.PACK_LOA_AUTO_ZEN')
        //     const productSummaryItem = packAutoZenList[packAutoZenList.length - 1]
        //     // TODO remove
        //     getScales(productSummaryItem, this.state)
        //         .then(scale => {
        //             dispatch('scalesModule/addScale', { scale })
        //             dispatch('calculateScales', { scale, searchType: SearchVariableType.payment })
        //         })
        //         .catch((e) => console.error(`getScales() => ${JSON.stringify(e?.response?.data)}`))
        // },
        async getScales ({dispatch}, payload) {
            const {productSummary} = payload
            if (!productSummary) {
                throw new Error('productSummary is mandatory')
            }
            for (let i=0; i<productSummary.length; i++) {
                const productSummaryItem = productSummary[i]
                await getScales(productSummaryItem, this.state)
                    .then(scale => {
                        dispatch('scalesModule/addScale', { scale })
                        dispatch('calculateScales', { scale, searchType: SearchVariableType.payment })
                    })
                    .catch((e) => console.error(`getScales() => ${JSON.stringify(e?.response?.data)}`))
            }

            const sortedScales = this.state.financingModule.scalesModule.scales.sort((a:any, b:any) => a.scalePresentation.index - b.scalePresentation.index);

            for (const scale of sortedScales) {
                const scaleSelected = this.state.financingModule.scalesModule.scaleSelected
                if (!scaleSelected && !scale.scalePresentation.loadingError) {
                    dispatch('scalesModule/setScaleSelected', { scale }).then(() => dispatch('recalculateScaleSelected'))
                    break;
                }
            }
        },
        recalculateScaleSelected ({dispatch}) {
            const scaleSelected = this.state.financingModule.scalesModule.scaleSelected
            if (scaleSelected) {
                recalculateScaleSelected(scaleSelected, this.state).then(scalesCalculated => {
                    dispatch('scalesModule/updateScale', { scale: scalesCalculated })
                    dispatch('scalesModule/setScaleSelected', { scale: scalesCalculated })
                    dispatch('serviceModule/setCalculationPendent', { calculationPendent: false })
                })
            }
        },
        calculateScales ({dispatch}, payload) {
            const {searchType, scale} = payload
            if (!searchType) {
                throw new Error('Search Type is mandatory')
            }
            if (!scale) {
                throw new Error('Scales is mandatory')
            }
            calculateScales(searchType, scale, this.state).then(scalesCalculated => {
                dispatch('scalesModule/updateScale', { scale: scalesCalculated })
                const scaleSelected = this.state.financingModule.scalesModule.scaleSelected
                if (!scaleSelected && !scale.scalePresentation.loadingError) {
                    dispatch('scalesModule/setScaleSelected', { scale }).then(() => dispatch('recalculateScaleSelected'))
                }
                dispatch('serviceModule/setCalculationPendent', { calculationPendent: false })
            })
        },
        calculateFromState ({commit, dispatch}, payload) {
            const {searchType, scale, callback , financingType} = payload
            if (!searchType) {
                throw new Error('Search Type is mandatory')
            }
            if (!scale) {
                throw new Error('Scales is mandatory')
            }
            calculateFromState(searchType, scale, this.state ,financingType).then(scale => {
                commit('updateCurrentState', FinancingStateEnum.CALCULATED)
                dispatch('scalesModule/updateScale', { scale })
                dispatch('updateNormalMode', { scale, searchType })
                if (callback) {
                    callback(scale)
                }
                dispatch('serviceModule/setCalculationPendent', { calculationPendent: false })
            }).catch(err => {
                console.error(err)
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                    color: "warning",
                    message: err.message || err,
                })
            })
        },
        async getSimulationReport({state}, payload) {
            try {
                const simulationReport = await getSimulationReport(this.state)
                return simulationReport.content
            } catch (e) {
                console.error(e)
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                    color: "negative",
                    message: 'Error generating simulation report',
                })
            }

        },
        updateNormalMode({commit}, payload) {
            const { scale, searchType } = payload
            this.dispatch('simulationModule/changeSliderValue', buildNormalModeFromScale(scale, searchType, this.state))

        },
        setSimulationRefreshing ({commit}, payload) {
            commit('setSimulationRefreshing', payload)
        },
        async refreshState ({dispatch, commit, state}) {
            if (!state.isSimulationRefreshing) {
                commit('setSimulationRefreshing', true)
                try {
                    const price = this.state.financingModule.proposalAssetTotalWithTax || 0
                    if (price > 0) {
                        if (!this.state.financingModule.packs.length) {
                            await dispatch('initState')
                        } else if(this.state.financingModule.simulationChanged) {
                            await dispatch('refreshScales')
                        }
                    }
                } catch (e) {
                    console.error(e)
                } finally {
                    commit('setSimulationRefreshing', false)
                }
            }
        },
        async refreshStateChangePartyType ({dispatch}) {
            await dispatch('setSimulationChanged', true)
            await dispatch("refreshState")
            await dispatch("scalesModule/verifyScaleSelected")
            await dispatch('setSimulationChanged', false)

        },
        refreshScales ({commit, dispatch}) {
            getPacks(this.state).then(result => {
                const { packs, financingProducts } = result
                commit('setFinancingProducts', financingProducts)
                const missing = getMissingPacks(this.state, packs)
                const removed = getRemovedPacks(this.state, packs)
                const remainingPacks = getRemainingPacks(this.state, packs)
                const errorPacks = getCalculatedWithErrorPacks(this.state)
                if (errorPacks.length) {
                    errorPacks.forEach(s => missing.push(s))
                }
                if (missing.length) {
                    missing.forEach(s => remainingPacks.push(s))
                }
                if (removed.length) {
                    dispatch('scalesModule/removeScalesFromPacks', { packs: removed }).then(() => removed.forEach(pack => commit('removePack', pack)))
                }
                remainingPacks.forEach(pack => commit('addPack', pack))
                dispatch('getScales', { productSummary: missing })
            })
        },
        cleanScales({commit, dispatch}) {
            commit('updateCurrentState', FinancingStateEnum.INITIAL)
            commit('setPacks', [])
            dispatch('formModule/cleanState')
            dispatch('scalesModule/cleanState')
            dispatch('serviceModule/cleanState')
            commit('setFinancingProducts', [])
            commit('setSimulationChanged', false)
            commit('setCategoryClassSelected', undefined)
        },
        async resetScales({commit, dispatch}) {
            commit('setPacks', [])
            await dispatch('setSimulationChanged', true)
            await dispatch('scalesModule/cleanState')
            commit('setSimulationRefreshing', false)
            await dispatch("refreshState")
            await dispatch("scalesModule/verifyScaleSelected")
            await dispatch('setSimulationChanged', false)
        },
        cleanState ({commit, dispatch}) {
            commit('updateCurrentState', FinancingStateEnum.INITIAL)
            commit('setPacks', [])
            dispatch('formModule/cleanState')
            dispatch('scalesModule/cleanState')
            dispatch('serviceModule/cleanState')
            dispatch('proposalGuaranteeModule/cleanState')
            commit('setFinancingProducts', [])
            commit('setSimulationChanged', false)
            commit('setCategoryClassSelected', undefined)
            commit('changeProposalAssetCarAmounts', { proposalAssetTotalWithTax: undefined, proposalAssetItemsTotalWoTax: undefined })
        },
        setSimulationChanged ({commit}, payload) {
            commit('setSimulationChanged', payload)
        },
        setCategoryClassSelected ({commit}, payload) {
            commit('setCategoryClassSelected', payload)
        },
        updateCurrentState ({commit}, payload) {
            const { newState } = payload
            if (!newState) {
                throw new Error('newState is mandatory')
            }
            commit('updateCurrentState', newState)
        },
        backPreviousState ({commit}) {
            const { previousState } = this.state.financingModule
            if (previousState || previousState === 0) {
                commit('updateCurrentState', previousState)
            }
        },
        calculateProposalAssetCarAmounts ({commit, dispatch, state}, payload) {
            const totals = calculateProposalAssetCarAmounts(this.state.demandModule.assetState)
            commit('changeProposalAssetCarAmounts', totals)
            const { proposalAssetTotalWithTax } = totals
            if (proposalAssetTotalWithTax > 0 && !state.isSimulationRefreshing) {
                dispatch("recalculateScales").then(() => {
                    if (!payload || payload.skipRecalculateScaleSelected) {
                        dispatch('recalculateScaleSelected')
                    }
                })
            }
        },
        recalculateScales({dispatch}) {
            const scales = this.state.financingModule.scalesModule.scales
            scales.filter(t => !t.scalePresentation.loading)
                .forEach(scale => dispatch('calculateScales', { scale, searchType: SearchVariableType.payment }))
        },
        async refreshMillage() {
            const result = await recalculateMillageValue(this.state)
            await this.dispatch('simulationModule/changeSliderValue', { ...result, typeSelected: result?.type })
            return result
        },
        refreshResidualValue() {
            recalculateResidualValue(this.state).then(result => {
                this.dispatch('simulationModule/changeSliderValue', { ...result, typeSelected: result?.type })
            })
        },
        updatePartyInsurance({commit}, payload) {
            const {calculatedPartyInsuranceList} = payload
            if (calculatedPartyInsuranceList && calculatedPartyInsuranceList.length) {
                const partyInsuranceList = this.state.financingModule.scalesModule.scaleSelected?.scalePresentation.servicePartyInsurance || []
                updatePartyInsurance(calculatedPartyInsuranceList, partyInsuranceList)
                commit('setPartyInsurance', [...partyInsuranceList])
            }
        },
        setPartyInsurance({commit}, payload) {
            const {calculatedPartyInsuranceList} = payload
            if (calculatedPartyInsuranceList) {
                commit('setPartyInsurance', [...calculatedPartyInsuranceList])
            }
        },
        setPartyInsuranceScale({commit}, payload) {
            const {calculatedPartyInsuranceList, scale} = payload
            if (scale && calculatedPartyInsuranceList) {
                commit('setPartyInsuranceScale', { calculatedPartyInsuranceList: [...calculatedPartyInsuranceList], scale })
            }
        }
    },
    mutations: {
        setPartyInsuranceScale(state, payload) {
            const {scale, calculatedPartyInsuranceList} = payload
            if (scale && scale.scalePresentation && calculatedPartyInsuranceList) {
                scale.scalePresentation.servicePartyInsurance = calculatedPartyInsuranceList
            }
        },
        setPartyInsurance(state, payload) {
            if (state.scalesModule.scaleSelected && state.scalesModule.scaleSelected.scalePresentation) {
                state.scalesModule.scaleSelected.scalePresentation.servicePartyInsurance = payload
            }
        },
        setSimulationChanged(state, payload) {
            state.simulationChanged = payload
        },
        setPacks(state, payload) {
            state.packs = payload
        },
        addPack(state, payload: ProductSummary) {
            if (state.packs.some(s => s.resourceUid === payload.resourceUid)) {
                state.packs = [...state.packs.filter(s => s.resourceUid !== payload.resourceUid), payload]
            } else {
                state.packs.push(deepClone(payload))
            }
        },
        removePack(state, payload: ProductSummary) {
            state.packs = [...state.packs.filter(s => s.resourceUid !== payload.resourceUid)]
        },
        setFinancingProducts(state, payload) {
            state.financingProducts = payload
        },
        setCategoryClassSelected(state, payload) {
            state.categoryClassSelected = payload
        },
        updateCurrentState(state, payload) {
            state.previousState = state.currentState
            state.currentState = payload
        },
        changeProposalAssetCarAmounts(state, payload) {
            const { proposalAssetTotalWithTax, proposalAssetItemsTotalWoTax } = payload
            state.proposalAssetTotalWithTax = proposalAssetTotalWithTax
            state.proposalAssetItemsTotalWoTax = proposalAssetItemsTotalWoTax
        },
        setSimulationRefreshing(state, payload) {
            state.isSimulationRefreshing = payload
        },
    },
    modules: {
        formModule,
        scalesModule,
        serviceModule,
        proposalGuaranteeModule
    },
}

export default financingModule
