import {Module} from "vuex";
import {LoadCheckListEvent, ModifyConditionsState, RootState} from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import {getOfferDecision} from '@/store/services/middle/middleOfficeService';
import {
  buildOfferInfo,
  getAssessList,
  getDocumentTypesManualFromJustifyTypeManual,
  getGatheringProcessItemResourceUid,
  handleSupportingDocumentsManual
} from "@/store/services/task/actions/modifyCondition";
import store from "@/store";

const modifyConditionsModule: Module<ModifyConditionsState, RootState> = {
  namespaced: true,
  state: {
    supportingDocumentsManual: []
  },
  getters: {
    getSupportingDocumentManualList(state) {
      return state.supportingDocumentsManual;
    },
    getSupportingDocumentManualItemInit() {
      return {
        typeJustify: '',
        documentType: '',
        document: '',
        creationDate: '',
        status: '',
        comment: '',
        processResourceUid: '',
        processItemResourceUid: '',
        targetEntity: ''
      }
    },
  },
  actions: {
    initState({ commit }, payload) {
      getOfferDecision(this.state.taskModule.offer?.proposals[0].proposalDecisions)
          .then((response) => {
            commit("setDecisionStep", response);
          })
          .catch((err) => {
            console.error(err);
            Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
              message: err,
              color: "negative",
            });
          });

      let supportingDocumentsManual = this.state.taskModule.modifyConditionsModule.supportingDocumentsManual
      supportingDocumentsManual = []
      supportingDocumentsManual?.push({
        typeJustify: '',
        documentType: '',
        document: '',
        creationDate: '',
        status: '',
        comment: '',
        processResourceUid: '',
        targetEntity: ''
      })
      commit("setSupportingDocumentManualList", supportingDocumentsManual);

    },
    async getAssessList({ commit }, payload) {
      const { request, callback } = payload
      if(request.assessmentContextResourceUid){
        const offer = store.state.taskModule.offer as any;
        let offerInfo = await buildOfferInfo(offer);

        const loadCheckListEvent: LoadCheckListEvent = {
          contextId: request.assessmentContextResourceUid,
          assessmentType: "EVAL",
          quoteInfo: offerInfo.quoteInfo,
          partyInfo: offerInfo.partyInfo
        }
        getAssessList(loadCheckListEvent)
            .then((response) => {
              if(response && callback){
                callback(response)
              }
            })
            .catch((err) => {
              console.error(err);
              Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                message: err,
                color: "negative",
              });
            });
      }
    },
    async saveSupportingDocumentsManual({ commit, state, getters }, payload) {
      const { supportingDocumentsManual } = state
      if (supportingDocumentsManual && supportingDocumentsManual.length) {
        const item = supportingDocumentsManual[0]
        const processResourceUid = item?.processResourceUid?.entityTargets[0].documents[0].processResourceUid
        if (processResourceUid) {
          const gatheringProcessItemResourceUid = await getGatheringProcessItemResourceUid(processResourceUid)
          for (const document of supportingDocumentsManual) {
            await handleSupportingDocumentsManual(document, gatheringProcessItemResourceUid, processResourceUid)
          }
          commit("setSupportingDocumentManualList", [getters.getSupportingDocumentManualItemInit]);
        }
      }
    },
    updateSupportingDocumentManualList({ commit }, payload) {
      commit("setSupportingDocumentManualList", payload);
    },
    getDocumentTypesFromJustifyType: ({ commit }, payload) => {
      const { request, callback } = payload
      getDocumentTypesManualFromJustifyTypeManual(request.resourceUid).then(response => {
        if (callback) {
          callback(response)
        }
      })
    },
    cleanState({ commit }) {
      commit("setInitial");
    }
  },
  mutations: {
    setDecisionStep(state, payload) {
      state.decisionStep = payload;
    },
    setSupportingDocumentManualList(state, payload) {
      state.supportingDocumentsManual = payload;
    },
    setInitial(state){
      state.supportingDocumentsManual = []
    }
  },
};

export default modifyConditionsModule;
