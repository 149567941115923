import {
    Account,
    Address,
    AssociatedParty,
    OfferAssociatedParty,
    Order,
    OrderAsset,
    OrderFinancing,
    OrderItem,
    OrderNetwork,
    OrderNetworkNode,
    OrderPartyRoleEnum,
    OrderStatusEnum,
    Organization,
    PartyAddress,
    PartyCorrespondent,
    PartyCorrespondentStatus,
    Person,
    Picklist,
    PicklistItem,
    Role,
    Task,
    TaskSearchCriteria,
    Third,
    ThirdType
} from "@/types";
import {
    createAssetDelivery,
    createAssetTradeIn,
    createNetwork,
    createNetworkNode,
    createOrder,
    createOrderAlert,
    createOrderFinancing,
    createOrderParty,
    getAlertList,
    getNetwork,
    getNetworkNode,
    getOrder,
    getOrderAssetDelivery,
    getOrderAssetTradeIn,
    getOrderItem,
    getOrderParty,
    getParty,
    getorderSubvention
} from "@/store/services/order/orderApi";
import {deepClone, deleteAttributes, deleteNonRequiredAttributes, formatDateRecursively, getOperator, globalConfig, headers, Masks, mountSelectFields, Paths, removeDuplicatesPreferences, unmountSelectFieldsV2, UON, upsertWithDataReturn} from "@/utils";
import {buildAddress, getThird} from "../searchThird";
import {settings} from "@/settings";
import {getTaskHistoryByEntity, getTaskUserAction, initWorkflowProcess} from "@/store/services/task/workflow";
import {getTasks} from "@/store/services/task/taskService";
import store from "@/store";
import {variablesWorkflowOrder} from "@/utils/configuration/table-config";
import {getOrderFinancing, getOrderFinancingInit} from "@/store/services/order/financing/orderFinancingService";
import axios from "axios";
import { api } from "@/auth/api";
import { buildAnalysis, buildBirthplace, buildBusinessDataPerson, buildCitizenshipsPerson, buildJobs, buildPartyContacts, buildSituation, saveContactCorrespondent, savePartyAddresses } from "../thirdService";
import { useApi } from "@/requests/useApi";
import i18n from "@/i18n";
import { formatDate, formatDateLocale } from "@/utils/configuration/formatters-config";
import { buildPreferences } from "@/commons/party-middleware";
import { getAssociatedParty } from "../offerService";
import moment from "moment";
import { getInOperator } from "../picklist";
import { SaveCarProduct, associateCatalogsToProduct, buildInventory } from "../configuration/AssetService";
import classification from "@/components/order/party/classification/classification.vue";


export const saveOrder = async (order: Order,orderParties?:any) => {
    const payload = deepClone(order)
    delete payload.config
    let orderPartiesBody: any
    if(orderParties){
    const orderPartiesClone = deepClone(orderParties)
     orderPartiesBody = await handleOrderParties(orderPartiesClone)
    }else{
        if(order.orderParties){
        const orderPartiesClone =deepClone(order.orderParties)
        for(const orderparty of orderPartiesClone){
            orderparty.orderParty={resourceUid:orderparty.orderParty.resourceUid}
        }
         orderPartiesBody = order.orderParties
        }

    }
   
    if (payload.orderCommissions  && payload.orderCommissions.length>0) {
    for (const commission of payload.orderCommissions){
        delete commission.role
       if(commission.adjustAmount)
          commission.adjustAmount={
              amount: typeof commission.adjustAmount === 'string' ? commission.adjustAmount : commission.adjustAmount.amount,
              currency:"EUR"
              }
        }
    }
    formatDateRecursively(payload, 'DD/MM/YYYY', Masks.dateMask)
    await handleOrderItem(payload)

    const networkResponse = await  saveNetwork(order.orderNetworks)
    if (networkResponse) {
        payload.orderNetworks = {
            resourceUid: networkResponse.resourceUid
        }
    }

    const networkNodeResponse = await  saveNetworkNode(order.orderNetworkNodes)
    if (networkNodeResponse) {
        payload.orderNetworkNodes = {
            resourceUid: networkNodeResponse.resourceUid
        }
    }

    let orderFinancingResourceUid = null
    //It means update mode
    if(order.financing && order.financing.resourceUid){
        orderFinancingResourceUid = order.financing.resourceUid
    }
    order.financing = await handleOrderFinancing(orderPartiesBody, order)

    if (orderPartiesBody.length) {
        const listOfOrderParties=[]
        for(const party of orderPartiesBody){
            const orderParty={resourceUid:party.orderParty.resourceUid}
            party.orderParty=orderParty
            listOfOrderParties.push(party)

        }
        payload.orderParties=listOfOrderParties
    }

    //It means that the user does not want to create a new financing
    //TODO - check with Marwa if the financing is mandatory
    if(order.financing){
        if(orderFinancingResourceUid){
            order.financing.resourceUid = orderFinancingResourceUid
        }

        const financingResponse = await saveFinancing(order.financing)
        if (financingResponse) {
            payload.financing = {
                resourceUid: financingResponse.resourceUid
            }
        }
    }
    //Those two deletes will be romoved and a corresponding function for each will be added when they are added in the DB
    delete payload.alertList
    delete payload.orderSubvention

    if (payload.resourceUid === '') {
        delete payload.resourceUid
    }
    const orderCreated =await createOrder(payload)
    return orderCreated
}


const handleOrderItem = async (order: Order) => {
    for (const orderItem of order.orderItems || []) {
        if (!orderItem.reference || orderItem.reference === '') {
            orderItem.reference = 'TS' + (Math.random() + 1).toString().substring(2,8)
        }
        if (orderItem.orderAssets) {
            /*const orderAsset = orderItem.orderAssets[0]
            delete orderAsset.config
            if (orderAsset.assetDelivery) {
                const assetDelivery = await saveAssetDelivery(deepClone(orderAsset.assetDelivery))
                orderAsset.assetDelivery = {
                    resourceUid: assetDelivery.resourceUid,
                    objectType: assetDelivery.objectType,
                    systemUid: assetDelivery.systemUid
                }
            }*/
            if(orderItem.effectiveDeliveryDate){
                orderItem.effectiveDeliveryDate =  moment(new Date(orderItem.effectiveDeliveryDate)).format('YYYY-MM-DD HH:mm:ss')
            }
            if(orderItem.initialDeliveryDate){
                orderItem.initialDeliveryDate =  moment(orderItem.initialDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            }
            if(orderItem.previsionDeliveryDate){
                orderItem.previsionDeliveryDate =  moment(orderItem.previsionDeliveryDate,  'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            }
            const orderAsset = orderItem.orderAssets[0]
            delete orderAsset.assetTradeIn
            await handleOrderAssets(orderItem,orderAsset)
            if(orderItem.orderAssets.length >1){
                const orderAssetTradeIn = orderItem.orderAssets[1]
                orderAssetTradeIn.flagNew=false
                if((orderAssetTradeIn.assetTradeIn && orderAssetTradeIn.assetTradeIn.origin)){
                    delete  orderAssetTradeIn.assetDelivery
                    delete orderAssetTradeIn.orderAssetServices
                    if(orderAssetTradeIn.assetProduct&&orderAssetTradeIn.assetProduct.resourceUid){
                        await handleOrderAssets(orderItem,orderAssetTradeIn)
                    }else{
                        const bodyCar= handleBodyForSaveCar(orderAssetTradeIn,orderItem.reference)
                        const car = await SaveCarProduct(bodyCar)
                        if(!(car.inventoryItems&&car.inventoryItems.length>0)){
                            await buildInventory(bodyCar,car)
                        }
                        orderAssetTradeIn.assetProduct={
                            objectType:car.objectType,
                            resourceUid:car.resourceUid,
                            systemUid:car.systemUid
                        }
                        await handleOrderAssets(orderItem,orderAssetTradeIn)
                    }
                }else{
                    orderItem.orderAssets.splice(1, 1);
                }
            }
        }


    }
}

const convertDateHour= (dateEntree : any ) =>{

    const partiesDate = dateEntree.split(/[\s\/:]+/);

    // Créer un objet Date en utilisant les parties de la date
    const date = new Date(partiesDate[2], partiesDate[1] - 1, partiesDate[0], partiesDate[3] || 0, partiesDate[4] || 0);

    // Obtenir les composantes de la date et de l'heure
    const annee = date.getFullYear();
    const mois = ('0' + (date.getMonth() + 1)).slice(-2);
    const jour = ('0' + date.getDate()).slice(-2);
    const heure = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    const seconde = ('0' + date.getSeconds()).slice(-2);

    // Formatter la date selon le format spécifié
    const dateFormatee = annee + '-' + mois + '-' + jour + ' ' + heure + ':' + minute + ':' + seconde;

 return dateFormatee
}

const handleOrderAssetServices =  (orderAssetServices: any) => {
    const ListOrderAssetServices=[]
    for(const orderAssetservice of orderAssetServices){
        delete orderAssetservice.type.type
        orderAssetservice.purchase = orderAssetservice.purchase.includes('true')
        ListOrderAssetServices.push(orderAssetservice)
    }
    return ListOrderAssetServices
}


export const saveOrderParties = async (orderParties: any) => {
    const result = []
    for(const orderParty of orderParties) {
        const payload = orderParty.orderParty
        delete payload.third
        if (payload.resourceUid === '') {
            delete payload.resourceUid
        }
        result.push(await createOrderParty(payload))
    }
    return result
}

export const saveNetwork = async (orderNetwork?: OrderNetwork) => {
    if (orderNetwork) {
        if (orderNetwork.resourceUid === '') {
            delete orderNetwork.resourceUid
        }
        return createNetwork(orderNetwork)
    }
}

export const saveNetworkNode = async (orderNetworkNode?: OrderNetworkNode) => {
    if (orderNetworkNode) {
        if (orderNetworkNode.resourceUid === '') {
            delete orderNetworkNode.resourceUid
        }
        return createNetworkNode(orderNetworkNode)
    }
}

export const saveAssetTradeIn = async (assetTradeIn?: any) => {
        return createAssetTradeIn(assetTradeIn)

}


export const saveAssetDelivery = async (assetDelivery?: any) => {
  
    if (assetDelivery) {
        if (assetDelivery.resourceUid === '') {
            delete assetDelivery.resourceUid
        }
        if(assetDelivery.effectiveDeliveryDate){
            assetDelivery.effectiveDeliveryDate =  moment(assetDelivery.effectiveDeliveryDate,  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
        }
        if(assetDelivery.initialDeliveryDate){
            assetDelivery.initialDeliveryDate =  moment(assetDelivery.initialDeliveryDate,  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')

        }
        if(assetDelivery.previsionDeliveryDate){
            assetDelivery.previsionDeliveryDate =  moment(assetDelivery.previsionDeliveryDate,  'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
        }
        return createAssetDelivery(assetDelivery)
    }
}



async function handleParty(orderParty: any) {
    const orderPartyResourceEntity = await getOrderParty(orderParty.resourceUid)
    const orderPartyResourceEntityResourceUid = orderPartyResourceEntity.party.resourceUid || ''
    const type = orderPartyResourceEntity.party.objectType === "odm.party.party.person.frenchperson" ? "party-FrenchPerson" : "party-FrenchOrganization"
    orderPartyResourceEntity.third = await getThird(orderPartyResourceEntityResourceUid, type, '')
    return orderPartyResourceEntity;
}

export const getOrderEditMode = async (resourceUid: string) => {
    const order = await getOrder(resourceUid)
    const {
        orderNetworks,
        orderNetworkNodes,
        orderParties,
        orderItems
    } =  order

    order.orderNetworks = await getNetwork(orderNetworks.resourceUid)
    order.orderNetworkNodes = await getNetworkNode(orderNetworkNodes.resourceUid)
    const listOfOrderPartie=[]
    for(const party of orderParties){
        const orderPartyResourceEntity = await handleParty(party.orderParty);
        party.orderParty = orderPartyResourceEntity
        listOfOrderPartie.push(party)
    }
    order.orderParties = listOfOrderPartie
    const {orderParty} = orderParties[0]
    const orderPartyResourceEntity = await handleParty(orderParty);
    const partyName = await getPartyName(orderPartyResourceEntity.party.resourceUid, orderPartyResourceEntity.party.objectType)
    order.config = {
        partyName
    }
    order.alertList = await getAlertList()
    order.orderSubvention = await getorderSubvention()
    await handleOrderItemEditMode(orderItems)

    if(order.financing && order.financing.resourceUid){
        const financingAssociatedParty =order.orderParties.filter(
            (s: any) => s.orderParty.role.resourceUid=== OrderPartyRoleEnum.FINANCIER
          )[0]

        store.state.orderModule.orderFinancingModule.financing = await getOrderFinancing(order.financing.resourceUid)
        store.state.orderModule.orderFinancingModule.financing.associatedParty = financingAssociatedParty?{ "orderParty": financingAssociatedParty.orderParty }:undefined
    }else{
        store.state.orderModule.orderFinancingModule.financing = await getOrderFinancingInit();
    }

    return order
}


export const getPartyName = async (resourceUid: string, objectType: string) => {
    const party = await getParty(resourceUid, objectType)
    if (objectType.includes('person')) {
        return party.firstName
    } else {
        return party.name
    }
}

const handleOrderItemEditMode = async (orderItems: OrderItem[]) => {
    for (const orderItem of orderItems) {
        if(orderItem.effectiveDeliveryDate){
            orderItem.effectiveDeliveryDate =  moment(new Date(orderItem.effectiveDeliveryDate)).format('DD/MM/YYYY')
        }
        if(orderItem.initialDeliveryDate){
            orderItem.initialDeliveryDate =  moment(new Date(orderItem.initialDeliveryDate)).format('DD/MM/YYYY HH:mm')
          
        }
        if(orderItem.previsionDeliveryDate){
            orderItem.previsionDeliveryDate =  moment(new Date(orderItem.previsionDeliveryDate)).format('DD/MM/YYYY HH:mm')
        }
        const {orderAssets} = orderItem
        for (const orderAsset of orderAssets || []) {
            const { supplier, assetDelivery } = orderAsset
            if (supplier) {
                const orderPartyResourceEntity = await getOrderParty(supplier.resourceUid)
                if (orderPartyResourceEntity.party) {
                    supplier.party = orderPartyResourceEntity.party
                    supplier.role = orderPartyResourceEntity.role
                    const supplierPartyName = await getPartyName(orderPartyResourceEntity.party.resourceUid, orderPartyResourceEntity.party.objectType)
                    orderAsset.config = {
                        supplierPartyName
                    }
                    const type = "party-FrenchOrganization"
                    const third = await getThird( orderPartyResourceEntity.party.resourceUid,type,'')
                    supplier.third=third
                }
            }
            if (assetDelivery) {
                orderAsset.assetDelivery = await getOrderAssetDelivery(assetDelivery.resourceUid)
                if(orderAsset.assetDelivery?.effectiveDeliveryDate){
                    orderAsset.assetDelivery.effectiveDeliveryDate =  moment(orderAsset.assetDelivery.effectiveDeliveryDate, 'YYYY-MM-DD' ).format('DD/MM/YYYY HH:mm')
                }
                if(orderAsset.assetDelivery?.initialDeliveryDate){
                    orderAsset.assetDelivery.initialDeliveryDate =  moment(orderAsset.assetDelivery.initialDeliveryDate,  'YYYY-MM-DD').format('DD/MM/YYYY HH:mm')
        
                }
                if(orderAsset.assetDelivery?.previsionDeliveryDate){
                    orderAsset.assetDelivery.previsionDeliveryDate =  moment(orderAsset.assetDelivery.previsionDeliveryDate,  'YYYY-MM-DD').format('DD/MM/YYYY HH:mm')
                }
                
            }
            if(orderAsset.assetTradeIn && orderAsset.assetTradeIn.resourceUid){
                orderAsset.assetTradeIn = await getOrderAssetTradeIn(orderAsset.assetTradeIn.resourceUid)
            }
            if(orderAsset.orderAssetServices){
                for (const service of orderAsset.orderAssetServices){
                    if(service.type&&service.type.resourceUid){
                        service.type.type={value:service.type.resourceUid,label:service.type.resourceUid}
                    }
                    service.purchase=service.purchase?"true":"false"
                    
                }
            }
        }
        if(orderItem.orderAssets&&orderItem.orderAssets.length===1){
            const orderAsset = store.getters["orderModule/getOrderAssetInit"]
            orderItem.orderAssets.push(orderAsset)
        }
    }
}


export const initOrderWorkflow = async (order: Order) => {
    const {
        data
    } = await getTasks({
        searchVariables: JSON.stringify([
            { name: 'orderReference',  operator: "eq",  value: order.reference }
        ])
    })
    if(!data || !data.length) {
        const variables = extractOrderVariables(order)
        await initWorkflowProcess({ processDefinitionId: settings.processDefinitionId, variables: JSON.stringify(variables) })
    }
}

const extractOrderVariables = (order: Order) => {

    const {
        resourceUid,
        reference,
        status,
        channel,
        orderNetworks,
        effectiveDeliveryDate,
        orderNetworkNodes ,
        orderParties
    } = order

    const variables = [
        { variableName: 'module', value: 'Order' },
        { variableName: 'daaq' , value: order.daaq || '' },
        { variableName: 'applicationName', value: 'TWDClientApp' },
        { variableName: 'env', value: settings.env },
        { variableName: 'orderId', value: resourceUid },
        { variableName: 'orderReference', value: reference },
        { variableName: 'orderCreationDate', value: effectiveDeliveryDate },
        { variableName: 'channel', value: channel },
        { variableName: 'orderStatus', value: status?.resourceUid },
        { variableName: 'order', value: JSON.stringify(order) },
    ]

    const orderNetworksId = orderNetworks?.network?.resourceUid
    if (orderNetworksId)
        variables.push({ variableName: 'associated_networks_id', value: orderNetworksId })
    const associatedNetworkNodesId = orderNetworkNodes?.networkNode?.resourceUid
    if (associatedNetworkNodesId)
        variables.push({ variableName: 'associated_network_nodes_id', value: associatedNetworkNodesId })

    const customer: any = (orderParties || [])
        .filter((party: any) => party.orderParty?.role?.resourceUid === 'CUSTOMER')[0]
    if (customer) {
        const third = customer.orderParty.third
        if (third.id.objectType.includes('person')) {
            const person = customer.orderParty.third.person
            const customerName = `${person.firstName} ${person.familyName}`
            variables.push({ variableName: 'customerRessourceUid', value: person.resourceUid })
            variables.push({ variableName: 'customerName', value: customerName })
            variables.push({ variableName: 'customerReference', value: person.reference })
            variables.push({ variableName: 'customerType', value: 'person' })
            variables.push({ variableName: 'customerBirthDate', value: person.birthDate })
        } else {
            const organization = customer.orderParty.third.organization
            variables.push({ variableName: 'customerResourceUid', value: organization.resourceUid })
            variables.push({ variableName: 'customerName', value: organization.name })
            variables.push({ variableName: 'customerReference', value: organization.reference })
            variables.push({ variableName: 'customerType', value: 'organization' })
        }
    }

    return variables

}

export async function getOrderTaskHistory() {
    const taskSearchCriteria: TaskSearchCriteria = {
      entityId: store.state.orderModule.order?.resourceUid || "" ,//"65f04e58aad7522c7dce6d2b",
      initializeVariables: variablesWorkflowOrder };
    const tasklist = await getTaskHistoryByEntity(taskSearchCriteria);
    const result = await setTaskUserAction(tasklist.data);
    return result;
  }
  export async function setTaskUserAction(payload: any) {
    payload.forEach(async (element: Task) => {
      if (element.taskId) {
        element.histories = await (await getTaskUserAction(element.taskId)).data;
        element.opened = true;
      }
    });
  
    return payload.sort((a: any, b: any) => Date.parse(b.creationDate) - Date.parse(a.creationDate));
  }


export const saveFinancing = async (orderFinancing?: OrderFinancing) => {
    if (orderFinancing) {
        if (orderFinancing.resourceUid === '') {
            delete orderFinancing.resourceUid
        }
        return createOrderFinancing(orderFinancing)
    }
}

async function handleOrderFinancing(saveOrderPartiesResponse: any, order: any):Promise<any> {
    let orderFinancingRequest = null
    if(store.state.orderModule.orderFinancingModule.financing?.label){
        const financingParty = saveOrderPartiesResponse.filter((party: any) => party.orderParty?.party?.resourceUid == store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.party?.resourceUid);
        orderFinancingRequest = {...store.state.orderModule.orderFinancingModule.financing };

        if (financingParty && financingParty[0].orderParty && store.state.orderModule.orderFinancingModule.financing) {
            orderFinancingRequest.financingOrganization = {
                resourceUid: financingParty[0].orderParty.resourceUid,
                objectType: financingParty[0].orderParty.objectType,
                systemUid: financingParty[0].orderParty.systemUid,
            }
        }
        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.contribution) {
            orderFinancingRequest.contribution = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.contribution,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.financedAmountWoTax) {
            orderFinancingRequest.financedAmountWoTax = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.financedAmountWoTax,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.financedAmountWTax) {
            orderFinancingRequest.financedAmountWTax = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.financedAmountWTax,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.firstRent) {
            orderFinancingRequest.firstRent = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.firstRent,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && orderFinancingRequest.type) {
            orderFinancingRequest.type = orderFinancingRequest.type.value
            //TODO - check with Marwa what we will do about this field - currently is the scale
            orderFinancingRequest.financingProductPack = null
            orderFinancingRequest.objectType = 'odm.order.orderfinancing'
        }
        delete orderFinancingRequest?.scale
        delete orderFinancingRequest?.associatedParty
        delete orderFinancingRequest?.config
    }


    return orderFinancingRequest
}

export function searchAssetOrder(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'asset-order-search-filter',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }

        const operators = []
        if (filter && filter.brand != null) {
            operators.push(getOperator("lower(ppc_brand", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.brand.label + '%').toLowerCase(), "ppc_brand"))
        }
        if (filter && filter.model != null) {
            operators.push(getOperator("lower(ppc_model", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.model.label + '%').toLowerCase(), "ppc_model"))
        }
        if (filter && filter.color != null) {
            operators.push(getOperator("lower(ppc_color", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.color + '%').toLowerCase(), "ppc_color"))  }
        if (filter && filter.activity != null) {
            operators.push(getOperator("product_inventory_item", "type_code", "LIKE", globalConfig.formatters.getLikeValue(filter.activity), "type_code"))
        }
        operators.push(getOperator("product_inventory_item", "status_code", "LIKE", globalConfig.formatters.getLikeValue('IN_STOCK'), "status_code"))

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            res.data.map((resItem: any) => {
                return {
                ...resItem,
                    opendetailInfo : false
                }
              })
            resolve(res.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
async function handleOrderParties(orderParties: any):Promise<any> {
    console.log("handleOrderParties")
    const daaqResourceUid=store.state.authModule?.daaqResourceUid
    const listofParties:any[]=[]
    for(const party of orderParties){
        let isOrganization: boolean
    if (party.associatedParty?.third?.type) {
        isOrganization = checkOrganization(party.associatedParty?.third?.type)
    } else {
        isOrganization = checkOrganization(party.associatedParty?.third?.objectType)
    }
    let partie
    if (isOrganization) {
        partie= await buildOrganization(party.associatedParty, orderParties, daaqResourceUid)
    } else {
        partie= await  makePerson(<Person>party.associatedParty?.third?.person, daaqResourceUid);
    }

    const orderParty:any ={
        party: {
            resourceUid:partie.resourceUid,
            objectType: partie.objectType,
            systemUid: partie.systemUid,

        },
        role: {
            resourceUid: party.role_code,
            objectType: 'odm.order.orderpartyrole'
        },
        objectType: 'odm.order.orderparty',
        systemUid: 'odm-order',
    }
    if(party.orderPartyResourceUid){
        orderParty.resourceUid=party.orderPartyResourceUid
    }
    const orderPartyResponse = await createOrderParty(orderParty)
    const orderPartieBody :any ={
        objectType: "odm.order.orderassociatedparty",
        systemUid: "odm-order"

        
    }
    orderPartieBody.orderParty=orderPartyResponse
    if(party.resourceUid){
        orderPartieBody.resourceUid=party.resourceUid
    }
    listofParties.push(orderPartieBody)
    }
    return listofParties
}
function checkOrganization(type: PicklistItem | string | undefined): boolean {
    if (typeof type === 'object') {
        return type.id === ThirdType.ORGANIZATION
    }
    else {
        return !!type?.includes(ThirdType.TYPE_ORGANIZATION)
    }
}
const buildPartyCorrespondent = async (correspondents?: PartyCorrespondent[]) => {
    for (const correspondent of correspondents || []) {
        correspondent.objectType = 'odm.party.partycorrespondent'
        correspondent.status.resourceUid = PartyCorrespondentStatus.ACTIVE
        correspondent.qualificationType.resourceUid = correspondent.qualificationType.type?.value || correspondent.qualificationType.resourceUid
        if (correspondent.qualificationType.type) deleteAttributes(correspondent.qualificationType, ['type'])
        const promises = correspondent.contactCorrespondent.map(item => saveContactCorrespondent(item))
        await Promise.all(promises)
    }
}
function buildIds(person: any) {

    if (person.ids && person.ids.length > 0) {
        person.ids?.forEach((item: any) => {
            item.emissionDate = item.validity.from ? formatDateLocale(item.validity.from, 'DD/MM/YYYY', 'fr') : '';
            item.validity.from = item.validity.from ? formatDateLocale(item.validity.from, 'DD/MM/YYYY', 'fr') : '';
            item.validity.until = item.validity.until ? formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr') : '';
            Object.keys(item.documentType).forEach(index => {
                item.documentType.resourceUid = item.documentType.type?.value;
            });
            delete item.documentType.type
            delete item.isDisabled
        });
    } else {
        delete person.ids
        person.ids = []
    }


}
async function saveAddresses(address: Address, roles?: Role[]) {
    delete address.isDisabled;

    const clonedAddress = deepClone(address);

    delete address.type;

    if (address.postalCode && !address.postalCode.resourceUid) {
        address.postalCode.resourceUid = typeof address.postalCode.type?.value === 'string' ? address.postalCode.type?.value : address.postalCode.type?.value?.value
    }
    if (address.postalCode && address.postalCode.type && address.postalCode.type.value) {
        address.postalCode.resourceUid = typeof address.postalCode.type?.value === 'string' ? address.postalCode.type?.value : address.postalCode.type?.value?.value
    }
    deleteNonRequiredAttributes(address.postalCode, ["resourceUid"]);

    if (address.street) {
        const result = await useApi('odm-party', 'address').upsert(address);
        if (result.response) {
            if (roles && roles.length > 0) {
                for (const role of roles) {
                    const params = {
                        preferences: role.preferences,
                        partyAddress: clonedAddress,
                        addressUID: result.response.resourceUid
                    }
                    const rolePreference = await buildPreferences(params);
                    role.preferences = rolePreference;
                }
            }
            return result.response.resourceUid;
        }
        else console.error(`ERROR: address was not saved [${result.error}]`);
    }
}
const makePersonAddress = async (person: Person) => {
    const promises = person?.addresses?.map(partyAddress => saveAddresses(partyAddress.address, person.roles));
    let livingSituation = ''
    if (promises) {
        const allAddressSaved = await Promise.all(promises)
        const personAddress = []
        let index = 0;
        for (const addr of allAddressSaved) {
            if (addr) {
                const partyAddressRelationship = person?.addresses?.filter(partyAddress => partyAddress.address.resourceUid === addr)[0];
                if (person?.addresses) {
                    livingSituation = (person.addresses[index] as any).livingSituation && (person.addresses[index] as any).livingSituation.resourceUid ? (person.addresses[index] as any).livingSituation.resourceUid : (person.addresses[index] as any).livingSituation  &&  (person.addresses[index] as any).livingSituation.type ? (person.addresses[index] as any).livingSituation.type.value : ''

                }
                const partyAddress: PartyAddress = {
                    resourceUid: partyAddressRelationship?.resourceUid || undefined,
                    objectType: 'odm.party.partyaddress',
                    daaq: "/",
                    address: {
                        resourceUid: addr,
                        objectType: 'odm.party.address'
                    },
                    livingSituation: livingSituation !== '' && livingSituation !== undefined ? {
                        resourceUid: livingSituation
                    } : null,

                }
                personAddress.push(partyAddress)
            }
            index++
        }
        person.addresses = personAddress
    }
}
function validateMaritalStatuses(person: Person) {
    return person?.currentMaritalStatus && person.currentMatrimonialRegime
}
function buildFinancialExposures(financialExposures: any) {
    const exposures: any[] = []
    if (financialExposures && financialExposures.length > 0) {
        financialExposures.map((item: any) => {
            const exposure =
            {
                resourceUid: item.resourceUid,
                objectType: item.objectType,
                systemUid: item.systemUid,
                daaq: item.daaq,
                grossOutstanding: {
                    amount: item.grossOutstanding,
                    currency: "EUR"
                },
                netOutstanding: {
                    amount: item.netOutstanding,
                    currency: "EUR"
                },
                residualValue: {
                    amount: item.residualValue,
                    currency: "EUR"
                },
                flagFinancialPool: item.flagFinancialPool,
                flagRiskPool: item.flagRiskPool,
                shareFinancialPool: item.shareFinancialPool,
                shareRiskPool: item.shareRiskPool,
                effectiveDate: item.effectiveDate?formatDateLocale(item.effectiveDate, 'DD/MM/YYYY', 'fr'):'',
                contractReference: item.contractReference,
                contractValidity: {
                    from: item.contractValidity?.from ? formatDateLocale(item.contractValidity.from , 'DD/MM/YYYY', 'fr'): '',
                    until: item.contractValidity?.until ? formatDateLocale(item.contractValidity.until, 'DD/MM/YYYY', 'fr') : '',
                },
                outstandingType: item.outstandingType?.value ? item.outstandingType?.value : item.outstandingType,
                financialInstitution: item.financialInstitution?.value ? item.financialInstitution?.value : item.financialInstitution,

            }
            exposures.push(exposure)
        })

        return exposures;
    }
    else {
        return []
    }

}
const makePerson = async (person: Person, daaqResourceUid?: string) => {
    unmountSelectFieldsV2(person, [
        'title',
        'nationality',
        'role',
        'status',
        'currentMaritalStatus',
        'currentMatrimonialRegime',
        'nature',
        'periodicity',
        'classifications',
        'gender'
    ]);

    deleteAttributes(person, ['validity', 'relationType', 'managerType', 'show','type','status']);

    person.daaq = daaqResourceUid ?? '/';

    if (person.correspondents) {
        person.correspondents = person.correspondents.filter((correspondent:any) => {
            return correspondent.firstName !== "" && correspondent.familyName !== "" && correspondent.qualificationType.resourceUid !== "";
        });
        await buildPartyCorrespondent(person.correspondents);
    }
    if (person.contacts) {
        for(const contact of person.contacts){
            if(contact.contactMechanism.validity){
                contact.contactMechanism.validity.from=contact.contactMechanism.validity.from?formatDateLocale(contact.contactMechanism.validity.from, 'DD/MM/YYYY', 'fr'):''
                contact.contactMechanism.validity.until=contact.contactMechanism.validity.until?formatDateLocale(contact.contactMechanism.validity.until, 'DD/MM/YYYY', 'fr'):''
            }
            
        }
        await buildPartyContacts(person.contacts);
        person.contacts.forEach((contact:any) =>{
            if( contact.contactMechanism &&  ! contact.contactMechanism.resourceUid){
              deleteAttributes(contact, ['contactMechanism','daaq']);
            }
          } )
    }

    if (person.title && !person.title.resourceUid){
        person.title.resourceUid = 'M'
    }

    if (person.ids && person.ids[0]?.reference!=='') {
        buildIds(person)
    }
    else{
        delete person.ids
        person.ids = []
    }

    if (person.addresses) {
        await makePersonAddress(person);
    }

    if (person.homemakers !== "") {
        buildBusinessDataPerson(person)
    }

    if (person.nationality && person?.nationality.resourceUid !== '') {
        buildCitizenshipsPerson(person)
    } else {
        delete person.nationality
    }

    if ((person.birthplace && person.birthplace.resourceUid && person.birthplace.resourceUid !== '') || (person.birthplace && person.birthplace.postalCode && person.birthplace.postalCode !== '')) {
        buildBirthplace(person)
    } else {
        delete person.birthplace
    }
    if (person.gender && !person.gender.resourceUid) {
        delete person.gender
    }

    if (validateMaritalStatuses(person)) {
        buildSituation(person)
    } else {
        delete person.maritalStatuses
        person.maritalStatuses = []
    }
    
    if (person.revenues && person.revenues.length > 0 && person.revenues[0].analysisDate) {
        for (const revenue of person.revenues){
            if(revenue.detail){
                for(const detail of revenue.detail){
                
                    if(detail.validity&&detail.validity.from){
                        detail.validity.from=formatDateLocale(detail.validity.from, 'DD/MM/YYYY', 'fr')
                    }
                    if(detail.validity&&detail.validity.until){
                        detail.validity.until=formatDateLocale(detail.validity.until, 'DD/MM/YYYY', 'fr')
                    }
                }
            }
            
        }
        buildAnalysis(person)
    } else {
        delete person.revenues
        person.revenues = []
    }
    if (person.financialExposures && person.financialExposures.length > 0) {
        person.financialExposures = buildFinancialExposures(person.financialExposures)
    }
    if (person.jobs && person.jobs.length > 0 && person.jobs[0].employerName) {
        person = await buildJobs(person, true)
        person.jobs?.map((job: any) => {
            if (job.jobLocation && !job.jobLocation.resourceUid) {
                delete job.jobLocation
            }
        })

    } else {
        delete person.jobs
        person.jobs = []
    } if (person.classifications && person.classifications.length > 0) {
        unmountSelectFieldsV2(person.classifications, [
            'partyClass'
        ]);
        person.classifications.forEach((value: any) => {
            delete value.classType
            delete value.isDisabled
            value.classificationDate=formatDateLocale(value.classificationDate, 'DD/MM/YYYY', 'fr')
            value.validity.from=value.validity.from?formatDateLocale(value.validity.from, 'DD/MM/YYYY', 'fr'):''
            value.validity.until=value.validity.until?formatDateLocale(value.validity.until, 'DD/MM/YYYY', 'fr'):''
        })
        person.classifications=person.classifications.filter((s: any) => s.partyClass.resourceUid)
    }
    if (person.accounts) {
        const accounts: Account[] = []
        let i = 0
        for (const item of person.accounts) {
            const data: Account = {
                objectType: 'odm.party.partybankaccount',
                bankAccount: {
                    resourceUid: item.bankAccount.resourceUid,
                    objectType: 'odm.party.bankaccount'
                },
                daaq: '/',
                resourceUid: person.accounts[i].resourceUid ? person.accounts[i].resourceUid : ''
            }
            accounts.push(data);
            i++
        }
        person.accounts = accounts
    }

    deleteAttributes(person, [
        'currentMaritalStatus',
        'currentMatrimonialRegime',
        'homemakers',
        'retirementPassage',
        'birthChildren',
        'removedSituation',
        'age',
    ])
    person.objectType = UON.ObjectType.frenchPerson;
    person.systemUid = UON.SystemUid.odmParty;
    delete person.relations

    if (!person.reference || person.reference === '') {
        person.reference = (await api().post(`/odm-configuration/api/1/odm-configuration/reference/generate/Party/`, {})).data.reference
    }
    delete person.driverLicenceDate
    if(person.birthDate){

        person.birthDate=formatDateLocale(person.birthDate, 'DD/MM/YYYY', 'fr')

    }

    return savePerson(person, 'odm-party', daaqResourceUid);
}
const savePerson = async (person: Person, systemUid: string, daaqResourceUid?: string) => {
    person.financialExposures?.forEach((financialExposure: any) => {
        if (financialExposure.outstandingType && financialExposure.outstandingType.value === "") {
            delete financialExposure.outstandingType
        }
        if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
            delete financialExposure.financialInstitution
        }

    })

    if (person.roles) {
        for (const item of person.roles) {
            delete item.isDisabled
            if(item.validity&&item.validity.from){
                item.validity.from=formatDateLocale(item.validity.from, 'DD/MM/YYYY', 'fr')
            }
            if(item.validity&&item.validity.until){
                item.validity.from=formatDateLocale(item.validity.until, 'DD/MM/YYYY', 'fr')
            }
            if (item.preferences && item.preferences.length > 0) {
                item.preferences = removeDuplicatesPreferences(item.preferences);
            }
        }
    }

    return upsertWithDataReturn(systemUid, person, Paths.frenchPerson, undefined, daaqResourceUid);
}

async function buildOrganization(associatedParty: AssociatedParty | undefined, parties?: OfferAssociatedParty[], daaqResourceUid?: string) {
    if (associatedParty?.third?.organization) {
        const organization = associatedParty.third.organization;
        daaqResourceUid = store?.state?.authModule?.daaqResourceUid
        delete organization.creationDate
        unmountSelectFieldsV2(organization, ['legalCategory', 'activitySector', 'partyClass', 'codeNACE'])
        if (!organization.codeNACE?.resourceUid) {
            delete organization.codeNACE
        }
        organization.daaq = '/',
            organization.objectType = UON.ObjectType.frenchOrganization;
        organization.systemUid = UON.SystemUid.odmParty;
        if (organization.classifications && organization.classifications.length > 0) {
            unmountSelectFieldsV2(organization.classifications, [
                'partyClass'
            ]);
            organization.classifications.forEach((value: any) => {
                delete value.classType
                delete value.isDisabled
                value.classificationDate=formatDateLocale(value.classificationDate, 'DD/MM/YYYY', 'fr')
                value.validity.from=value.validity.from?formatDateLocale(value.validity.from, 'DD/MM/YYYY', 'fr'):''
                value.validity.until=value.validity.until?formatDateLocale(value.validity.until, 'DD/MM/YYYY', 'fr'):''
            })
            organization.classifications=organization.classifications.filter((s: any) => s.partyClass.resourceUid)
        }
        if (organization.financialExposures && organization.financialExposures.length > 0) {
            organization.financialExposures = buildFinancialExposures(organization.financialExposures)
        }
        await buildPartyCorrespondent(organization.correspondents);
        if (organization.correspondents && organization.correspondents.length > 0) {
            organization.correspondents=organization.correspondents.filter((s: any) => s.firstName!=='')
        }
        organization.facilities =[]
        await buildOrganizationAddress(associatedParty.third);
        if (!organization.legalCategory?.resourceUid) {
            delete organization.legalCategory
        }
        

        const clone = deepClone(organization);
        delete clone.manager
        delete clone.relations
        delete clone.codeApe
        delete clone.type
        delete clone.status
        clone.facilities.forEach((value: any) => {
            delete value.livingSituation
        })
        const savedOrganization = saveOrganization(clone, 'odm-party', daaqResourceUid);

        organization.resourceUid = (await savedOrganization).resourceUid

        return savedOrganization;
    }
}

async function buildOrganizationAddress(third: Third) {
    const organization = third.organization;
    if (organization && !!organization.addresses) {
        for (const address of organization.addresses){
            if(address.address && address.address.type&& address.address.type.type&&address.address.type.type.value&&address.address.type.type.value.length>0){
                address.address.type.resourceUid=address.address.type?.type.value[0]
            }
            if(address.address && address.address.type&& address.address.type.type&&address.address.type.type.id&&address.address.type.type.id.length>0){
                address.address.type.resourceUid=address.address.type?.type.id
            }
            if(address.address && address.address.type){
                delete address.address.type.type
            }
        }
        const promises =
            organization.addresses
                .filter((i:any) => !!i.address.postalCode?.postalCode)
                .map((item:any) => savePartyAddresses(item, organization.roles))
        const allPartyAddressSaved = await Promise.all(promises)
        if (organization.addresses) {
            organization.addresses.splice(0, organization.addresses.length)
        }
        organization.addresses?.push(...allPartyAddressSaved);
    }
}
const saveOrganization = async (organization: Organization, systemUid: string, daaqResourceUid?: string) => {
    organization.correspondents?.forEach(correspondent => {

        correspondent.contactCorrespondent.forEach(contact =>{
          if( ! contact.contactMechanism.resourceUid){
            deleteAttributes(contact, ['contactMechanism','daaq']);
          }
        } )

        deleteAttributes(correspondent, ['businessData']);
        deleteNonRequiredAttributes(correspondent.qualificationType, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.contacts?.forEach(contact => {
        deleteNonRequiredAttributes(contact.contactMechanism, ['objectType', 'systemUid', 'resourceUid']);
    })

    if (organization.facilities) {

        organization.facilities.forEach(item => {
            deleteNonRequiredAttributes(item.address, ['objectType', 'systemUid', 'resourceUid']);
            if (item.address?.resourceUid === undefined) {
                item.address = null;
            }
        })
        organization.facilities.forEach(item => {
            delete item.facilityType?.type
        })
    }

    organization.addresses?.forEach(address => {
        deleteNonRequiredAttributes(address.address, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.accounts?.forEach(account => {
        deleteNonRequiredAttributes(account.bankAccount, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.roles?.forEach(role => {
        delete role.isDisabled
        if(role.validity){
            role.validity.from =role.validity.from ? formatDateLocale(role.validity.from, 'DD/MM/YYYY', 'fr') :''
            role.validity.until =role.validity.until ? formatDateLocale(role.validity.until, 'DD/MM/YYYY', 'fr') :''
        }
        unmountSelectFieldsV2(role, ['status', 'role'])
    })

    if (!organization.reference || organization.reference === '') {
        organization.reference = (await api().post(`/odm-configuration/api/1/odm-configuration/reference/generate/Party/`, {})).data.reference
    }
    
    organization.financialExposures=organization.financialExposures?.filter((s: any) => 
            s.effectiveDate !== '')

    organization.financialExposures?.forEach((financialExposure: any) => {
        if (financialExposure.outstandingType.value === "") {
            delete financialExposure.outstandingType
        }
        if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
            delete financialExposure.financialInstitution
        }
    })

    buildBalanceSheets(organization);

    if (organization.roles) {
        for (const item of organization.roles) {
            if (item.preferences && item.preferences.length > 0) {
                item.preferences = removeDuplicatesPreferences(item.preferences);
            }
        }
    }

    return upsertWithDataReturn(systemUid, organization, Paths.frenchOrganization, undefined, daaqResourceUid);

}

const buildBalanceSheets = (organization: Organization) => {
    organization.balanceSheets?.forEach(balance => {
        deleteNonRequiredAttributes(balance, ['objectType', 'systemUid', 'resourceUid', 'period', 'country','label', 'supportingDocument','detail', 'status']);
        const countryUID = balance.country.id ? balance.country.id : ''
        const statusUID = balance.status.id ? balance.status.id : ''
        if (countryUID && countryUID !== '') {
            balance.country = {
                resourceUid: countryUID
            }
        }
        if (statusUID && statusUID !== '') {
            balance.status = {
                resourceUid: statusUID
            }
        }
        if (!balance.period) {
            balance.period = { until: '', from: '' }
        }
        if (!(balance.status && balance.status.resourceUid)) {
            delete balance.status
        }
        if (!(balance.country && balance.country.resourceUid)) {
            delete balance.country
        }
        if (balance.detail) {
            balance.detail.forEach((dt: any) => {
                const nature = dt.nature.id;
                dt.nature = {
                    objectType: 'odm.party.balanceitemtype',
                    resourceUid: nature
                };
                dt.objectType = 'odm.party.balanceitem';
                dt.daaq = '/'
            });
        }
        delete balance.isDisabled
    })
}
export const getOrderParties = async (orderParties:any) => {
const list=[]
let i=0
    for(const orderPartie of orderParties){
        i++
        if(orderPartie.orderParty&& orderPartie.orderParty.third){
            const thirdResourceUid =orderPartie.orderParty.third.type.id.toUpperCase().includes('PERSON')? orderPartie.orderParty.third.person.resourceUid:orderPartie.orderParty.third.organization.resourceUid
            const third = await getThird(thirdResourceUid,orderPartie.orderParty.third.type.id,'')
            await buildAddressWithPostalCode(third.addresses)
            
            mountSelectFields(third, ['legalCategory', 'activitySector'])
        if (third.organization && third.organization.facilities) {
          for (const facility of third.organization.facilities) {
            if (!facility.address) {
              facility.address = { ...store.getters["thirdModule/getAddressInit"] }
            }
          }
        }
        if (third.person && third.person.birthplace && third.person.birthplace.type) {
          if (!third.person.birthplace.type.config) {
            third.person.birthplace.type.config = {
              city: "",
              country: {
                id: "",
                label: ""
              },
            }
          }

        }
            list.push({
                id: i,
                tabName: orderPartie.orderParty.role.resourceUid ,
                role_code: orderPartie.orderParty.role.resourceUid,
                showDeleteBtn: false,
                objectType: orderPartie.objectType,
                systemUid: orderPartie.systemUid,
                resourceUid: orderPartie.resourceUid,
                orderPartyResourceUid:orderPartie.orderParty.resourceUid,
                associatedParty: {
                  third: third,
                },
              })
        }
    }

    return list
}
async function buildAddressWithPostalCode(addresses: any) {
    const partyAddresses: any = []
    for (const partyAddress of addresses) {
        const result = await useApi(UON.SystemUid.odmParty, 'address').get(partyAddress.address.resourceUid);
        if (result.response) {
            const address = result.response
            partyAddress.address=address
            if(!partyAddress.address.type){
                partyAddress.address.type= {
                    type: {
                      value: [],
                    },
                    objectType: 'odm.party.addresstype',
                    resourceUid: ''
                  }
            }
            partyAddress.address.postalCode = await buildPostalCode(address.postalCode.resourceUid);
            partyAddress.livingSituation = {
                type: {
                    value: partyAddress.livingSituation?.resourceUid || partyAddress.livingSituation?.type.value,
                    label: partyAddress.livingSituation?.resourceUid?.toString() !== "" ? i18n.global.t("middleOffice.party.person.address.livingstatus." + partyAddress.livingSituation?.resourceUid) : partyAddress.livingSituation?.type.value !== "" ? i18n.global.t("middleOffice.party.person.address.livingstatus." + partyAddress.livingSituation?.type.value) : '',
                }
            };
            partyAddresses.push(partyAddress)
        } else console.error(`buildAddress => ${result.error}`)
    }
    return partyAddresses
}

export async function buildPostalCode(resourceUid: string) {
    const resultPostalCode = await useApi(UON.SystemUid.odmParty, 'frenchpostalcode').get(resourceUid);
    if (resultPostalCode.response) {
        const postalCode = resultPostalCode.response
        const countryName = i18n.global.t(`demand.party.country.${postalCode.country.resourceUid}`)
        return {
            ...postalCode,
            country: {
                ...postalCode.country,
                name: countryName
            },
            type: {
                value: postalCode.resourceUid,
                label: `${postalCode.city} - ${postalCode.postalCode}`,
                config: {
                    city: postalCode.city,
                    department: `${postalCode.department}`,
                    country: {
                        id: postalCode.country.resourceUid,
                        label: countryName
                    },
                }
            }
        }
    } else console.error(`postalCode => ${resultPostalCode.error}`)
}
export const orderDuplicateMode = async (resourceUid: string,number:any,length:any) => {
    const order = await getOrder(resourceUid)
	delete order.resourceUid
    order.effectiveDeliveryDate=order.effectiveDeliveryDate?formatDateLocale(order.effectiveDeliveryDate, 'DD/MM/YYYY', 'fr'):''
        order.previsionDeliveryDate=order.previsionDeliveryDate?formatDateLocale(order.previsionDeliveryDate, 'DD/MM/YYYY', 'fr'):''
        for (const item of order.orderItems){
            delete item.resourceUid
            item.initialDeliveryDate=item.initialDeliveryDate?formatDateLocale(item.initialDeliveryDate, 'DD/MM/YYYY', 'fr'):''
            item.previsionDeliveryDate=item.previsionDeliveryDate?formatDateLocale(item.previsionDeliveryDate, 'DD/MM/YYYY', 'fr'):''
            item.effectiveDeliveryDate=item.effectiveDeliveryDate?formatDateLocale(item.effectiveDeliveryDate, 'DD/MM/YYYY', 'fr'):''
            for(const orderAsset of item.orderAssets){
                delete orderAsset.resourceUid
            }
        }
        for(const party of order.orderParties){
           delete party.resourceUid
        }
    

    for(let i=Number(length);i<Number(number) + Number(length);i++){
        const orderClone = deepClone(order)
        orderClone.reference =orderClone.reference+'_'+i
        for (const item of orderClone.orderItems){
            item.reference =item.reference+'_'+i
        }
        const orderCreated =await createOrder(orderClone)

    }
    
    return order
	}
    export  async function getAssociatedPartySeller(partyResourceUid:any){
        const type = partyResourceUid.objectType.includes("frenchperson")?'party-FrenchPerson':'party-FrenchOrganization'
        const third = await getThird(partyResourceUid.resourceUid,type,'')
            await buildAddressWithPostalCode(third.addresses)
            
            mountSelectFields(third, ['legalCategory', 'activitySector'])
        if (third.organization && third.organization.facilities) {
          for (const facility of third.organization.facilities) {
            if (!facility.address) {
              facility.address = { ...store.getters["thirdModule/getAddressInit"] }
            }
          }
        }
        if (third.person && third.person.birthplace && third.person.birthplace.type) {
          if (!third.person.birthplace.type.config) {
            third.person.birthplace.type.config = {
              city: "",
              country: {
                id: "",
                label: ""
              },
            }
          }

        }
            return {
                id: 2,
                tabName: OrderPartyRoleEnum.SELLER, 
                role_code: OrderPartyRoleEnum.SELLER,
                showDeleteBtn: false,
                objectType: "odm.order.orderassociatedparty",
                systemUid: "odm-order",
                resourceUid: "",
                orderPartyResourceUid:"",
                associatedParty: {
                  third: third,
                },
              }
    }

    export  function getPlaningEvent(filter:any) {
        return new Promise<any>(async (resolve, reject) => {
            const url = settings.api_query_url;
        
            const qc_header: any = {
              qc: {
                queryId: 'deliverySchedule-search-filter',
              }
            }
            qc_header.qc.parameters = {
              ...filter
            }
            const operators = []
            if (filter && filter.filterReference) {
                operators.push(getOperator( "lower(order_order","_reference)","LIKE",globalConfig.formatters.getEqualValue(filter.filterReference + "%").toLowerCase(),"reference"));
            }
            if (filter && filter.filterStatus) {
                operators.push(getOperator("order_order","status_code","EQUAL",globalConfig.formatters.getEqualValue(filter.filterStatus),"status_code"));
            }
            if (filter && filter.filterGroup) {
                operators.push(getOperator("user_security_group", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.filterGroup), "id"))
                        }
            if (filter && filter.filterActor) {
                operators.push(getOperator("user_lease_forge_user","id","EQUAL",globalConfig.formatters.getEqualValue(filter.filterActor),"id"));
            }

            operators.push(
                getOperator(
                    "order_order_party",
                    "role_code",
                    "EQUAL",
                    "'CUSTOMER'",
                    "role_code"
                )
            );


            operators.push(getInOperator("order_order", "status_code", "IN", null, null, globalConfig.formatters.getInValue(['DELIVERED','PLANNED','TO_PLAN','TO_BE_RESCHEDULED'])))
            if (operators.length > 0) {
              qc_header.qc.otherFilters = {
                expressions: [
                  {
                    and: {
                      operators
                    }
                  }
                ]
              }
              qc_header.qc.otherOrders = null
              qc_header.qc.offset = 0
              qc_header.qc.limit = 50
            }
        
            const _headers = headers({
              qc: JSON.stringify(qc_header.qc)
            })
        
            axios.get(url, {
              headers: _headers
            }).then(async res => {
        
            
              const response : any[] =[]
                 for (const resItem of res.data){
              
                const  date = resItem.previsiondeliverydate ? convertDateFromDB (resItem.previsiondeliverydate):resItem.initialdeliverydate ? convertDateFromDB (resItem.initialdeliverydate): formatDate(moment(new Date()).format("DD/MM/YYYY"));
                const startDate=  resItem.previsiondeliverydate||resItem.initialdeliverydate ? date :date +' 09:00'
                const endDate =  resItem.previsiondeliverydate ? convertDateFromDB (resItem.previsiondeliverydate,"endDate"):resItem.initialdeliverydate ? convertDateFromDB (resItem.initialdeliverydate,"endDate") : date +' 11:00'
                let associatedparty = ''
                if (resItem.associatedpartyfirstname) {
                    associatedparty = associatedparty + resItem.associatedpartyfirstname + ' '
                } if (resItem.associatedpartyfamilyname) {
                    associatedparty = associatedparty + resItem.associatedpartyfamilyname
                }
                if (resItem.associatedpartyname) {
                    associatedparty = resItem.associatedpartyname
                }     
               const orderItem = await  getOrderItem(resItem.idorderitems)
                const brand = orderItem && orderItem.data.orderAssets[0].brand 
                        ? orderItem && orderItem.data.orderAssets[0].brand.replace(/asset.good.vehicle.brand./g, "") : ""
                const  range= orderItem && orderItem.data.orderAssets[0].range.includes('range.') 
                        ? orderItem.data.orderAssets[0].range.substring(orderItem.data.orderAssets[0].range.lastIndexOf(".") + 1) : ""
                
                        const   productAsset=  orderItem &&  orderItem.data.orderAssets[0].assetProduct ? orderItem.data.orderAssets[0].assetProduct?.resourceUid : ""
                const   description    =  orderItem &&  orderItem.data.orderAssets[0].description ? orderItem.data.orderAssets[0].description: ""
       
         
                   response.push({
                        id: resItem.idorderitems,
                        start: startDate,
                        end: endDate,
                        title: resItem.referenceorder,
                        color: resItem.color ? resItem.color : setStatusColor(resItem.status_code) ,
                        deletable: true,
                        orderid: resItem.orderid,
                        content:'<i class="fas fa-truck"> '+associatedparty +' '+brand+' '+ range + '</i>' ,
                        associatedparty:  associatedparty,
                        salespersonfirstname:  resItem.salespersonfirstname,
                        salespersonlastname:  resItem.salespersonlastname,
                        ordercreateddate:  resItem.ordercreateddate,
                        initialdeliverydate:  resItem.initialdeliverydate,
                        effectivedeliverydate:  resItem.effectivedeliverydate,
                        previsiondeliverydate:  resItem.previsiondeliverydate,
                        status_code:  resItem.status_code,
                        _daaq: resItem._daaq,
                        class: "vuecal" + resItem.idorderitems,
                        clientName: associatedparty,
                        productAsset: productAsset,
                        description: description,
                        brand : brand,
                        range: range



                                
                  })
                }
              
              resolve(response)
        
            }).catch(err => {
                console.error(err)
            });
        
        
          })

    }


   function setStatusColor(status: string) {
        switch (status) {
            
          case OrderStatusEnum.PLANNED:
            return "#3699ff";
          case OrderStatusEnum.DELIVERED:
            return "#07fa2c";
          case OrderStatusEnum.TO_PLAN:
            return "#fa0707";
            case OrderStatusEnum.TO_BE_RESCHEDULED:
            return "#e607fa";
          default:
            return "#3699ff";
        }
      }

    function  convertDateFromDB(dateFromDB: any, enddate?:any){
        const dateOrigine = new Date(dateFromDB);
        const formattedTime = moment(dateFromDB).format('HH:mm');
        const add2Hours = enddate ? moment(dateOrigine.setHours(dateOrigine.getHours() + 2)).format('HH:mm') : moment(dateOrigine.setHours(dateOrigine.getHours())).format('HH:mm');

        let hour=  ""
        const jour = dateOrigine.getDate();
        const mois = dateOrigine.getMonth() + 1; // Les mois commencent à partir de 0, donc on ajoute 1
        const année = dateOrigine.getFullYear();

        if (dateOrigine.getHours() === 0 && dateOrigine.getMinutes() === 0 && dateOrigine.getSeconds() === 0) {
           hour=  "09:00"// Modifier l'heure à 9h du matin
           if (enddate){
            hour=  "11:00"
            }
        }
        else {
            hour=  formattedTime
            if (enddate){
                hour=  add2Hours
                }
        }
        const formatDate  =  année + '-' + (mois < 10 ? '0' : '') + mois + '-' + (jour < 10 ? '0' : '') + jour + ' ' +hour;
        return formatDate

      }


      export function getOrderAlertList(filter: any): Promise<Picklist> {
        return new Promise<Picklist>(async (resolve, reject) => {
            const url = settings.api_query_url;
   
            const qc_header: any = {
                qc: {
                    queryId: 'order-alert-reason',
                }
            }
            qc_header.qc.parameters = {
                ...filter
            }
            const operators = []
           
            if (filter && filter.orderId != null) {
                operators.push(getOperator("order_order_alert", "order_id", "LIKE", globalConfig.formatters.getLikeValue(filter.orderId), "id"))
            }
            if (operators.length > 0) {
                qc_header.qc.otherFilters = {
                    expressions: [
                        {
                            and: {
                                operators
                            }
                        }
                    ]
                }
                qc_header.qc.otherOrders = null
                qc_header.qc.offset = 0
                qc_header.qc.limit = 50
            }
   
            const _headers = headers({
                qc: JSON.stringify(qc_header.qc)
            })
   
            axios.get(url, {
                headers: _headers
            }).then(res => {
                const response = {
                    systemUid: 'odm-party',
                    items: res.data.map((resItem: any) => {
                        return {
    
                            id: resItem.id,
                            comment: resItem.alert_comment,
                            created_by: resItem._who_created,
                            created_when : resItem._when_created,
                            reason_code: resItem.reason_code
                        }
                    })
   
                }
                resolve(response)
            }).catch(err => {
                //FIXME: until debizium is fixed
                //reject(err)
   
            });
   
   
        })
    }



    export function getAlertReasons(): Promise<Picklist> {
        return new Promise<Picklist>(async (resolve, reject) => {
            const url = settings.api_query_url;
   
            const qc_header: any = {
                qc: {
                    queryId: 'alert-reason',
                }
            }
            
            const operators :any  = []
           
             
            if (operators.length > 0) {
                qc_header.qc.otherFilters = {
                    expressions: [
                        {
                            and: {
                                operators
                            }
                        }
                    ]
                }
                qc_header.qc.otherOrders = null
                qc_header.qc.offset = 0
                qc_header.qc.limit = 50
            }
   
            const _headers = headers({
                qc: JSON.stringify(qc_header.qc)
            })
   
            axios.get(url, {
                headers: _headers
            }).then(res => {
                const response = {
                    systemUid: 'odm-party',
                    items: res.data.map((resItem: any) => {
                        return {
                            id: resItem.id,
                            label: resItem.id
                        }
                    })
   
                }
                resolve(response)
            }).catch(err => {
                //FIXME: until debizium is fixed
                //reject(err)
   
                
            });
   
   
        })
    }
    export function buildAlertList(alertList:  any){
        const orderAlert: any = {};
        alertList.items?.forEach((item: any) => {
          if (!orderAlert[item.id]) {
            orderAlert[item.id] = {
              id: item.id,
              whoCreated: item.created_by,
              whenCreated: item.created_when,
              comment: item.comment,
              reasons: [],
            };
          }
          orderAlert[item.id].reasons.push(item.reason_code);
        });
        // Convert the reasons array to a comma-separated string
        Object.values(orderAlert).forEach((alert: any) => {
          alert.reasons = alert.reasons.join(",");
        });
        //return this.rows=  res.items
        return Object.values(orderAlert) || [];
      
    }


    const handleOrderAssets = async (orderItem: OrderItem,orderAsset: any) => {

        delete orderAsset.config
        if (orderAsset.assetDelivery) {
            const assetDelivery = await saveAssetDelivery(deepClone(orderAsset.assetDelivery))
            orderAsset.assetDelivery = {
                resourceUid: assetDelivery.resourceUid,
                objectType: assetDelivery.objectType,
                systemUid: assetDelivery.systemUid
            }
        }
        if (orderAsset.assetTradeIn) {
            const assetTradeIn = await saveAssetTradeIn(orderAsset.assetTradeIn)
            orderAsset.assetTradeIn = {
                resourceUid: assetTradeIn.resourceUid,
                objectType: assetTradeIn.objectType,
                systemUid: assetTradeIn.systemUid
            }
        }
        //

        //
        /*if(orderItem.effectiveDeliveryDate){
            orderItem.effectiveDeliveryDate =  moment(new Date(orderItem.effectiveDeliveryDate)).format('YYYY-MM-DD HH:mm:ss')
        }
        if(orderItem.initialDeliveryDate){
            orderItem.initialDeliveryDate =  convertDateHour(orderItem.initialDeliveryDate)

        }
        if(orderItem.previsionDeliveryDate){
            orderItem.previsionDeliveryDate =  convertDateHour(orderItem.previsionDeliveryDate)
        }*/
        if (orderAsset.supplier && orderAsset.supplier?.party?.resourceUid) {
            const supplier = orderAsset.supplier
            if(supplier.third){
                delete supplier.third
            }

            if (supplier.resourceUid === '') {
                delete supplier.resourceUid
            }
            const party = await createOrderParty(supplier)
            orderAsset.supplier = {
                resourceUid: party.resourceUid,
                objectType: 'odm.order.orderparty',
                systemUid: 'odm-order',
            }
        } else {
            delete orderAsset.supplier
        }
        if(orderAsset.orderAssetServices){
            orderAsset.orderAssetServices= handleOrderAssetServices(orderAsset.orderAssetServices)
        }
        if ((orderAsset as any).inventoryItemCode) {
            orderAsset.assetInventory= {
                resourceUid: (orderAsset as any).inventoryItemCode,
                objectType: 'odm.product.inventoryitem.inventoryitemvehicle.inventoryitemcar',
                systemUid: 'odm-product',
            }
            delete (orderAsset as any).inventoryItemCode
        }
        if(orderAsset.orderAssetComponents&&orderAsset.orderAssetComponents.length>0){
            for(const orderAssetComponent of orderAsset.orderAssetComponents){
                delete orderAssetComponent.config
                console.log(orderAssetComponent)
            }

        }
        if(orderAsset.brand &&orderAsset.brand.id){
            orderAsset.brand=orderAsset.brand.id
        }

        if(orderAsset.range &&orderAsset.range.id){
            orderAsset.range=orderAsset.range.id
        }

        if(orderAsset.version &&orderAsset.version.id){
            orderAsset.version=orderAsset.version.id
        }

        if(orderAsset.carBodyStyle &&orderAsset.carBodyStyle.id){
            orderAsset.carBodyStyle=orderAsset.carBodyStyle.id
        }

        if(orderAsset.engineType &&orderAsset.engineType.id){
            orderAsset.engineType=orderAsset.engineType.id
        }
        delete orderAsset.proposalAssetItems
        delete orderAsset.doorNumbers
        delete orderAsset.Kilometrage

}


  function handleBodyForSaveCar(orderAssetTradeIn:any,referenceItem:any){
        const carBody :any = {
            type:{resourceUid:'CAR'},
            nbOfDoor: orderAssetTradeIn.doorNumbers,
            horsePower: orderAssetTradeIn.horsePower,
            classifications:[],

            reference:referenceItem+' '+ orderAssetTradeIn.brand.label+' '+orderAssetTradeIn.range.label,
            name:referenceItem+' '+ orderAssetTradeIn.brand.label+' '+orderAssetTradeIn.range.label ,
            validity: {
              from: "2001-06-06",
              until: null
            },

            status: {
                objectType: 'odm.product.productstatus',
                systemUid: 'odm-product',
                resourceUid: 'DRAFT'
            },
            feature: [
              {
                defaultValue: false,
                label: 'AMOUNT',
                internalCode: 'AMOUNT',
                name: 'AMOUNT',
                standardValue: orderAssetTradeIn.amountWoTax.amount,
                minValue: 0.0,
                maxValue: 0.0,
                currency: 'EUR',
                objectType: 'odm.product.featurepricingparameter',
                systemUid: 'odm-product',
                validity:{from:"2001-06-06",until:null}
              }
            ],
            objectType: 'odm.product.productfeature.product.assetproduct.goodproduct.vehicleproduct.carproduct',
            systemUid:'odm-product'
          }
          if(orderAssetTradeIn.brand &&orderAssetTradeIn.brand.id){
            carBody.brand={id:orderAssetTradeIn.brand.id,
                config: {name: 'asset.good.vehicle.brand'}
            }
          }
          if(orderAssetTradeIn.range &&orderAssetTradeIn.range.id){
            carBody.range={id:orderAssetTradeIn.range.id,
                config: {name: 'asset.good.vehicle.brand.range'}
            }
          }
          if(orderAssetTradeIn.version &&orderAssetTradeIn.version.id){
            carBody.version=orderAssetTradeIn.version

          }
          if(orderAssetTradeIn.carBodyStyle &&orderAssetTradeIn.carBodyStyle.id){
            carBody.carBodyStyle=orderAssetTradeIn.carBodyStyle
          }
          if(orderAssetTradeIn.engineType &&orderAssetTradeIn.engineType.id){
            carBody.engineType=orderAssetTradeIn.engineType
          }
          return carBody

      }



    export function buildOrderAlert(comment:string,reasons: any) {

        const listReasonAlert: any = []
        const orderAlert =
            {
                objectType: "odm.order.orderalert",
                systemUid: "odm-order",
                alertComment: comment,
                reasons: []
            }
        for (const reason of reasons) {
            const reasonAlert =
                {
                    objectType: "odm.order.orderalertreason",
                    systemUid: "odm-order",
                    reason: {
                        resourceUid: reason
                    }
                }
                listReasonAlert.push(reasonAlert)
        }
        orderAlert.reasons=listReasonAlert
        return orderAlert
    }

