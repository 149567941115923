import { Module } from "vuex";
import {
  ScaleSliderConfig,
  SearchType,
  SimulationAttribute,
  SimulationState,
} from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;

import {
  buildExpertModeState,
  buildNormalModeState,
  calculatePricing,
  getScales,
  getScalesOtherData,
  getServices,
} from "@/store/services/simulation";
import { deleteFormatMaskAndConvertToNumber } from "@/utils/configuration/formatters-config";
import { deepClone, globalConfig } from "@/utils";
import i18n from "../../i18n";

const simulationModule: Module<SimulationState, any> = {
  namespaced: true,
  state: {
    services: [],
    selectedServices: [],
    rateFinancingTax: 2.5,
    expertModeState: {},
    normalModeState: { attributes: [] },
    typeSimulation: "normal",
    price: 0,
    calculationRequest: {
      searchVariable: SearchType.PAYMENT,
      financialProductUid: {
        systemUid: "odm-product",
        resourceUid: "finance.AutoFinancing",
      },
      price: 0,
    },
    toShowScaleBox: true,
    scales: [],
    amountTTC: 0
  },
  getters: {
    getAmountTTC(state){
      return state.amountTTC ?? 0;
    }
  },
  actions: {
    mockState({ commit }, payload) {
      // TODO remove
      const { state } = payload;
      commit("setMockState", state);
    },
    recoverState({ commit }, payload) {
      commit("setRecoverState", payload);
    },
    cleanState({ commit }, payload) {
      commit("setInitial", payload);
    },
    changePriceEditable({ commit }, payload) {
      commit("setPriceEditable", payload.isPriceEditable || false);
    },
    changeFinancingProductSelected({ commit }, payload) {
      commit("setFinancingProductSelected", {
        value: payload.financingProductSelected.id,
        ...payload.financingProductSelected,
      });
    },
    updateTypeSimulation({ commit }, payload) {
      const { type } = payload;
      commit("setTypeSimulation", type);
      const state = this.state.simulationModule;
      if (type === "expert") {
        commit("setExpertModeState", buildExpertModeState(state));
      } else if (type === "normal") {
        const normalModeState = buildNormalModeState(state);
        commit("setNormalModeState", normalModeState);
      }
    },
    clearCalculatePricingResponse({ commit }, payload) {
      commit("setInitialPricingCalculationResponse", payload);
    },
    updateSummary({ commit }, payload) {
      const { summary } = payload;
      commit("setSimulationBody", summary);
    },
    setSimulationChanged({ commit }, payload) {
      commit("setSimulationChanged", payload);
    },
    executeCalculatePricingNormalMode({ commit }, payload) {
      throw new Error("TODO 35764 remove old code ");
    },
    executeCalculatePricing({ commit }, payload) {
      const { type, request, callback } = payload;
      calculatePricing(type, request)
        .then((res) => {
          commit("setPricingCalculationResponse", res);
          callback(res);
        })
        .catch((err) => {
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Calculation returned an error \n ${err}`,
            color: "negative",
          });
        });
    },
    resetScales({ commit }, payload) {
      if (!payload) {
        throw new Error("Payload is required");
      }
      return;
      const simulationModule = this.state.simulationModule;
      const financingModule = this.state.financingModule;
      const price = financingModule.proposalAssetTotalWithTax;
      if (price <= 0) {
        Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
          message: i18n.global.t(`demand.product.messages.priceIsRequired`),
          color: "negative",
        });
      } else {
        const { init } = payload;
        if (init) {
          commit("clearNormalModeAttribute", []);
          commit("setScales", []);
          commit("setScales", []);
        } else {
          const selectedScale = simulationModule.financingProductSelected;
          const scales = simulationModule.scales;
          if (selectedScale) {
            const financialProductItem = scales.filter(
              (s: any) => s.resourceUid === selectedScale.resourceUid
            )[0];
            commit("setScales", [financialProductItem]);
          }
        }
        getScales(this.state, price).then((res) => {
          const scales = res;
          if (scales.length) {
            const selectedScale = simulationModule.financingProductSelected;
            if (selectedScale) {
              const financialProductItem = scales.filter(
                (s: any) => s.resourceUid === selectedScale.resourceUid
              )[0];
              const otherScales = scales.filter(
                (s: any) => s.resourceUid !== selectedScale.resourceUid
              );
              if (financialProductItem) {
                financialProductItem.selected = true;
                commit("setScales", [selectedScale, ...otherScales]);
                // commit('setScaleSelected', financialProductItem)
              }
            } else {
              const financialProductItem = scales[0];
              const otherScales = scales.slice(1);
              financialProductItem.selected = true;
              commit("setScales", otherScales);
              commit("setScaleSelected", financialProductItem);
            }
            getScalesOtherData(this.state, scales, price, simulationModule)
              .then((resData) => {
                if (resData.length) {
                  const financialProductItemCompleted = resData[0];
                  if (selectedScale) {
                    commit("setScales", [
                      selectedScale,
                      ...resData.filter(
                        (s: any) => s.resourceUid !== selectedScale.resourceUid
                      ),
                    ]);
                  } else {
                    commit("setScales", resData);
                  }
                  if (init) {
                    financialProductItemCompleted.selected = true;
                    simulationModule.scaleTypeFiltered = financialProductItemCompleted.activity.split(
                      "_"
                    )[1];
                    this.dispatch("simulationModule/changeFinancingProductSelected", {
                      financingProductSelected: financialProductItemCompleted,
                    }).then(() => {
                      this.dispatch("simulationModule/changeProductValue", {
                        product: financialProductItemCompleted,
                      });
                      this.dispatch("simulationModule/changeProductLabel", {
                        productLabel: i18n.global.t(
                          `demand.product.productLabel.${financialProductItemCompleted.resourceUid}`
                        ),
                      });
                      //this.dispatch('simulationModule/setScalesVisible', {id: financialProductItemCompleted.activity})
                    });
                    this.dispatch("simulationModule/initializeSliderValue", {
                      financingProduct: financialProductItemCompleted,
                    });
                    commit("setServices", financialProductItemCompleted.services);
                  } else {
                    this.dispatch("simulationModule/changeFinancingProductSelected", {
                      financingProductSelected: selectedScale,
                    }).then(() => {
                      // this.dispatch('simulationModule/setScalesVisible', {id: selectedScale.activity})
                    });
                    commit("setServices", selectedScale.services);
                  }
                }
              })
              .catch((err) => {
                console.error(err);
                commit("setScales", []);
                commit("setServices", []);
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                  message: `Initialization of the simulation returned an error \n ${err}`,
                  color: "negative",
                });
              })
              .catch((err) => {
                console.error(err);
                Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
                  message: `Initialization of the simulation returned an error \n ${err}`,
                  color: "negative",
                });
              });
          }
        });
      }
    },
    initSimulation({ commit }) {
      commit("setAmountTTC", 0)
      getServices()
        .then((res) => {
          // commit('setServices', res)
          // commit('setSelectedServices', res.filter(service => service.config.selected))
        })
        .catch((err) => {
          console.error(err);
          Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
            message: `Initialization of the simulation returned an error \n ${err}`,
            color: "negative",
          });
        });
    },
    changePrice({ commit, dispatch }, payload) {
      const { price } = payload;
      commit("setPrice", price);
      // dispatch('resetScales')
    },
    changeSliderConfig({ commit }, payload) {
      const { label, sliderConfig, scale } = payload;
      sliderConfig.forEach((slider: ScaleSliderConfig) => {
        commit("setCalculationAttribute", {
          attribute: slider.attribute,
          value: slider.value,
          type: slider.typeSelected,
        });
      });
      commit("setFinancingProductSelected", scale);
      commit("setScaleSelected", scale);
      // commit('setSliderConfig', sliderConfig)
      commit("setProductScale", label);
    },
    initializeSliderValue({ commit }, payload) {
      const { financingProduct } = payload;
      financingProduct.config.sliders.forEach((slider: any) => {
        commit("setCalculationAttribute", {
          attribute: slider.attribute,
          value: slider.value,
          type: slider.typeSelected,
        });
      });
    },
    changeSliderValue({ commit }, payload) {
      commit("setNormalModeAttribute", {
        attribute: payload.attribute,
        value: payload.value,
        type: payload.typeSelected,
      });
      this.dispatch("financingModule/setSimulationChanged", true);
    },
    addRemoveSlider({ commit }, payload) {
      const { isAdd, attributeObject } = payload;
      commit(
        isAdd ? "addNormalModeAttribute" : "removeNormalModeAttribute",
        attributeObject
      );
    },
    changeProductValue({ commit }, payload) {
      const { product } = payload;
      if (product.config) {
        commit("resetCalculationBody", product);
        product.config.sliders
          .map((slider: any) => {
            return {
              attribute: slider.attribute,
              value: slider.value,
              type: slider.slideType,
              defaultValue:
                slider.slideType === "PERCENT" || slider.slideType === "RATE"
                  ? slider.percentMin
                  : slider.min,
            };
          })
          .forEach((item: any) => commit("setCalculationAttribute", item));
        commit("setCalculationProduct", product.resourceUid);
      }
    },
    changeFinancingTypeTTC({ commit }, payload) {
      const { isTTC } = payload;
      commit("setFinancingTypeTTC", isTTC);
    },
    addSelectedService({ commit }, payload) {
      const { service } = payload;
      commit("addSelectedServices", service);
    },
    removeSelectedServices({ commit }, payload) {
      const { service } = payload;
      commit("removeSelectedServices", service);
    },
    changeProductLabel({ commit }, payload) {
      const { productLabel } = payload;
      commit("setProductLabel", productLabel);
    },
    changeExpertModeState({ commit }, payload) {
      const { body } = payload;
      commit("setExpertModeState", body);
    },
    changeFinancingProductValue({ commit }, payload) {
      const { financingProduct } = payload;
      const product = this.state.simulationModule.scales.filter((s: any) =>
        s.activity.includes(financingProduct.id)
      )[0];
      if (product && product.config) {
        commit("setFinancingProductSelected", product);
        commit("setScaleSelected", product);
        commit("setCalculationProduct", product.resourceUid);
        product.config.sliders
          .map((slider: any) => {
            return {
              attribute: slider.attribute,
              value: slider.value,
              type: slider.slideType,
              defaultValue:
                slider.slideType === "PERCENT" || slider.slideType === "RATE"
                  ? slider.percentMin
                  : slider.min,
            };
          })
          .forEach((item: any) => commit("setNormalModeAttribute", item));
      }
    },
    setScalesVisible({ commit }, payload) {
      commit("setScalesVisible", payload);
    },
    setCalculationAttribute({ commit }, payload) {
      commit("setCalculationAttribute", payload);
    },
    setFinancingValues({ commit }, payload) {
      const { sliders } = payload;
      if (!sliders) {
        throw new Error("sliders is mandatory");
      } else if (!sliders.length) {
        commit("clearNormalModeAttribute", []);
      } else {
        sliders
          .map((s: any) => {
            return {
              attribute: s.attribute,
              value: s.value,
              type: s.slideType,
              defaultValue:
                s.slideType === "PERCENT" || s.slideType === "RATE"
                  ? s.percentMin
                  : s.min,
            };
          })
          .forEach((item: any) => commit("setNormalModeAttribute", item));
      }
    },
    setAmountTTC({commit}, amount){
      commit('setAmountTTC', amount);
    }
  },
  mutations: {
    setMockState(state, payload) {
      // TODO remove
      const newState: SimulationState = payload;
      state.financingProductSelected = newState.financingProductSelected;
      state.isPriceEditable = newState.isPriceEditable;
      state.flagSelected = newState.flagSelected;
      state.rateFinancingTax = newState.rateFinancingTax;
      state.typeSimulation = newState.typeSimulation;
      state.productScale = newState.productScale;
      state.productLabel = newState.productLabel;
      state.isFinancingTypeTTC = newState.isFinancingTypeTTC;
      state.price = newState.price;
      state.simulationBody = newState.simulationBody;
      state.scales = newState.scales;
      state.scaleSelected = newState.scaleSelected;
      state.simulationResponse = newState.simulationResponse;
      state.services = newState.services;
      state.selectedServices = newState.selectedServices;
      state.sliderConfig = newState.sliderConfig;
      state.calculationRequest = newState.calculationRequest;
      state.normalModeState = newState.normalModeState;
      state.expertModeState = newState.expertModeState;
      state.pricingCalculationResponse = newState.pricingCalculationResponse;
      state.productTax = newState.productTax;
    },
    setInitial(state, payload) {
      state.services = [];
      state.selectedServices = [];
      delete state.scales;
      delete state.sliderConfig;
      delete state.simulationBody;
      delete state.productLabel;
      delete state.productScale;
      delete state.pricingCalculationResponse;
      delete state.isFinancingTypeTTC;
      state.normalModeState = { attributes: [] };
      state.expertModeState = {};
      state.price = 0;
      state.typeSimulation = "normal";
      state.calculationRequest = {
        searchVariable: SearchType.PAYMENT,
        financialProductUid: {
          systemUid: "odm-product",
          resourceUid: "finance.AutoFinancing",
        },
        price: 0,
      };
    },
    setRecoverState(state, payload) {
      const prevState = localStorage.getItem(globalConfig.storageKeys.simulationModule);
      if (prevState) {
        state = JSON.parse(prevState);
      }
    },
    setTypeSimulation(state, payload) {
      state.typeSimulation = payload;
    },
    setExpertModeState(state, payload) {
      state.expertModeState = payload;
    },
    setNormalModeState(state, payload) {
      payload.forEach((item: SimulationAttribute) => {
        if (state.normalModeState.attributes.indexOf(item) !== 1) {
          state.normalModeState.attributes = [
            item,
            ...state.normalModeState.attributes.filter(
              (att) => att.attribute !== item.attribute
            ),
          ];
        } else {
          state.normalModeState.attributes.push(item);
        }
      });
    },
    setScales(state, payload) {
      state.scales = payload;
    },
    setServices(state, payload) {
      if (payload && payload.length) {
        payload.forEach(
          (s: any) =>
          (s.config = {
            selected: s.qualification === "required",
            required: s.qualification === "required",
          })
        );
      }
      state.services = payload;
    },
    setSelectedServices(state, payload) {
      state.selectedServices = payload;
    },
    addSelectedServices(state, payload) {
      if (state.selectedServices) {
        state.selectedServices.push(payload);
      }
    },
    removeSelectedServices(state, payload) {
      if (state.selectedServices) {
        state.selectedServices = state.selectedServices.filter(
          (item) => item !== payload
        );
      }
    },
    setSliderConfig(state, payload) {
      state.sliderConfig = payload;
    },
    setPrice(state, payload) {
      state.price = payload;
    },
    setScaleSelected(state, payload) {
      state.scaleSelected = payload;
    },
    resetCalculationBody(state, payload) {
      state.calculationRequest = {
        searchVariable: SearchType.PAYMENT,
        financialProductUid: {
          systemUid: "odm-product",
          resourceUid: "finance.AutoFinancing",
        },
        price: 20000,
      };
    },
    setCalculationAttribute(state, payload) {
      const { attribute, value, type, defaultValue } = payload;
      const attributeObject: SimulationAttribute = { attribute, type };
      const price = deleteFormatMaskAndConvertToNumber(state.price.toString());
      if (price < 1) {
        if (defaultValue) {
          state.calculationRequest[attribute] = defaultValue;
          attributeObject.value = defaultValue;
          attributeObject.percentValue = defaultValue;
        }
      } else {
        if (type === "PERCENT" || type === "RATE") {
          const newValue = (price * value) / 100;
          state.calculationRequest[attribute] = newValue;
          attributeObject.value = newValue;
          attributeObject.percentValue = value;
        } else {
          state.calculationRequest[attribute] = value;
          attributeObject.value = value;
          attributeObject.percentValue = (value * 100) / price;
        }
      }
      if (
        type &&
        !state.normalModeState.attributes.some((item) => item.attribute === attribute)
      ) {
        state.normalModeState.attributes.push(attributeObject);
      } else if (
        type &&
        state.normalModeState.attributes.some((item) => item.attribute === attribute)
      ) {
        state.normalModeState.attributes
          .filter((item) => item.attribute === attribute)
          .forEach((item) => {
            item.value = attributeObject.value;
            item.percentValue = attributeObject.percentValue;
          });
      }
    },
    setNormalModeAttribute(state, payload) {
      const { attribute, value, type } = payload;
      const attributeObject: SimulationAttribute = { attribute, type };
      const price = deleteFormatMaskAndConvertToNumber(state.price.toString());
      if (type === "PERCENT" || type === "RATE") {
        attributeObject.value = (price * value) / 100;
        attributeObject.percentValue = value;
      } else {
        attributeObject.value = value;
      }
      state.normalModeState.attributes = deepClone([
        attributeObject,
        ...state.normalModeState.attributes.filter(
          (item) => item.attribute !== attribute
        ),
      ]);
    },
    addNormalModeAttribute(state, payload) {
      state.normalModeState.attributes.push(payload);
    },
    removeNormalModeAttribute(state, payload) {
      const { attribute } = payload;
      state.normalModeState.attributes = [
        ...state.normalModeState.attributes.filter(
          (item) => item.attribute !== attribute
        ),
      ];
    },
    clearNormalModeAttribute(state, payload) {
      state.normalModeState.attributes = [];
    },
    setCalculationProduct(state, payload) {
      state.calculationRequest.financialProductUid.resourceUid = payload;
    },
    setFinancingTypeTTC(state, payload) {
      state.isFinancingTypeTTC = payload;
    },
    setProductLabel(state, payload) {
      state.productLabel = payload;
    },
    setProductScale(state, payload) {
      state.productScale = payload;
    },
    setSimulationBody(state, payload) {
      state.simulationBody = payload;
    },
    setPricingCalculationResponse(state, payload) {
      state.pricingCalculationResponse = payload;
    },
    setSimulationChanged(state, payload) {
      state.simulationChanged = payload;
    },
    setSimulationResponse(state, payload) {
      state.simulationResponse = payload;
    },
    setInitialPricingCalculationResponse(state, payload) {
      delete state.pricingCalculationResponse;
    },
    setPriceEditable(state, payload) {
      state.isPriceEditable = payload;
    },
    setFinancingProductSelected(state, payload) {
      state.financingProductSelected = payload;
    },
    setScalesVisible(state, payload) {
      if (state.scales) {
        const selectedScale = state.financingProductSelected;
        const scalesFound = state.scales.filter((scale) => scale.activity === payload);
        const hasSelected = scalesFound.filter(
          (scale) => selectedScale.resourceUid === scale.resourceUid
        )[0];
        for (let i = 0; i < scalesFound.length; i++) {
          const scale = scalesFound[i];
          const isSelected = hasSelected && hasSelected.resourceUid === scale.resourceUid;
          if (payload === "NULL") {
            scale.selected = hasSelected ? isSelected : i < 1;
          }
          scale.visible = true;
        }
        if (payload !== "NULL")
          state.scales
            .filter((scale) => scale.activity !== payload)
            .forEach((scale) => (scale.visible = false));
        else state.scales.forEach((scale) => (scale.visible = true));
      }
    },
    setAmountTTC(state, amount){
      state.amountTTC = amount;
    }
  },
};

export default simulationModule;
