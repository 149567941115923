
import store from "@/store";
import {Options, Vue} from "vue-class-component";
import AlertDialog from "./AlertDialog.vue";



@Options({
  components: { AlertDialog }
})
export default class AlertButton extends Vue {

  toggleShowControls() {
    return store.dispatch('orderModule/toggleShow')
  }


  get toggleShow(){
  return store.state.orderModule.showAlert
}
}
