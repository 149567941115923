
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import { globalConfig } from "@/utils";
import {
  contacts,
  getPartyClass,
  populateContactsMap,
} from "@/components/summary-panel/summary";
import { ContactType, PartyContactCorrespondent } from "@/types";
import store from "@/store";
import i18n from "@/i18n";
import { formatDate } from "@/utils/configuration/formatters-config";

@Options({
  methods: { getPartyClass },
  components: { LabelAndValue },
  props: ["organization", "manager","order"],
  created() {
    if (this.organization.correspondents.length > 0) {
      populateContactsMap(
        this.organization.correspondents.find((el: PartyContactCorrespondent) => !!el)
          .contactCorrespondent
      );
    }
    store.dispatch("middleOfficeModule/searchClassification", {
      filter: {
        id: this.organization.resourceUid,
      },
      callback: this.updateResultRows,
    });
  },
})
export default class SummaryOrganization extends Vue {
  organization: any;
  manager: any;
  order?:string;
  rows: any[] = [];
  formatDate = formatDate;

  capitalize = globalConfig.capitalize;

  get contactType() {
    return ContactType;
  }

  get contacts() {
    return contacts;
  }

  get financialExposureTable() {
    const rawData = this.organization?.financialExposures as unknown as {
      financialInstitution: { value: string };
      outstandingType: { value: string };
      netOutstanding: number;
      grossOutstanding: number;
      residualValue: number;
      contractValidity: { until: string };
    }[];

    const currentDate = new Date(); // Get the current system date

    const groupedData: {
      [key: string]: {
        financialInstitution: string;
        outstandingTypes: {
          [key: string]: {
            netOutstanding: number;
            grossOutstanding: number;
            residualValue: number;
          }
        };
      }
    } = rawData.reduce((acc: any, el: any) => {
      const key = el.financialInstitution.value;
      if (el.contractValidity.until) {
        const contractUntilDateParts = el.contractValidity.until.split('/'); // Split the date string
        const contractUntilDate = new Date(
          Number(contractUntilDateParts[2]), // Year
          Number(contractUntilDateParts[1]) - 1, // Month (subtract 1 because months are zero-based)
          Number(contractUntilDateParts[0]) // Day
        );

        // Check if the contract is still valid (until date is greater than current system date)
        if (contractUntilDate > currentDate) {
          if (!acc[key]) {
            acc[key] = {
              financialInstitution: el.financialInstitution.value,
              outstandingTypes: {}
            };
          }
          const outstandingTypeKey = el.outstandingType.value;
          if (!acc[key].outstandingTypes[outstandingTypeKey]) {
            acc[key].outstandingTypes[outstandingTypeKey] = {
              netOutstanding: 0,
              grossOutstanding: 0,
              residualValue: 0
            };
          }
          // Accumulate values for specific outstanding type
          acc[key].outstandingTypes[outstandingTypeKey].netOutstanding += el.netOutstanding || 0;
          acc[key].outstandingTypes[outstandingTypeKey].grossOutstanding += el.grossOutstanding || 0;
          acc[key].outstandingTypes[outstandingTypeKey].residualValue += el.residualValue || 0;
        }
      }
      return acc;
    }, {});

    // Transforming data for display
    const transformedData = Object.values(groupedData).map(item => ({
      financialInstitution: i18n.global.t(
        "middleOffice.party.organization.financialInstitution." + item.financialInstitution),
      outstandingTypes: Object.entries(item.outstandingTypes).map(([key, value]) => ({
        outstandingType: i18n.global.t(
          "middleOffice.party.organization.typeOutstanding." + key),
        netOutstanding: value.netOutstanding.toFixed(2),
        grossOutstanding: value.grossOutstanding.toFixed(2),
        residualValue: value.residualValue.toFixed()
      }))
    }));

    return transformedData;
  }

  updateResultRows(rows: any) {
    this.rows = this.filterLatestValidity(rows);
  }
  filterLatestValidity(rows: any[]) {
    const latestValidityMap = new Map();
    rows.forEach(row => {
      const classTypeCode = row.class_type_code;
      if (!latestValidityMap.has(classTypeCode) ||
        row.validity_from > latestValidityMap.get(classTypeCode).validity_from ||
        (row.validity_from === latestValidityMap.get(classTypeCode).validity_from &&
         row.validity_until > latestValidityMap.get(classTypeCode).validity_until)) {
      latestValidityMap.set(classTypeCode, row);
    }
    });
    const filteredRows = Array.from(latestValidityMap.values());
    return filteredRows;
  }
 get legalCategory() {
  return store.getters["picklistModule/legalCategoryItems"].map((item: any) => {
      return {
        ...item,
        label: item.value?.toString() !== "" ?`${item.value} - ${i18n.global.t(`picklists.legal-category.${item.value}`)}` : ''
      };
    }).filter((o: any) => o.value === this.organization.legalCategory.type.value)
      .map((o: any) => o.label).join('. ');
  }
  
 get managerPhoneNumber(){
  return this.organization.manager.contacts.filter((con:any) => con.contactMechanism.type?.id?.includes('PHONE_CONTACT'))[0]?.contactMechanism?.phoneNumber
 }
  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    color: "black",
  };

  divValueStyle = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "10px",
  };
  labelStyleItem = {
    "font-size": "1rem",
    color: "gray",
    "font-weight": "bold",
  };
}
