
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import { reactive } from "vue";
import store from "@/store";
import ComboRecipients from "@/components/communication/comboRecipients.vue";

import {
  _parameters,
  clientAppMsg,
  messageTemplate,
  metadata,
  Notifications,
  objectType_odmTransferReplyMsg,
  OfferStatus,
  recipient,
  transferMsg,
  User,
} from "@/types";
import { convertToUON, notifyReplytransferMsg } from "@/utils";
import router from "@/router";

@Options({
  name: "MessageCom",
  components: {
    ComboRecipients,
  },
  props: {
    notification: {
      type: Object,
      default: () => ({}), // set default value as an empty object
    },
    destinataires: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0, // set default value as 0
    },
  },
  computed: {
    /**
     * translating notification Subject
     */
    notificationSubject() {
      return i18n.global.t(`enum.offer.status.${this.notification.subject?.resourceUid}`);
    },
    showTransferReply() {
      //return (this.showRepondre = !this.showRepondre);
    },
    /**
     *  reformate CommunicationDate to JJ/MM/AAAA
     */
    formateCommunicationDate() {
      return new Date(this.notification.communicationDate).toLocaleString("fr-FR");
    },
    /**
     * display condition for entity_status only if context is 'OFFER' and not undefined/null
     */
    checKContextEntityStatus() {
      return (
        this.notification.metadata?.length > 0 &&
        this.notification.metadata?.find((meta: any) => meta.key === "Context")?.value !==
          "PARTY" &&
        this.notification.metadata?.find((meta: any) => meta.key === "Entity_Status")
          ?.value !== "undefined"
      );
    },
    /**
     * translating notification entity_status
     */
    entityStatus() {
      if( this.notification.metadata?.find((meta: any) => meta.key === "Entity_Status")?.value !='null'){
        if(this.notification.metadata?.find((meta: any) => meta.key === "Context")?.value ==='OFFER'){
          return i18n.global.t(
          `enum.offer.status.${
          this.notification.metadata?.find((meta: any) => meta.key === "Entity_Status")
            ?.value
          }`);
        }else if (this.notification.metadata?.find((meta: any) => meta.key === "Context")?.value ==='ORDER'){
          return i18n.global.t(
          `main.dossier.orderStatus.${
          this.notification.metadata?.find((meta: any) => meta.key === "Entity_Status")
            ?.value
          }`);
        }else {
          return this.notification.metadata?.find((meta: any) => meta.key === "Entity_Status")
            ?.value   
        } 
      }
      return ''
    },
    /**
     * fetching and displaying entity_reference
     */
    entityReference() {
      if( this.notification.metadata?.find((meta: any) => meta.key === "Entity_Reference")?.value !='null'){
      return this.notification.metadata?.find(
        (meta: any) => meta.key === "Entity_Reference"
      )?.value;
    }
    return ''
  },

    context() {
      if( this.notification.metadata?.find((meta: any) => meta.key === "Context")?.value !='null' && 
          this.notification.metadata?.find((meta: any) => meta.key === "Entity_Reference")?.value !== undefined){
      return this.notification.metadata?.find(
        (meta: any) => meta.key === "Context"
      )?.value;
    }
    return ''
  },

    entityRessourceUID() {
      if(this.notification.metadata?.find((meta: any) => meta.key === "Context")?.resourceUid!='null'){
          return this.notification.metadata?.find((meta: any) => meta.key === "Context")
        ?.resourceUid
        }
        return ''
    },
    statusUID() {
      if(this.notification.metadata?.find((meta: any) => meta.key === "Entity_Status")?.value!='null'){
        return this.notification.metadata?.find((meta: any) => meta.key === "Entity_Status")
        ?.value
      }
      return '';
    },
  },

  methods: {
    ...mapActions("notificationModule", ["updateState", "setMsgUID", "transferMessage"]),
    ...mapActions("notificationModule", ["updateState"]),
    /**
     * This function retrieves the selected tag in the given scope.
     @param {any} scope - The scope to retrieve the selected tag.
     @returns {string} - The selected tag in the given scope.
     */
    getSelectedLabel(scope: any) {
      if (scope.opt.id) {
        return {
          id: scope.opt.reference ? scope.opt.reference : scope.opt.id,
          context: "Party",
        };
      } else if (scope.opt.reference) {
        return {
          id: scope.opt.reference,
          context: "Offer",
        };
      } else if (scope.opt.referenceorder) {
        return {
          id: scope.opt.referenceorder,
          context: "Order",
        };
      }
    },

    /**
     * get selected notif
     */
    getNotification() {
      return this.notification;
    },

    /**
     * get destinations from comboRecipients
     */
    getOdmRecipients() {
      const _destinations = convertToUON(this.$refs.comboRecipients.destinations);

      return _destinations;
    },

    /**
     * mapping parameters from metadata
     * TODO: fix mapping metadata using parameters (reply transfer msg)
     */
    getParams() {
      const _metadata = this.getNotification().metadata;
      const _params = _metadata.reduce(
        (_params: any, meta: { key: string; value: any }) => {
          if (meta.key in _params) {
            return { ..._params, [meta.key]: meta.value };
          }
          return _params;
        },
        { Context: "", Entity_Reference: "", Entity_Status: "" }
      );
      return _params;
    },
  },
})
export default class Notification extends Vue {
  notification!: Notification;
  messageTransfer!: User;
  ActionType!: string;
  status: any;
  body: any;
  sender: any;
  reference: any;
  priority: any;
  communicationDate: any;
  subject: any;
  metadata: any;
  resourceUid!: string;
  msgUID!: string;
  type: any;
  rows: any;
  context: any;
  setMsgUID!: (msgUID: any) => void;
  senderUid: any;
  showRepondre: boolean = false;
  routePath: any;
  entityRessourceUID: any;
  statusUID: any;
  openNewTab(ressourceUID: any, statusUID: any ,context :any) {
    this.context = context;
    if(this.context === "OFFER"){
      store.dispatch("demandModule/getProposalStatus", {
      statusId: statusUID,
      callback: (result: any) => {
        this.routePath = [
          OfferStatus.DECISION,
          OfferStatus.CONTRACT,
          OfferStatus.CANCELLED,
        ].includes(result.internalCode)
          ? "/mo/demand/detail/"
          : "/demand/edit/";

        store.dispatch("demandModule/searchOffer", {
          callback: this.updateResultRows,
          filter: {
            reference: ressourceUID,
          },
        });
      },
      });
    }else if(this.context === "ORDER"){
      store.dispatch("orderModule/searchOrder", {
          callback: this.updateResultRows,
          filter: {
            filterReference: ressourceUID,
            role_code: "CUSTOMER"
          },
      });
    }
    
  }

  updateResultRows(rows: any) {
    let routeData;
    this.rows = rows;
    if(this.context === "OFFER"){
       routeData = router.resolve(`${this.routePath}${this.rows[0].resourceuidoffer}`);
    }else {
      routeData = router.resolve(`${'/order/edit/'}${this.rows[0].orderid}`)
    }
    window.open(routeData.href, "_blank");
  }
  showAllSelected(destinataires: { value: number; label: string }[]) {
    return destinataires.map((el) => el.value);
  }

  /**
   * This method is called when the response form is submitted.
   * updates notification status and add comment.
   */
  submitFormRepondre() {
    //
  }
  updateState!: (payload: any) => void;
  transferMessage!: (message: any) => any;

  showInner = false;

  selectedDestinataire = [];
  msgSend!: string;
  index!: number;

  showComment?: boolean = false;
  showTransfer?: boolean = false;

  cancelFormRepondre() {
    this.msgSend = "";
    this.showRepondre = false;
    this.showComment = false;
  }

  messageReplyMethode() {
    this.setMsgUID(this.msgUID);
    if (this.msgSend && this.msgSend.length > 0) {
      this.showInner = true;
      store
        .dispatch("notificationModule/messageReply", {
          resourceUid: this.msgUID,
          messageTemplate: {
            objectType: "odm.contactmechanism.messagetemplate",
            systemUid: "odm-contactmechanism",
            resourceUid: "TEMPLATE_MSG",
          },
          sender: `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`,

          senderUid: {
            objectType: "odm.user.leaseforgeuser",
            systemUid: "odm-user",
            resourceUid: `${store.state.authModule?.user?.userId}`,
          },

          priority: this.priority,
          subject: this.subject,
          type: {
            objectType: "odm.contactmechanism.messagetype",
            systemUid: "odm-contactmechanism",
            resourceUid: "MESSAGE",
          },
          body: this.msgSend,
          recipients: [
            {
              recipient: {
                objectType: "odm.user.leaseforgeuser",
                systemUid: "odm-user",
                resourceUid: this.senderUid.resourceUid!,
              },
            },
          ],
          parameters: {
            Context: this.metadata?.find((meta: any) => meta.key === "Context")?.value,
            Entity_Reference: this.metadata?.find(
              (meta: any) => meta.key === "Entity_Reference"
            )?.value,
            Entity_Status: this.metadata?.find(
              (meta: any) => meta.key === "Entity_Status"
            )?.value,
          },

          relatedObjects: null,
          clientApplication: {
            objectType: "odm.contactmechanism.contactmechanismclient",
            systemUid: "odm-contactmechanism",
            resourceUid: "68815",
          },
          objectType: objectType_odmTransferReplyMsg,
          systemUid: "odm-contactmechanism",
          daaq: "/COMPANY/BRANCH2/SALES",
        })
        .then(() => {
          this.resetMessage();
          notifyReplytransferMsg(true, "reply");
        })
        .catch((error) => {
          notifyReplytransferMsg(false, "reply");
        });
    }
  }

  /**
   * Transfer message
   */
  destinations?: [];
  getOdmRecipients!: () => recipient[];
  getNotification!: () => any;
  getParams!: () => _parameters;

  resetMessage() {
    this.msgSend = ""; // Resets the message field
    this.showRepondre = false; // Hides the reply section
    this.showInner = false; // Hides the inner section
  }

  /**
   * getting current notification (message payload)
   */
  showTransferForm(notifMsg: Notification) {
    this.showTransfer = !this.showTransfer;
    //this.notification = notifMsg
    //
  }
  /**
   * transfer selected message to destinations list (groups/users)
   * @param payloadTransferMsg - current notification
   */
  submitFormTransfert() {
    const payloadNotifcation: transferMsg = this.getNotification();
    //
    const _recipients: recipient[] = this.getOdmRecipients();
    //
    const _params: _parameters = this.getParams();
    //
    clientAppMsg.resourceUid = payloadNotifcation.clientApplication?.resourceUid || "";

    //
    /**
     * mapping {payloadTransferMsg}
     */
    this.showInner = true;
    const payloadTransferMsg: transferMsg = {
      messageTemplate: messageTemplate,
      sender: payloadNotifcation.sender,
      senderUid: payloadNotifcation.senderUid,
      priority: payloadNotifcation.priority,
      subject: payloadNotifcation.subject,
      type: payloadNotifcation.type,
      body: payloadNotifcation.body,
      recipients: _recipients, //*
      // TODO in the backend to convert params to metadata
      parameters: _params,
      relatedObjects: payloadNotifcation.relatedObjects,
      clientApplication: clientAppMsg, //*
      objectType: objectType_odmTransferReplyMsg,
      systemUid: payloadNotifcation.systemUid,
      daaq: payloadNotifcation.daaq,
      resourceUid: payloadNotifcation.resourceUid,
    };
    //
    this.showInner = false;
    this.transferMessage(payloadTransferMsg)
      .then(() => {
        this.showInner = false;
        notifyReplytransferMsg(true, "transfert");
        this.cancelFormTransfert();
      })
      .catch((error: any) => {
        console.error("An error occurred while transfer the message:", error);
        notifyReplytransferMsg(false, "transfert");
      });
  }

  /**
   * cancel transfer request
   */
  cancelFormTransfert() {
    this.showTransfer = false;
    this.showComment = false;
  }
}
