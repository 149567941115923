import {Module} from "vuex";
import {FinancingProductPackInfo, RootState, ScalesState, SearchVariableType} from "@/types";
import {deepClone} from "@/utils";
import {calculateScales} from "@/store/services/financing/financingService";


const scalesModule: Module<ScalesState, RootState> = {
    namespaced: true,
    state: {
        scales: []
    },
    getters: {
        scaleSelected(state) {
            const {scaleSelected, scales} = state
            return scaleSelected || scales[0]
        }
    },
    actions: {
        addScale ({commit}, payload) {
            const {scale} = payload
            if (!scale) {
                throw new Error('Scales is mandatory')
            }
            commit('addScale', scale)
        },
        updateScale ({commit}, payload) {
            const {scale} = payload
            if (!scale) {
                throw new Error('Scales is mandatory')
            }
            const services = []
            if (scale.items && scale.items.length) {
                for (let i=0; i<scale.items.length; i++) {
                    const scaleItem = deepClone(scale.items[i])
                    switch (scaleItem.objectType) {
                        case "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.simpleserviceproductitem":
                        case "odm.product.financingproductpackitem.accessoryproductitem.serviceproductitem.insuranceproductitem.partyinsuranceproductitem":
                            scaleItem.config = {
                                "selected": true,
                                "required": scaleItem.qualification === "required"
                            }
                            if (scale.scalePresentation.services) {
                                const serviceValue: any = scale.scalePresentation.services.filter((s: any) => s.reference === scaleItem.resourceUid)[0]
                                if(serviceValue && serviceValue.amountValue) {
                                    scaleItem.amount.amount = serviceValue.amountValue
                                }
                            }
                            services.push(scaleItem)
                            break
                    }
                    this.dispatch('financingModule/serviceModule/setServices', { services })
                }
            }
            commit('updateScale', scale)
        },
        setScales ({commit}, payload) {
            const {scales} = payload
            if (!scales) {
                throw new Error('Scales is mandatory')
            }
            commit('setScales', scales)
        },
        refreshState ({commit}, payload) {
            const {scales} = payload
            if (scales) {
                commit('setScales', scales)
            }
        },
        cleanState ({commit}) {
            commit('cleanScales')
            commit('setScaleSelected', undefined)
            commit('setScaleItemsSelected', [])
            commit('setCategorySelected', undefined)
        },
        cleanScaleSelected ({commit}) {
            commit('setScaleSelected', undefined)
        },
        verifyScaleSelected ({commit, state}) {
            if (!state.scaleSelected) {
                const firstScale = state.scales.filter(s => !s.scalePresentation.loadingError)[0]
                commit('setScaleSelected', firstScale)
            }
        },
        setCategorySelected ({commit}, payload) {
            const {category} = payload
            if (!category) {
                throw new Error('category is mandatory')
            }
            commit('setCategorySelected', category)
        },
        setScaleUnselected ({dispatch}, payload) {
            const {scaleSelected} = payload
            scaleSelected.scalePresentation = scaleSelected.scalePresentationDefault
            const proposalScaleId = this.state.demandModule.proposalScaleId || ''
            if (!this.state.demandModule.isEdit || proposalScaleId !== scaleSelected.resourceUid) {
                calculateScales(SearchVariableType.payment, scaleSelected, this.state).then(scalesCalculated => {
                    dispatch('updateScale', { scale: scalesCalculated })
                })
            }
        },
        async setScaleSelected ({commit, dispatch}, payload) {
            if (!payload || !payload.scale) {
                throw new Error('scale is mandatory')
            }
            
            if (this.state.financingModule.scalesModule.scaleSelected) {
                const previousSelectedScale = deepClone(this.state.financingModule.scalesModule.scaleSelected)
                if (previousSelectedScale.resourceUid !== payload.scale.resourceUid) {
                    previousSelectedScale.scalePresentation.servicePartyInsurance.forEach((data: any) => {
                        if (data._constrols) {
                            data._constrols.selected = true
                        }
                    })
                    await dispatch('setScaleUnselected', { scaleSelected: previousSelectedScale })
                }
            }
            this.dispatch('simulationModule/setFinancingValues', {
                sliders: []
            })
            const scale: FinancingProductPackInfo = payload.scale
            // scale.scalePresentation.servicePartyInsurance.forEach((data: any) => {
            //     if (data._constrols) {
            //         data._constrols.selected = true
            //     }
            // })
            commit('setScaleSelected', deepClone(scale))
            const items = []
            if (scale.items && scale.items.length) {
                for (let i=0; i<scale.items.length; i++) {
                    const scaleItem = deepClone(scale.items[i])
                    switch (scaleItem.objectType) {
                        case "odm.product.financingproductpackitem.financingofferitem":
                            items.push(scaleItem)
                            break
                    }
                    commit('setScaleItemsSelected', items)
                }
            } else {
                this.dispatch('financingModule/serviceModule/setServices', { services: [] })
                commit('setScaleItemsSelected', [])
            }
            this.dispatch('simulationModule/setFinancingValues', {
                sliders: scale.scalePresentation.sliders
            })
        },
        removeScalesFromPacks({commit}, payload) {
            const {packs} = payload
            if (packs && packs.length) {
                packs.forEach((s: any) => {
                    commit('removeScalesOnPack', s)
                })
            }
        }
    },
    mutations: {
        removeScalesOnPack(state, payload) {
            state.scales = [...state.scales.filter(s => s.resourceUid !== payload.resourceUid)]
        },
        addScale(state, payload: FinancingProductPackInfo) {
            if (state.scales.some(s => s.resourceUid === payload.resourceUid)) {
                state.scales = [...state.scales.filter(s => s.resourceUid !== payload.resourceUid), payload]
            } else {
                state.scales.push(deepClone(payload))
            }
        },
        updateScale(state, payload: FinancingProductPackInfo) {
            const scale = state.scales.filter(s => s.resourceUid === payload.resourceUid)[0]
            if (scale) {
                const index = state.scales.indexOf(scale)
                state.scales[index] = payload
            }
        },
        setScales(state, payload: FinancingProductPackInfo[]) {
            const scalesIds = payload.map(s => s.resourceUid)
            const stateScales = state.scales.filter(s => scalesIds.indexOf(s.resourceUid) === -1)
            state.scales = deepClone([...stateScales, ...payload])
        },
        setScaleSelected(state, payload) {
            state.scaleSelected = payload
        },
        setScaleItemsSelected(state, payload) {
            state.scalesItemsSelected = payload
        },
        setCategorySelected(state, payload) {
            state.categorySelected = payload
        },
        cleanScales(state) {
            state.scales = []
        },
    },
}

export default scalesModule
