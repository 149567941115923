
import 'quasar/dist/quasar.css'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'

import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';

import {globalConfig} from '@/utils'

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    notify: globalConfig.notify_config.notifyConfiguration,
    loading: { /* look at QuasarConfOptions from the API card */ }
  },
  components: ['QPopover'],
  plugins: {
    Notify,
    Loading,
    Dialog
  },
  iconSet: iconSet
}
