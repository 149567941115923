import {settings} from "@/settings";

//Global Configuration for Superset Dashboard
export const supersetConfiguration = {
    base_url: settings.superset_api_url, //Use the superset_api_url from /settings/intex.ts file
    loginPath: "security/login",
    csrfPath: "security/csrf_token/",
    guest_token: "security/guest_token/",
    adminConfig: settings.adminConfig,  //Use the adminConfig from /settings/intex.ts file
    guestConfig: settings.guestConfig,  //Use the guestConfig from /settings/intex.ts file
    dashboardUiConfig: {
        hideTitle: true,
        filters: {
            visible: true,
        }
    }
}




