
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import { globalConfig, serviceName, amountHT, amountTTC, paymentDatesOption } from "@/utils";
import {formatAmoutswitchCurrency, formatWithMask } from "@/utils/configuration/formatters-config";
import SummaryFinancingPartyInsurance from "@/components/summary-panel/SummaryFinancingPartyInsurance.vue";
import store from "@/store";
import { proposalAccessoriesObjectTypes, businessDataUtils } from "@/types";

@Options({
  components: { LabelAndValue, SummaryFinancingPartyInsurance },
  props: ["proposal", "valueStyle", "labelStyle", "divValueStyle"],
})
export default class SummaryFinancingService extends Vue {
  capitalize = globalConfig.capitalize;
  serviceName = serviceName;
  amountHT = amountHT;
  amountTTC = amountTTC;
  paymentDatesOption = paymentDatesOption;
  proposalPartyInsuranceObjectType = proposalAccessoriesObjectTypes.proposalPartyInsuranceObjectType
  proposalsimpleservice = proposalAccessoriesObjectTypes.proposalsimpleservice
  proposal?: any;
  labelStyle?: any;
  baseServiceKey = "demand.product.services.names";

  get calculatestatus() {
    return config.VUE_APP_CALCULATE
  }
  get offer() {
    return store.state.summaryPanelModule?.offer
  }

  get partyInsurance() {
    let accessories
    if (config.VUE_APP_CALCULATE === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
      accessories = this.proposal?.proposalAccessories.filter((accessorie: any) => accessorie.objectType.includes(this.proposalPartyInsuranceObjectType)) || []
    }

    else {
      accessories = store.state.middleOfficeModule?.proposalScale?.scalePresentation?.servicePartyInsurance || []
    }
    return accessories
  }
  getAmountHT(service: any) {
    return service.amountValueWoTax
        ? `${formatAmoutswitchCurrency("EUR", service.amountValueWoTax)}`
        : "-";
  }

  get servicesList() {
    let services;
    if (config.VUE_APP_CALCULATE === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
      services = this.proposal?.proposalAccessories.filter((accessorie: any) => accessorie.objectType.includes(this.proposalsimpleservice)) || []
    }

    else {
      services = store.state.middleOfficeModule?.proposalScale?.scalePresentation?.services || []
    }
    return services;
  }

  getAmountTTC(service: any) {
    const tax: number = service.rate ? Number(service.rate) : 0;
    if (service.amountValueWTax) {
      const value = service.amountValueWTax * (1 + tax);
      const result = `${formatAmoutswitchCurrency("EUR", value)}`;
      return result;
    } else {
      return "-";
    }
  }
  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    color: "black",
  };

  divValueStyle = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "10px",
  };
  labelStyleItem = {
    "font-size": "1rem",
    color: "gray",
    "font-weight": "bold",
  };
}
