
import store from "@/store";
import { globalConfig, sleep } from "@/utils";
import moment from "moment";
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";

@Options({watch: {

  async "$store.state.orderModule.alertList"(refraich){
  
   },
 
    
}})
export default class AlertDialog extends Vue {
  actionDate?: any = ref(moment().format("DD/MM/YYYY HH:mm"));
  comment = "";
  items?: any[] = [];
  checkedItems?: any = [];


  get show() {
    return store.state.orderModule.showAlert;
  }
  get rows(){

    return store.state.orderModule.alertList
  }

  get reference() {
    switch (this.currentRoute) {
      case "order-edit":
        return store.state.orderModule.order?.reference;
    }
    return "-";
  }
  get order() {
    return store.state.orderModule.order;
  }
  async deleteAlert(row: any, index : number) {
    const deleteRow = { orderId: this.order.resourceUid,  id: row.id };

   store.dispatch("showConfirmationDeleteDialog", {
      message: "Are you sure you want to delete this alert",
      confirmation: true,
      payload: deleteRow,
      deleteAction: "RemoveAlert",
    });


  }
  async onSaveAlert() {
    let reasonsList: any = [];
    if (this.items) {
      for (const item of this.items) {
        if (item.selected) reasonsList.push(item.name);
      }
    await  store.dispatch("orderModule/createOrderAlert", {
        orderId: this.order.resourceUid,
        reasons: reasonsList,
        comment: this.comment,
      });

    
   
      this.initializeCheckedItems();
    }
  }


  whoCreatedName() {
    const firstName = store.state.authModule?.user?.firstName || "";
    const lastName = store.state.authModule?.user?.lastName || "";
    return `${firstName} ${lastName}`.trim();
  }

  initializeCheckedItems() {
    this.items?.forEach((item: any) => {
      item.selected = false;
    });
    this.comment = "";
  }

  toggleShowControls() {
    this.initializeCheckedItems();
    return store.dispatch("orderModule/toggleShow");
  }

  get columns() {
    return globalConfig.table_config.orderAlertCols;
  }

  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }

  get getAlertList() {
    return store.state.orderModule.order?.alertList;
  }
  isAnyCheckboxSelected() {
    this.items?.forEach((item: any) => {
      if (item.selected === true) {
        this.checkedItems = true;
      }
    });
    return this.checkedItems;
  }
  created() {
    store.dispatch("orderModule/getOrderAlertList", {
      filter: {
        orderId: this.order.resourceUid,
      },
    });

    store.dispatch("orderModule/getAlertReasons", {
      callback: (res: any) => {
        if (res) {
          let itemsList: any = [];
          for (const item of res.items) {
            const reasons = { name: item.id, selected: false };
            itemsList.push(reasons);
          }
          this.items = itemsList;

          this.initializeCheckedItems();
        }
      },
    });
  }
}
