import i18n from "@/i18n";
import store from "@/store";
import { getAssociatedPartySeller } from "@/store/services/order/orderService";
import { ContactType, IContactMechanism, OrderPartyRoleEnum } from "@/types";
import { Module } from "vuex";

const orderPartyModule: Module<any, any> = {
    namespaced: true,
    state:{
        orderParties:[],
    },
    actions: {
        async InitOrderParties({commit}) {
            const orderParties = store.getters["orderPartyModule/getOrderPartiesInit"] 
            const associatedParty:any = store.state.authModule?.user.partyPersonId
           const body= await getAssociatedPartySeller(associatedParty)
           orderParties.push(body);
          commit('setOrderParties', orderParties)
        },
        addOrderPartie({commit},payload) {
            const {role}=payload
            const party = store.getters["orderPartyModule/getParty"] 
            party.tabName=role.id
            party.role_code=role.id
            commit('addOrderParties', party)
        },
        setOrderParties({commit},payload) {
            const {orderParties}=payload
                commit('setOrderParties', orderParties)
        },
        cleanState({ state, commit }) {
          commit('setInitial')
      }
    },
    mutations: {
        setOrderParties(state, payload) {
            state.orderParties = payload
        },
        addOrderParties(state, payload) {
            state.orderParties.push(payload)
        },
        setInitial(state) {
          state.orderParties = []
      },
    },
    getters: {
        getParty(){
            return {
                id: 1,
                tabName: "CUSTOMER",
                role_code: "CUSTOMER",
                showDeleteBtn: false,
                objectType: "",
                systemUid: "",
                resourceUid: "",
                orderPartyResourceUid:"",
                associatedParty: {
                  third: store.getters["orderPartyModule/getThirdInit"],
                },
              }
        },
        getOrderPartiesInit(){
           return [
                {
                  id: 1,
                  tabName: "CUSTOMER",
                  role_code: "CUSTOMER",
                  showDeleteBtn: false,
                  objectType: "",
                  systemUid: "",
                  resourceUid: "",
                  associatedParty: {
                    third: store.getters["orderPartyModule/getThirdInit"],
                  },
                },
              ]
        },
        getThirdInit() {
            return {
              id: {
                resourceUid: '',
                objectType: '',
                systemUid: ''
              },
              type: { id: "party-FrenchOrganization", label: "Personne morale" },
              person: {
                title: {
                  objectType: 'odm.party.persontitle',
                  type: {
                    value: '',
                    label: ''
                  }
                },
                taxGeographicZone: 'France_M',
                birthChildren: [],
                nationality: {
                  type: {
                    value: '',
                    label: ''
                  }
                },
                currentMaritalStatus: {
                  type: {
                    value: '',
                    label: ''
                  }
                },
                currentMatrimonialRegime: {
                  type: {
                    value: '',
                    label: ''
                  }
                },
                gender: {
                  type: {
                    value: '',
                    label: ''
                  }
                },
                maritalStatuses: [],
                nbOfDependent: '',
                nbOfChildren: '',
                homemakers: '',
                retirementPassage: '',
                birthplace: {
                  type: {
                    id: "",
                    label: "",
                    config: {
                      city: "",
                      country: {
                        id: "",
                        label: ""
                      },
                    }
                  },
                  postalCode: '',
                  country: {
                    name: '',
                    objectType: 'odm.party.country'
                  },
                  inseeCode: '',
                  region: '',
                  department: '',
                  city: '',
                  objectType: 'odm.party.postalcode',
                },
                firstName: '',
                familyName: '',
                birthDate: '',
                contacts: [{ contactMechanism: { ...store.getters["orderPartyModule/getContactMechanismInit"] } }],
                ids: [{ ...store.getters["orderPartyModule/getThirdIdProofInit"] }],
                jobs: [{ ...store.getters["orderPartyModule/getThirdJobsInit"] }],
                revenues: [{ ...store.getters["orderPartyModule/getThirdAnalysisInit"] }],
                addresses: [],
                correspondents: [],
                classifications: [],
                reference: '',
                financialExposures: []
              },
              relations: [],
              accounts: [],
              financialExposures: [],
              correspondents: [],
              organization: {
                siren: '',
                name: '',
                codeApe: '',
                acronym: '',
                isCreated: true,
                commercialRegister: '',
                creationDate: '',
                numTVAIntraCom: '',
                activitySector: {
                  objectType: 'odm.party.activitysector',
                  resourceUid: '',
                  type: {
                    value: undefined,
                    label: ''
                  }
                },
                codeNACE: {
                  type: {
                    value: '',
                    label: ''
                  }
                },
                legalCategory: {
                  objectType: 'odm.party.organizationlegalcategory',
                  resourceUid: '',
                  type: {
                    value: '',
                  }
                },
                contacts: [],
                facilities: [{ ...store.getters["thirdModule/getFrenchFacilityInit"] }],
                addresses: [],
                balanceSheets: [],
                correspondents: [],
                classifications: [],
                reference: '',
                financialExposures: []
              },
              addresses: [],
              roles: [],
              classifications: [],
              isDisabled: true,
            }
          },
          getContactMechanismInit(): IContactMechanism {
            return {
              _controls: {
                attToRemove: ['businessData'],
              },
              type: {
                id: ContactType.EMAIL_CONTACT,
                label: "E-mail",
                config: {
                  objectType: "odm.party.contactmechanism.emailcontact",
                  fields: ["emailAddress", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "consent", "facility", "daaq"]
                }
              },
              objectType: '',
              emailAddress: '',
              identifier: '',
              phoneNumber: '',
              acceptSMS: false,
              nonSolicitation: false,
              isDisabled: true,
              daaq: "/"
            }
          },
          getThirdIdProofInit() {
            return {
              reference: '',
              emissionDate: '',
              authority: '',
              validity: {
                from: '',
                until: ''
              },
              documentType: {
                type: {
                  value: '',
                  label: '',
                },
      
      
              },
              supportingDocument: null,
              objectType: 'odm.party.identitydocument',
              isDisabled: true
            }
          },
          getThirdJobsInit() {
            return {
              positionType: {
                type: {
                  id: "",
                  label: ""
                },
              },
              occupationType: {
                type: {
                  id: "",
                  label: ""
                },
              },
              contractType: {
                type: {
                  id: '',
                  label: ''
                }
              },
              activity: {
                type: {
                  id: "",
                  label: ""
                },
              },
              validity: {
                from: '',
                until: ''
              },
              nbYearExperienceActivity: '',
              nbYearExperiencePosition: '',
              employer: {},
              jobLocation: { ...store.getters["thirdModule/getAddressInit"] },
              employerName: '',
              principal: false,
              nbHour: '',
              employerCreationDate: '',
              tenureDate: '',
              isDisabled: true
            }
          },
          getThirdAnalysisInit() {
            return {
              label: "",
              analysisDate: "",
              status: {
                type: {
                  value: '',
                  label: ''
                }
              },
              yearlyExpensesTotal: {
                currency: "EUR",
                amount: 0
              },
              yearlyIncomeTotal:{
                currency: "EUR",
                amount: 0
              },
              detail: [{ ...store.getters["thirdModule/getIncomeInit"] }, { ...store.getters["thirdModule/getExpenseInit"] }],
              isDisabled: false,
            }
          },
          getAddressInit() {
            return {
              daaq: "/",
              address: {
                type: {
                  type: {
                    id: "Residential",
                    label: i18n.global.t("demand.party.addressType.Residential"),
                  },
                  objectType: "odm.party.addresstype",
                },
                line1: "",
                line2: "",
                line3: "",
                territory: "",
                postalCode: {
                  type: {
                    id: "",
                    label: "",
                    config: {
                      city: "",
                      country: {
                        id: "",
                        label: "",
                      },
                    },
                  },
                  postalCode: "",
                  country: {
                    name: "",
                    objectType: "odm.party.country",
                  },
                  inseeCode: "",
                  region: "",
                  department: "",
                  city: "",
                  objectType: "odm.party.postalcode",
                },
                objectType: "odm.party.address",
                isDisabled: false,
                daaq: "/",
              },
              livingSituation: {
                type: {
                  value: "",
                  label: "",
                },
              },
            };
          }

    },

}
export default orderPartyModule;