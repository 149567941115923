import { Module } from "vuex";
import { RootState, ValidateDocumentsState } from "@/types";
import {
  deleteManualDocument,
  deleteProcessFile,
  getGatheringProcessList, rejectFile, updateSupportingDocumentStatus
} from "@/store/services/task/actions/validateDocumentService";
import { deleteMetadataFile } from "@/commons/gathering-process";

const validateDocumentsModule: Module<ValidateDocumentsState, RootState> = {
  namespaced: true,
  state: {
    gatheringProcessList: []
  },
  getters: {},
  actions: {
    getGatheringProcessList({ commit }) {
      const offerUid = this.state.taskModule.offer?.resourceUid
      if (offerUid) {
        getGatheringProcessList(offerUid)
          .then((res) => commit("setProcessList", res))
          .catch((e) => console.error(e));
      }
    },
   async changeSupportingDocumentStatus({ commit }, payload) {
      const { status, gatheringProcessRow, processIndex } = payload
      if (!status || !gatheringProcessRow) {
        throw new Error('Supporting document and status are mandatory!')
      }
      return updateSupportingDocumentStatus(gatheringProcessRow,status).then(result => {
        return result;
        }).catch((e: Error) => console.error(e))
    },
    deleteManualDocument({ commit }, payload) {
      const { request } = payload
      const processId = request.processResourceUid
      const supportingDocId = request.supportingDocumentResourceUid
      const documentResourceUid = request.receivedFileUid
      if (request && processId && supportingDocId) {
        rejectFile(processId, documentResourceUid, supportingDocId, {
          status: {
            objectType: "odm.supportingdocument.processstatus",
            systemUid: "odm-supportingdocument",
            resourceUid: "REJECTED"
          }
        }).then(() =>
          deleteManualDocument(processId, supportingDocId)
            .then(() => {
              if (payload.callback) payload.callback()
            })
            .catch((e) => console.error(e))
        ).catch((e) => console.error(e))
      }
    },
    async deleteProcessFile({ commit }, payload) {
      const { request, callback } = payload
      const processId = request.processResourceUid
      const documentResourceUid = request.receivedFileUid
      if (request && processId) {
        try {
          if (request.document?.metadata) {
            for (const metadata of request.document.metadata) {
              if (metadata.resourceUid) {
                await deleteMetadataFile(metadata.resourceUid);
              }
            }
          }
          callback('DECREMENT');
          await deleteProcessFile(processId, documentResourceUid);
        } catch (e) {
          console.error(e);
        }
      }
    },
    cleanState({ commit }) {
      commit("setInitial");
    },
  },
  mutations: {
    setProcessList(state, payload) {
      state.gatheringProcessList = payload
    },
    updateSupportingDocument(state, payload) {
      const { supportingDocument, processIndex, indexSupportingDoc, indexRecivedFiles, status } = payload
      state.gatheringProcessList[processIndex ? processIndex : 0].supportingDocuments[indexSupportingDoc] = supportingDocument
      state.gatheringProcessList[processIndex ? processIndex : 0].gatheringProcessItem[0].receivedFiles[indexRecivedFiles].status.resourceUid = status
    },
    setInitial(state) {
      state.gatheringProcessList = []
    },
  },
};

export default validateDocumentsModule;
